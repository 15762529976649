<template>
  <div class="addon lf-headline">
    <component :is="asyncAddonLfHeadlineEditor" v-if="isEditModeActive && isDemo && isEditorReady" :model="model" />

    <ApplyReplacementTags
      v-else
      :tag="state.type"
      :html="text"
      :extra-replacement-tags="model.column?.replacementTags"
      :style="state?.elementStyling?.inline"
      class="lf-headline__content"
    />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineAsyncComponent, nextTick, ref, watch } from 'vue';
import { computed, defineComponent, onMounted } from 'vue';
import type { AddonLfHeadlineModel } from '@/src/components/addons/lf-headline/Model';
import useFontObserver from '@/src/hooks/useFontObserver';
import ApplyReplacementTags from '@/src/components/ApplyReplacementTags.vue';
import { useCampaignStore } from '@/src/store/campaign';
import { isSsr } from '@/src/utilities/Utilities';
import { useUtilityStore } from '@/src/store/utility';

export default defineComponent({
  name: 'AddonLfHeadline',
  components: {
    ApplyReplacementTags
  },
  props: {
    model: {
      type: Object as PropType<AddonLfHeadlineModel>,
      required: true
    }
  },
  setup(props) {
    const state = props.model.state;
    const campaignStore = useCampaignStore();
    const utilityStore = useUtilityStore();

    let readyPromiseResolve: (() => void) | undefined;
    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const isEditorReady = ref(true);

    const isEditModeActive = computed<boolean>(() => {
      return campaignStore.model?.state.isEditModeActive ?? false;
    });

    const isDemo = computed(() => {
      if (isSsr()) return false;

      return utilityStore.url.includes('/campaign/view/demo');
    });

    const text = computed<string>(() => {
      const fallbackContent = 'Lorem ipsum dolor sit amet';

      if (state.text === '' || state.text === undefined) {
        return fallbackContent;
      }

      return state.text;
    });

    const asyncAddonLfHeadlineEditor = defineAsyncComponent(
      () => import('@/src/components/addons/lf-headline/Editor.vue')
    );

    watch(
      () => state.type,
      async () => {
        isEditorReady.value = false;
        await nextTick();
        await nextTick();
        isEditorReady.value = true;
      }
    );

    onMounted(async () => {
      const { isFontLoaded } = useFontObserver();

      if (state.layout?.typography?.fontFamily) {
        await isFontLoaded(state.layout.typography.fontFamily);
      }

      if (readyPromiseResolve) {
        readyPromiseResolve();
      }
    });

    return {
      isEditorReady,
      isDemo,
      state,
      text,
      isEditModeActive,
      asyncAddonLfHeadlineEditor,
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>
