import { defineStore } from 'pinia';
import { isSsr } from '@/src/utilities/Utilities';

interface MetaAttributes {
  [key: string]: string;
}

interface State {
  fonts: string[];
  bodyClassList: string[];
  userAgent: string | null;
  host: string | null;
  path: string | null;
  cookies: string | null;
  overwrittenWindowHeight: number | null;
  overwrittenUrl: string | null;
  campaignPadding: number | null;
  metatags: MetaAttributes[];
}

export const useUtilityStore = defineStore('utility', {
  state: (): State => ({
    fonts: [],
    bodyClassList: [],
    userAgent: null,
    host: null,
    path: null,
    cookies: null,
    overwrittenWindowHeight: null,
    overwrittenUrl: null,
    campaignPadding: 0,
    metatags: []
  }),
  getters: {
    windowHeight(state) {
      return state.overwrittenWindowHeight ?? window.innerHeight;
    },
    url(state) {
      if (state.overwrittenUrl === null) {
        return '';
      }

      return state.overwrittenUrl;
    },

    isDemoUrl(state) {
      let url = state.overwrittenUrl;
      if (!isSsr() && !url) {
        url = window.location.href;
      }

      if (url) {
        return url.includes('/campaign/view/demo');
      }
      return false;
    }
  },

  // The reason for these return if "isSsr" is because the server "can" sometimes see the device as mobile, but the client will say it desktop
  // for whatever reason we can't remove the body class again when the client takes over, and we will end up with both desktop and mobile classes
  // this check fixes the issue and makes sure only the client decides what device we are working with
  actions: {
    addBodyClass(className: string) {
      if (isSsr()) return;
      this.addBodyClasses([className]);
    },
    addBodyClasses(classList: string[]) {
      if (isSsr()) return;
      classList.forEach((className) => {
        if (!this.bodyClassList.includes(className)) {
          this.bodyClassList.push(className);
        }
      });
    },
    removeBodyClass(className: string) {
      if (isSsr()) return;
      this.removeBodyClasses([className]);
    },
    removeBodyClasses(classList: string[]) {
      if (isSsr()) return;
      classList.forEach((className) => {
        if (this.bodyClassList.includes(className)) {
          this.bodyClassList.splice(this.bodyClassList.indexOf(className), 1);
        }
      });
    },
    addRemoveClassList(addClasses: string[], removeClasses: string[]) {
      if (isSsr()) return;

      addClasses.forEach((className) => {
        if (!this.bodyClassList.includes(className)) {
          this.bodyClassList.push(className);
        }
      });

      removeClasses.forEach((className) => {
        if (this.bodyClassList.includes(className)) {
          this.bodyClassList.splice(this.bodyClassList.indexOf(className), 1);
        }
      });
    },
    addMetatag(metaAttributes: MetaAttributes) {
      const existingEntry = this.metatags.find((tag) => {
        for (const key in tag) {
          if (Object.prototype.hasOwnProperty.call(tag, key)) {
            if (tag[`${key}`] === metaAttributes[`${key}`]) {
              return metaAttributes;
            }
          }
        }

        return false;
      });

      if (!existingEntry) {
        this.metatags.push(metaAttributes);
      } else {
        this.metatags.push(existingEntry);
      }
    },
    loadFont(fontFamily: string) {
      const isCustom = fontFamily.includes('custom:');
      if (isCustom) return;

      // Sometimes we might get font family forwarded with multiple fonts in them.
      // Here we need to destruct the font family using regex and find our font we need to get.
      if (fontFamily.includes('"')) {
        const match = fontFamily.match(/"([a-z_\-0-9 ]+)"/i);

        if (match && match[1]) {
          fontFamily = match[1];
        }
      }

      if (!this.fonts.includes(fontFamily)) {
        this.fonts.push(fontFamily);
      }
    }
  }
});
