export enum GameEndingConditionType {
  NONE = 'none',
  WINNER = 'winner',
  LOSER = 'loser'
}

export interface VisibilityDateRangeData {
  enabled: string;
  date_from?: string;
  date_to?: string;
}

export interface VisibilityTimeRangeData {
  enabled: string;
  time_from: string;
  time_to: string;
}

export interface VisibilityMetricData {
  enabled: string;
  operator?: 'and' | 'or';
  items?: VisibilityMetricItemData[];
}
export interface VisibilityMetricItemData {
  field?: string;
  type: string;
  value?: string | number;
  value_option?: string | number;
}

export interface VisibilityParamData {
  enabled: string;
  operator?: 'and' | 'or';
  items?: VisibilityParamItemData[];
}
export interface VisibilityParamItemData {
  name: string;
  type: string;
  value: string;
}

export interface VisibilityCookieData {
  enabled: string;
  operator?: 'and' | 'or';
  items?: VisibilityCookieItemData[];
}
export interface VisibilityCookieItemData {
  name: string;
  type: string;
  value: string | number;
}

export interface FormFieldItem {
  field: string;
  type: number;
  value: string | number;
}

export interface VisibilityBulkPrizes {
  enabled: string;
  condition: string;
}

export interface VisibilityConditionsData {
  condition?: GameEndingConditionType;
  date_range?: VisibilityDateRangeData;
  time_range?: VisibilityTimeRangeData;
  metric_data?: VisibilityMetricData;
  form_fields?: {
    enabled?: string;
    items?: FormFieldItem[];
    operator?: 'and' | 'or';
  };
  flow?: {
    enabled: string;
    pages: {
      [key: string]: string;
    };
  };
  parameters?: VisibilityParamData;
  cookies?: VisibilityCookieData;
  bulk_prizes?: VisibilityBulkPrizes;
  custom?: {
    [key: string]: unknown;
  };
  devices?: {
    desktop?: string;
    tablet?: string;
    mobile?: string;
  };
}
