<template>
  <div
    class="content__item-form-type content__item-form-type--checkbox"
    :class="{
      'content__item-form-type--checkbox-multi': model.state.type === FieldType.MULTIPLE
    }"
    role="group"
    :aria-labelledby="`checkbox-group-label-${model.state.id}`"
  >
    <RichTextRenderer
      v-if="model.state.checkbox.type === FieldType.MULTIPLE"
      :id="`checkbox-group-label-${model.state.id}`"
      class="content__item-form-checkboxes-label"
      :html="model.state.label"
      tag="label"
    />

    <template v-if="model.state.options && model.state.options.length > 0">
      <div
        v-for="(item, index) in model.state.options"
        :key="index"
        :class="[
          'content__item-form-checkbox form-check',
          fieldClasses,
          { 'form-group--is-focus-checkbox': inputFocused === index.toString() }
        ]"
      >
        <input
          :id="`checkbox-${model.state.id}-${index}`"
          v-model="item.selected"
          type="checkbox"
          tabindex="0"
          class="content__item-form-checkbox-input form-check-input"
          :class="{ invalid: !model.state.isValid }"
          :name="`form[${model.state.id}][${index}]`"
          :disabled="model.state.readonly"
          role="checkbox"
          :aria-checked="item.selected ? 'true' : 'false'"
          @change="onChangeMultiple(item)"
          @focus="onFocus(index)"
          @blur="onBlur"
        />
        <label
          class="content__item-form-label content__item-form-label--checkbox form-check-label"
          :for="`checkbox-${model.state.id}-${index}`"
          @click.stop="onChangeMultiple(item)"
        >
          <RichTextRenderer :html="item.label" tag="span" />
        </label>
      </div>
    </template>

    <div
      v-if="model.state.checkbox.type !== FieldType.MULTIPLE"
      :class="[
        'content__item-form-checkbox form-check',
        fieldClasses,
        { 'form-group--is-focus-checkbox': inputFocused === model.state.id }
      ]"
    >
      <input
        :id="`checkbox-${model.state.id}`"
        v-model="state.value"
        tabindex="0"
        type="checkbox"
        class="content__item-form-checkbox-input form-check-input"
        :name="`form[${model.state.id}]`"
        :disabled="model.state.readonly"
        @focus="onFocus(model.state.id)"
        @blur="onBlur"
      />

      <label
        class="content__item-form-label content__item-form-label--checkbox form-check-label"
        :for="`checkbox-${model.state.id}`"
      >
        <span @click.stop="onLabelClick">
          <RichTextRenderer :truncate="model.state?.truncate" :html="model.state.label" tag="span" />
        </span>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import type { FormElementCheckboxModel } from '@/src/components/addons/registration/Models/FormElementCheckboxModel';
import { FieldType } from '@/src/typings/enums/enums';
import { redirect, RedirectTarget } from '@/src/services/url';
import RichTextRenderer from '@/src/components/RichTextRenderer.vue';
import type { FieldOptionItem } from '@/src/components/addons/registration/types';
import { useCampaignStore } from '@/src/store/campaign';
import type { ClassList } from '@/src/typings/types/types';

export default defineComponent({
  name: 'FormElementCheckbox',
  components: { RichTextRenderer },
  props: {
    model: {
      type: Object as PropType<FormElementCheckboxModel>,
      required: true
    },

    showValidationErrors: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const campaignStore = useCampaignStore();
    const inputFocused = ref<string | undefined>(undefined);

    const fieldClasses = computed<ClassList>(() => {
      return {
        'form-group--error': props.showValidationErrors && !props.model.state.isValid,
        'form-check--error': props.showValidationErrors && !props.model.state.isValid,
        'form-check--has-value': Array.isArray(props.model.state.value)
          ? props.model.state.value.length > 0
          : !!props.model.state.value,
        'form-check--valid': !!props.model.state.isValid
      };
    });

    const onLabelClick = (e: MouseEvent) => {
      if (props.model.state.cta?.type === 'popover' && props.model.state.cta?.popover) {
        e.preventDefault();
        campaignStore.setActivePopover(props.model.state.cta.popover);
      }

      if (props.model.state.cta?.url && props.model.state.cta.type === 'url' && typeof window !== 'undefined') {
        let target: RedirectTarget | undefined;

        switch (props.model.state.cta.target) {
          case '_blank':
            target = RedirectTarget.BLANK;
            break;

          case '_top':
            target = RedirectTarget.TOP;
            break;

          case '_self':
            target = RedirectTarget.SELF;
            break;
        }

        redirect({
          url: props.model.state.cta.url,
          target: target ?? RedirectTarget.BLANK
        });
      }
    };

    const onChangeMultiple = (item: FieldOptionItem) => {
      item.selected = !item.selected;

      if (props.model.state.options) {
        // eslint-disable-next-line vue/no-mutating-props
        props.model.state.value = props.model.state.options
          .filter((option) => option.selected)
          .map((field) => field.value);
      }
    };

    const onFocus = (index: number | string) => {
      inputFocused.value = index.toString();
    };
    const onBlur = () => {
      inputFocused.value = undefined;
    };

    onMounted(() => {
      if (props.model.state.options) {
        // eslint-disable-next-line vue/no-mutating-props
        props.model.state.value = props.model.state.options
          .filter((option) => option.selected)
          .map((field) => field.value);
      }
    });

    return {
      inputFocused,
      onFocus,
      onBlur,
      fieldClasses,
      onLabelClick,
      state: props.model.state,
      onChangeMultiple,
      FieldType
    };
  }
});
</script>
