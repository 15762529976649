<template></template>

<script lang="ts">
import { useHead } from '@vueuse/head';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { IntegrationCookieConsentModel } from './Model';
import type { CookieConsent } from './Types';
import { isSsr } from '@/src/utilities/Utilities';
import { cookieConsentAccept, cookieConsentReject } from '@/src/services/cookieConsent';

declare global {
  interface Window {
    cookieconsent?: CookieConsent;
  }
}

export default defineComponent({
  name: 'integration-cookie-consent',
  props: {
    model: {
      type: Object as PropType<IntegrationCookieConsentModel>,
      required: true
    }
  },
  setup(props) {
    if (isSsr()) {
      return;
    }

    useHead({
      link: [
        {
          href: 'https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css',
          rel: 'stylesheet',
          type: 'text/css'
        }
      ],
      script: [
        {
          src: 'https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js'
        }
      ]
    });

    if (typeof window !== 'undefined') {
      window.cookieWait = true;

      const waitForLibrary = setInterval(() => {
        if (typeof window.cookieconsent !== 'undefined') {
          clearInterval(waitForLibrary);

          window.cookieconsent.initialise({
            ...(props.model.state.plugin ?? {}),
            onInitialise: function () {
              const type = this.options.type;
              const didConsent = this.hasConsented();

              if (type === 'opt-in' && didConsent) {
                // enable cookies
                cookieConsentAccept();
              } else if (type === 'opt-out' && !didConsent) {
                // disable cookies
                cookieConsentReject();
              }
            },
            onStatusChange: function () {
              const type = this.options.type;
              const didConsent = this.hasConsented();

              if (type === 'opt-in' && didConsent) {
                // enable cookies
                cookieConsentAccept();
              } else if (type === 'opt-out' && !didConsent) {
                // disable cookies
                cookieConsentReject();
              }
            },
            onRevokeChoice: function () {
              const type = this.options.type;

              if (type === 'opt-in') {
                // disable cookies
                cookieConsentReject();
              } else if (type === 'opt-out') {
                // enable cookies
                cookieConsentAccept();
              }
            }
          });
        }
      }, 100);
    }
  }
});
</script>
