<template>
  <component is="style" v-if="imageNormalStyling" type="text/css">
    {{ imageNormalStyling }}
  </component>
  <component is="style" v-if="imageHoverStyling && allowHoverStyling" type="text/css">
    {{ imageHoverStyling }}
  </component>
  <div v-if="src" class="lf-image" :style="model.state.elementStyling?.wrapper">
    <img
      v-if="src"
      class="lf-image__img"
      :style="model.state.elementStyling?.inline"
      :class="imgClassList"
      :src="src"
      :alt="model.state.altText"
      :title="model.state.altText"
      @mouseenter="onMouseOver"
      @mouseleave="onMouseOut"
      @click="onImageClick"
    />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { onBeforeUnmount } from 'vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import hotkeys from 'hotkeys-js';
import type { AddonLfImageModel, ImageFilterState } from '@/src/components/addons/lf-image/Model';
import { applyReplacementTags, isSsr, preloadImagePromise } from '@/src/utilities/Utilities';
import { ImageSourceType } from '@/src/components/addons/lf-image/Data';
import { ActionType, KeyBinding } from '@/src/typings/enums/enums';
import { isValidHttpUrl } from '@/src/utilities/Url';
import type { AddonRegistrationModel } from '@/src/components/addons/registration/Model';
import { setFormReplacementTags, submitForm } from '@/src/utilities/Registration';
import { FormDisableDefaultBehavior } from '@/src/exceptions/FormDisableDefaultBehavior';
import useDevice from '@/src/hooks/useDevice';
import { useCampaignStore } from '@/src/store/campaign';

export default defineComponent({
  name: 'AddonLfImage',
  inheritAttrs: false,
  props: {
    model: {
      type: Object as PropType<AddonLfImageModel>,
      required: true
    }
  },
  setup(props) {
    const campaignStore = useCampaignStore();
    const playHoverAnimation = ref(false);
    const imageNotLoaded = ref(false);
    let enterAnimationInProgress = false;
    const allowHoverStyling = ref(true);
    const { isMobile } = useDevice();

    if (isMobile) {
      allowHoverStyling.value = false;
    }

    const isFormSubmit = ref(false);

    const isDisabled = computed(() => {
      return isFormSubmit.value || props.model.state.action?.state.evaluating;
    });

    const onImageClick = async (event?: Event) => {
      if (isDisabled.value || campaignStore.model?.state.isEditModeActive) {
        return;
      }

      // We should only stop propagation if we actually have an action.
      if (event && props.model.state.action?.state.type) {
        event.stopPropagation();
      }

      let defaultBehavior = true;

      // We should only attempt to submit registration forms if it's action is manipulating with the flow.
      if (
        props.model.state.action?.state.type &&
        [ActionType.FORCE_SKIP_REGISTRATION, ActionType.GOTO_FLOW_PAGE].includes(props.model.state.action.state.type)
      ) {
        const hasRegistrationAddon = props.model.getSection().getAddons<AddonRegistrationModel>('registration');

        if (hasRegistrationAddon.length > 0 && props.model.state.action?.state.type === ActionType.GOTO_FLOW_PAGE) {
          const section = props.model.getSection();
          isFormSubmit.value = true;

          try {
            await submitForm(section);
            setFormReplacementTags();
          } catch (e) {
            if (e instanceof FormDisableDefaultBehavior) {
              defaultBehavior = e.allowContinueInFlow;
            } else {
              // If exception wasn't the exception to disable default behavior we should bubble up the exception.
              throw e;
            }
          }
        }
      }

      if (props.model.state.action?.state && defaultBehavior) {
        await props.model.state.action?.triggerAction(props.model, props.model.column?.replacementTags);
      }

      isFormSubmit.value = false;
    };

    const src = computed(() => {
      const replacementTagSrc = applyReplacementTags(
        props.model.state.sourceType === ImageSourceType.BROWSE
          ? props.model.state.src ?? ''
          : `#${props.model.state.replacementTag ?? ''}#`,
        props.model.column?.replacementTags
      );

      let result = replacementTagSrc;

      // checking that it's not an url and instead <img tag>
      // if img tag then we will extract the src and return the result
      if (replacementTagSrc && !isValidHttpUrl(replacementTagSrc)) {
        const regex = /<img.*?src="(.*?)"/g;
        result = regex.exec(replacementTagSrc)?.[1] ?? replacementTagSrc;
      }

      if (!isValidHttpUrl(result) && !result.startsWith('/files')) {
        return '';
      }

      return result;
    });

    const generateFilterStyles = (data: ImageFilterState) => {
      const filterState = data;
      if (!filterState) {
        return undefined;
      }

      let filterStyle = '';
      filterStyle += ` opacity(${filterState.opacity * 100}%) `;
      filterStyle += ` blur(${filterState.blur}px) `;
      filterStyle += ` contrast(${filterState.contrast}%) `;
      filterStyle += ` saturate(${filterState.saturation}%) `;
      filterStyle += ` hue-rotate(${filterState.hue}deg) `;
      filterStyle += ` brightness(${filterState.brightness}%) `;
      return filterStyle;
    };

    const imageFilterStylesNormal = computed(() => {
      const filterState = props.model.state.settings?.layout?.filters?.normal;

      if (filterState) {
        return generateFilterStyles(filterState);
      }

      return undefined;
    });

    const imageFilterStylesHover = computed((): string | undefined => {
      const filterState = props.model.state.settings?.layout?.filters?.hover;

      if (filterState) {
        return generateFilterStyles(filterState);
      }

      return undefined;
    });

    const generateStyling = (imageTransition?: string, filterStyles?: string) => {
      let style = '';

      if (filterStyles === 'normal') {
        style += `
        -webkit-filter: ${imageFilterStylesNormal.value};
        filter: ${imageFilterStylesNormal.value};
      `;
      }

      if (filterStyles === 'hover') {
        style += `
        -webkit-filter: ${imageFilterStylesHover.value};
        filter: ${imageFilterStylesHover.value};
      `;
      }

      if (imageTransition && filterStyles) {
        style += `
        -webkit-transition: -webkit-${imageTransition};
        transition: -webkit-${imageTransition};
        -o-transition: ${imageTransition};
        transition: ${imageTransition};
        transition: ${imageTransition}, -webkit-${imageTransition};
      `;
      }

      return style;
    };

    const imageNormalStyling = computed(() => {
      const imageTransition = props.model.state.elementStyling?.normal?.transition ?? 'filter 1s ease';
      const style = generateStyling(imageTransition, 'normal');

      if (style && style !== '') {
        return style ? `.${props.model.state.classIdentifier} .lf-image img { ${style} }` : '';
      } else {
        return undefined;
      }
    });

    const imageHoverStyling = computed(() => {
      const imageTransition = props.model.state.elementStyling?.hover?.transition ?? 'filter 1s ease';
      const style = generateStyling(imageTransition, 'hover');

      if (style && style !== '') {
        return style ? `.${props.model.state.classIdentifier} .lf-image img:hover { ${style} }` : '';
      } else {
        return undefined;
      }
    });

    const onKeybindingPress = (e: KeyboardEvent) => {
      onImageClick(e);
    };

    const unbindKeybindings = () => {
      if (isSsr()) {
        return;
      }

      hotkeys.unbind('escape', onKeybindingPress);
      hotkeys.unbind('enter', onKeybindingPress);
    };

    const initKeybindings = () => {
      if (isSsr()) {
        return;
      }

      // Unbind the keybinding before we bind new ones.
      unbindKeybindings();

      if (props.model.state.action?.state.keybinding) {
        switch (props.model.state.action?.state.keybinding) {
          case KeyBinding.ENTER:
            hotkeys('enter', onKeybindingPress);
            break;

          case KeyBinding.ESCAPE:
            hotkeys('escape', onKeybindingPress);
            break;
        }
      }
    };

    const onMouseOver = () => {
      if (!enterAnimationInProgress) {
        playHoverAnimation.value = true;
        enterAnimationInProgress = true;

        setTimeout(
          () => {
            enterAnimationInProgress = false;
            playHoverAnimation.value = false;
          },
          props.model.state.settings?.layout?.filters?.hover.enterDuration
            ? props.model.state.settings.layout.filters.hover.enterDuration * 1000
            : 525
        );
      }
    };

    const onMouseOut = () => {
      if (!enterAnimationInProgress) {
        playHoverAnimation.value = false;
      }
    };

    onMounted(async () => {
      if (readyPromiseResolve) {
        if (src.value) {
          // waiting to load image before transition
          try {
            await preloadImagePromise(src.value);
            readyPromiseResolve();
          } catch (error) {
            imageNotLoaded.value = true;
            // eslint-disable-next-line
            console.error(`Could not load the image, ${src.value}`);
            // TODO: Decide and implement better error handling when image is not loaded.
            readyPromiseResolve();
          }
        } else {
          readyPromiseResolve();
        }
      }

      props.model.state.action?.startAutoTrigger();
    });

    if (!isSsr()) {
      onBeforeUnmount(unbindKeybindings);
    }

    watch(() => props.model.state.action?.state?.keybinding, initKeybindings, { immediate: true });

    let readyPromiseResolve: (() => void) | undefined;
    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const imgClassList = computed(() => {
      return {
        ...props.model.state.imageClasses,
        ...(playHoverAnimation.value &&
          props.model.state.settings?.layout?.filters?.hover.enter && {
            animated: true,
            [`${props.model.state.settings.layout.filters.hover.enter}`]: true
          }),
        'lf-image__img--not-loaded': imageNotLoaded.value
      };
    });

    return {
      allowHoverStyling,
      imageNormalStyling,
      imageHoverStyling,
      onImageClick,
      src,
      imgClassList,
      onMouseOver,
      onMouseOut,
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>

<style lang="scss">
.lf-image {
  display: block;
  font-size: 0;
  line-height: 0;

  &__img {
    display: inline-block;
    border: 0;
    .grid__addon--position-absolute:not(.grid__addon--width-custom) & {
      max-width: none;
    }

    &--clickable {
      cursor: pointer;
    }
    &--not-loaded {
      height: 0;
      visibility: hidden;
    }
  }

  &__placeholder {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    background: linear-gradient(to bottom, #cccccc, #e2e2e2);
    color: #383838;

    .fa {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 5rem;
      opacity: 0.1;
    }

    &--clickable {
      cursor: pointer;
    }
  }
}

.site--ie .grid__addon--lf-image {
  flex-shrink: 0;
}
</style>
