<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <div
    class="element-toolbar"
    :class="{
      'element-toolbar--green': isGreen,
      'element-toolbar--in-column': isColumnModel
    }"
  >
    <div class="element-toolbar__content">
      <div class="element-toolbar__delete" @click.stop="onDelete">
        <p-icon icon="trash" size="small" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';
import { defineComponent } from 'vue';
import { SectionBaseModel } from '../../layout/section/SectionBaseModel';
import RowModel from '../../layout/row/RowModel';
import { AddonRegistrationModel } from '../../addons/registration/Model';
import type { ModelTypes, SectionModelType } from '@/src/typings/types/types';
import ColumnModel from '@/src/components/layout/column/ColumnModel';
import { useEditingStore } from '@/src/store/editing';
import { useCampaignStore } from '@/src/store/campaign';
import { SectionType } from '@/src/typings/enums/enums';
import AddonModel from '@/src/models/grid/AddonModel';
import useFlow from '@/src/hooks/useFlow';

export default defineComponent({
  name: 'DeleteElement',
  props: {
    model: {
      type: Object as PropType<ModelTypes>,
      required: true
    },
    /**
     * If this prop is true, the "trashcan" icon will become green. You can see this when marking/activating an addon
     */
    isGreen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const editingStore = useEditingStore();
    const campaignStore = useCampaignStore();
    const { makeSpecificFlowPageActive } = useFlow();

    const isColumnModel = computed(() => {
      return props.model instanceof ColumnModel;
    });

    const onDelete = () => {
      let hasRegistrationAddon = false;

      if (
        props.model instanceof SectionBaseModel ||
        props.model instanceof RowModel ||
        props.model instanceof ColumnModel
      ) {
        hasRegistrationAddon = props.model
          .getAddons<AddonRegistrationModel>('registration')
          .some((addon: AddonRegistrationModel) => addon.state?.fields && addon.state?.fields.length > 0);
      } else if (
        props.model instanceof AddonRegistrationModel &&
        props.model.state.fields &&
        props.model.state.fields?.length > 0
      ) {
        hasRegistrationAddon = true;
      }

      if (hasRegistrationAddon && campaignStore.model?.state?.config?.hasRegistrations) {
        editingStore.showError(
          'Attention!',
          'Looks like you are trying to delete a registration form, however, this campaign already has registrations.\n' +
            'In order to delete this element, you must first delete all registrations collected on the campaign.'
        );
        return;
      }

      editingStore.showDeletePrompt(handlePromptButtonClicked);
    };

    const handlePromptButtonClicked = async (): Promise<void> => {
      const currentIndex = props.model.index;
      const sectionType = props.model instanceof SectionBaseModel ? props.model.getSectionType() : undefined;

      await props.model.delete();

      editingStore.removeActiveModel();

      /**
       * Make first section active
       */
      if (props.model instanceof SectionBaseModel) {
        let firstElement: SectionModelType | undefined;
        if (sectionType === SectionType.SECTION) {
          firstElement = campaignStore.model?.getFirstSection();
        } else if (sectionType === SectionType.FLOWPAGE) {
          firstElement = campaignStore.model?.getFirstFlowPage();
        } else if (sectionType === SectionType.POPOVER) {
          firstElement = campaignStore.model?.getFirstPopover();
        }

        if (firstElement) {
          editingStore.setActiveModel(firstElement);
        } else {
          editingStore.removeActiveModel();
        }

        if (sectionType === SectionType.FLOWPAGE && firstElement) {
          await makeSpecificFlowPageActive(firstElement.state.id);
        }
      }

      /**
       * Make either parent row, next row or parent section active
       */
      if (props.model instanceof RowModel) {
        let row = props.model.section.getRowByIndex(currentIndex - 1);
        if (!row) {
          row = props.model.section.getRowByIndex(currentIndex + 1);
        }
        if (row) {
          editingStore.setActiveModel(row);
        } else {
          editingStore.setActiveModel(props.model.section);
        }
      }

      /**
       * Make either parent column, next column or parent row active
       */
      if (props.model instanceof ColumnModel) {
        let column = props.model.row.getColumnByIndex(currentIndex - 1);
        if (!column) {
          column = props.model.row.getColumnByIndex(currentIndex + 1);
        }
        if (column) {
          editingStore.setActiveModel(column);
        } else {
          editingStore.setActiveModel(props.model.row);
        }
      }

      /**
       * Make either parent addon, next addon or parent row column
       */
      if (props.model instanceof AddonModel && props.model.column) {
        let addon = props.model.column.getAddonByIndex(currentIndex - 1);

        if (!addon) {
          addon = props.model.column.getAddonByIndex(currentIndex + 1);
        }

        if (addon) {
          editingStore.setActiveModel(addon);
        } else {
          editingStore.setActiveModel(props.model.column);
        }
      }
    };

    return {
      isColumnModel,
      handlePromptButtonClicked,
      onDelete
    };
  }
});
</script>

<style lang="scss">
.grid__addon--position-fixed {
  .edit-title {
    transform: translateY(-100%);
  }
  .element-toolbar {
    transform: translateY(-100%);
    left: 61px;
    right: auto;
  }
}

.element-toolbar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  display: flex;
  width: 25px;
  height: 25px;
  background: $edit-border-color;
  line-height: 24px;
  color: #e3e3e3;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  float: right;
  transition: color 125ms linear;
  pointer-events: all;
  justify-content: center;
  align-items: center;

  &--green {
    background: $edit-addon-border-color;
    color: #006349 !important;

    &:hover {
      color: #232424 !important;
    }
  }

  &:hover {
    color: #fff;
  }
}
</style>
