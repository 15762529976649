import type {
  AddonQuestionValidationData,
  AddonQuestionValidationSettingsData
} from '@/src/components/addons/question-validation/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { AlignContentType } from '@/src/typings/enums/enums';
import {
  QuestionAnswers,
  QuestionRedirects,
  QuestionTransition
} from '@/src/components/addons/question-validation/enums';
import type { AdvancedAddonsStyleData } from '@/src/typings/interfaces/data/settings/settings';

interface AddonQuestionValidationState extends AddonModelState {
  settings: AddonQuestionValidationSettingsState;
}

interface AddonQuestionValidationAnswer {
  id: number;
  answer: string;
}

export interface AddonQuestionValidationSettingsState {
  question?: string;
  answers?: AddonQuestionValidationAnswer[];
  contentAlignment?: AlignContentType;
  correctAnswer?: QuestionAnswers;
  button?: AddonQuestionValidationButtonSettings;
  questionRedirect?: QuestionRedirects;
  flowPage?: number;
  transition: QuestionTransition;
  error?: AddonQuestionValidationErrorSettings;
  popoverText?: string;
  advanced?: AdvancedAddonsStyleData;
}
interface AddonQuestionValidationButtonSettings {
  label: string;
  alignment: AlignContentType;
}
interface AddonQuestionValidationErrorSettings {
  message: string;
  alignment: AlignContentType;
}

export class AddonQuestionValidationModel extends AddonModel<
  AddonQuestionValidationData,
  AddonQuestionValidationState,
  'question-validation'
> {
  parseAddon(data: AddonQuestionValidationData) {
    const state = this.state;

    state.settings = state.settings || {};

    state.settings = AddonQuestionValidationModel.constructQuestionValidationSetting(data.settings);
  }

  private static constructQuestionValidationSetting(
    data: AddonQuestionValidationSettingsData
  ): AddonQuestionValidationSettingsState {
    const button: AddonQuestionValidationButtonSettings = {
      label: data?.button_label || 'Choose answer',
      alignment: data?.button_alignment || AlignContentType.CENTER
    };

    const error: AddonQuestionValidationErrorSettings = {
      message: data?.error_message || '',
      alignment: data?.error_alignment || AlignContentType.LEFT
    };

    const answers: AddonQuestionValidationAnswer[] = [];

    // there will always be 4 answer & they are empty by default
    answers.push(
      { id: 1, answer: data?.answer1 ? data.answer1 : '' },
      { id: 2, answer: data?.answer2 ? data.answer2 : '' },
      { id: 3, answer: data?.answer3 ? data.answer3 : '' },
      { id: 4, answer: data?.answer4 ? data.answer4 : '' }
    );

    return {
      ...(data?.question && { question: data?.question }),
      ...{ contentAlignment: data?.content_alignment || AlignContentType.CENTER },
      ...{ correctAnswer: Number(data?.correct_answer) || QuestionAnswers.ONE },
      ...{ button },
      ...{ error },
      ...{ questionRedirect: data?.question_redirect || QuestionRedirects.SHOW_MESSAGE },
      ...(data?.flow_page && { flowPage: Number(data?.flow_page) }),
      ...{ transition: data?.transition || QuestionTransition.SLIDE_IN },
      answers,
      ...(data?.popover_text && { popoverText: data?.popover_text })
    };
  }

  public isAddonValid(): boolean {
    return !!this.getData().settings.question;
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
