<template>
  <button
    :id="id"
    :type="buttonType"
    :class="buttonEffectClasses"
    class="content__item-action btn btn-lg"
    :disabled="isDisabled"
    :tabindex="tabindex"
    :style="isContenteditable ? 'white-space: pre-wrap' : ''"
    @click="handleButtonClick"
  >
    <slot></slot>
    <DotsLoader v-if="!isContenteditable" />
  </button>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import useButtonEffects from '@/src/hooks/useButtonEffects';
import DotsLoader from '@/src/components/ui/DotsLoader.vue';
import { ActionType } from '@/src/typings/enums/enums';
import { useCampaignStore } from '@/src/store/campaign';

type ButtonStyle = 'primary' | 'secondary';
type ButtonType = 'submit' | 'reset' | 'button';

export default defineComponent({
  name: 'UIButton',
  components: { DotsLoader },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    buttonType: {
      type: String as PropType<ButtonType>,
      default: 'button'
    },
    id: {
      type: String,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonStyle: {
      type: String as PropType<ButtonStyle>,
      default: 'primary'
    },
    preventStop: {
      type: String as PropType<ActionType>,
      default: ActionType.NONE
    },
    tabindex: {
      type: Number,
      default: undefined
    },
    isContenteditable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup(props, context) {
    const campaignStore = useCampaignStore();
    const { applyButtonEffects } = useButtonEffects();

    const buttonId = computed(() => {
      if (typeof context.attrs.id === 'string') {
        return typeof context.attrs.id;
      } else {
        return undefined;
      }
    });

    const handleButtonClick = (e: MouseEvent) => {
      if (props.preventStop !== ActionType.NONE) {
        e.preventDefault();
        e.stopPropagation();
      }
      context.emit('click');
    };

    const isEditModeActive = computed(() => {
      return campaignStore.model?.state.isEditModeActive;
    });

    const buttonEffectClasses = computed(() => {
      if (isEditModeActive.value) {
        return '';
      }

      let buttonEffect = '';
      if (props.buttonStyle === 'primary') {
        buttonEffect = applyButtonEffects('btn--primary');
      }
      if (props.buttonStyle === 'secondary') {
        buttonEffect = applyButtonEffects('btn--secondary');
      }
      return {
        [buttonEffect]: true,
        'btn--loading': props.isLoading,
        [`btn--${props.buttonStyle}`]: true,
        'content__item-action--disabled': props.isDisabled
      };
    });

    return {
      buttonEffectClasses,
      buttonId,
      handleButtonClick
    };
  }
});
</script>
