import { applyReplacementTags, copyToClipboard, inIframe, isSsr } from '@/src/utilities/Utilities';
import type { ReplacementTags } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import { BaseModel } from '@/src/models/BaseModel';
import type { ActionData } from '@/src/typings/interfaces/data/settings/actions';
import { ActionType } from '@/src/typings/enums/enums';
import useFlow from '@/src/hooks/useFlow';
import { events } from '@/src/services/events';
import { redirect, RedirectTarget } from '@/src/services/url';
import { FlowPageDoesNotExists } from '@/src/exceptions/FlowPageDoesNotExists';
import { SdkActionEvent } from '@/src/sdk/events/actionEvent';
import type { ModelTypes } from '@/src/typings/types/types';
import { SdkEventsEmitter } from '@/src/sdk/interface';
import { sendEvent } from '@/src/utilities/iFrameUtils';
import type { AddonRegistrationModel } from '@/src/components/addons/registration/Model';
import { endGame } from '@/src/hooks/useEndGame';

export interface ActionModelState {
  url?: string | null;
  type: ActionType;
  target?: string;
  flowPage?: number | null;
  section?: string | null;
  popover?: number;
  onClickEvent?: string | null;
  delay?: number | null;
  keybinding?: string | null;
  autoTrigger?: number;
  dontShowPopupAfter: boolean;
  evaluating?: boolean;
  copyValue?: string;

  // Below are settings for finish_game action type
  gameWinner: boolean;
  replacementTag?: {
    name: string;
    value: string;
  };
}

export default class ActionsModel extends BaseModel<ActionData, ActionModelState> {
  private timer: number | undefined;

  parse(data: ActionData) {
    const state = this.state;
    state.url = data.url || null;
    state.target = data.target || '_blank';
    state.type = data.type || null;
    state.section = data.section || null;

    if (data.popover) {
      state.popover = Number(data.popover);
    } else if (data.id) {
      // TODO: Figure out why we're doing this. I'm fairly sure this is a mistake that the below was made...
      state.popover = Number(data.id);
    }

    state.onClickEvent = data.event || null;
    state.delay = data.delay || null;
    state.flowPage = data.flow_page ? Number(data.flow_page) : null;
    state.keybinding = data.keybinding || null;
    state.copyValue = data.copy_value;
    state.autoTrigger = data.auto_trigger ? Number(data.auto_trigger) * 1000 : undefined;
    state.dontShowPopupAfter = data.dont_show_popup_after === '1';
    state.gameWinner = data.game_status === 'winner';

    if (data.replacementtag?.name) {
      state.replacementTag = {
        name: data.replacementtag.name,
        value: data.replacementtag.value
      };
    } else {
      state.replacementTag = undefined;
    }
  }

  /**
   * We cannot start the timer in our construct as the element containing the actions model
   * might not be rendered yet. We'll leave it up to the components that utilize ActionsModel
   * to manually register the auto trigger.
   *
   * The callback parameter is options. This is used to overwrite the "trigger". Use cases ca be if
   * you manually call the triggerAction function but wishes to do something before triggering.
   */
  async startAutoTrigger(callback?: () => void): Promise<void> {
    if (!isSsr() && typeof this.timer === 'undefined' && this.state.autoTrigger) {
      this.timer = window.setTimeout(async () => {
        if (callback) {
          callback();
        } else {
          await this.triggerAction();
        }

        this.timer = undefined;
      }, this.state.autoTrigger);
    }
  }

  /**
   *
   * @param origin used in SDK to tell where action is invoked
   * @param replacementTags used in type URL - to apply replacement tags in path.
   */
  async triggerAction(origin?: ModelTypes, replacementTags?: ReplacementTags): Promise<void> {
    const startTime = new Date().getTime();

    if (!isSsr() && typeof this.timer !== 'undefined') {
      window.clearTimeout(this.timer);
      this.timer = undefined;
    }

    const campaignStore = useCampaignStore();

    // Disable click events if button is disabled or if we're in edit mode...
    if (this.state.evaluating || campaignStore.model?.state.isEditModeActive) {
      return;
    }

    this.state.evaluating = true;

    if (origin) {
      const sdkActionEvent = new SdkActionEvent(this, origin);
      if (sdkActionEvent) {
        SdkEventsEmitter.emit('action', sdkActionEvent);

        try {
          await sdkActionEvent.waitForPromises();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('[SDK] Action exception caught', e);
        }

        if (sdkActionEvent.defaultPrevented) {
          this.state.evaluating = false;
          return;
        }
      }
    }

    const delay = this.state.delay ?? 0;

    const actionCallback = async () => {
      const state = this.state;

      const currentUrl = new URL(window.location.href);

      switch (state.type) {
        case ActionType.URL:
          if (state.url) {
            this.goToUrl(state.url, replacementTags);
          }
          break;

        case ActionType.CLICK_TAG:
          if (inIframe()) {
            // Click tags are for ads and are handled in the parent window
            window.parent.postMessage('click_tag', '*'); // nosem
          }
          break;

        case ActionType.FINISH_GAME:
          if (state.replacementTag) {
            campaignStore.addReplacementTags({
              [`${state.replacementTag.name}`]: state.replacementTag.value
            });
          }

          await endGame(state.gameWinner);
          break;

        case ActionType.COPY_TO_CLIPBOARD:
          if (state.copyValue) {
            copyToClipboard(applyReplacementTags(state.copyValue, replacementTags));
          }
          break;

        case ActionType.RESTART:
          currentUrl.searchParams.set('restart-game', '1');

          if (inIframe()) {
            sendEvent('scroll-to-top');

            // Allow event-loop to flush the events to the receiver
            setTimeout(() => {
              window.location.href = currentUrl.toString(); // Navigate to the new URL
            }, 1);
          } else {
            window.location.href = currentUrl.toString(); // Navigate to the new URL
          }
          break;

        case ActionType.SECTION:
          if (state.section) {
            this.goToSection(state.section);
          }
          break;

        case ActionType.POPOVER:
          campaignStore.setActivePopover(state.popover);
          break;

        case ActionType.CLOSE_POPOVER:
          campaignStore.setActivePopover(undefined);
          break;

        case ActionType.GOTO_FLOW_PAGE:
          this.goToFlowPage(this.state.flowPage);
          break;

        case ActionType.ONCLICK:
          if (state.onClickEvent) {
            this.onClick(state.onClickEvent);
          }
          break;

        case ActionType.NEXTFLOW:
          useFlow().goToNextFlowPage();
          break;

        case ActionType.FLOWPAGE:
          this.goToFlowPage(this.state.flowPage);
          break;

        case ActionType.FORCE_SKIP_REGISTRATION:
          this.onForceSkipRegistration(state.flowPage);
          break;
      }

      this.state.evaluating = false;
    };

    if (delay) {
      const currentTime = new Date().getTime();
      const elapsedTime = (currentTime - startTime) / 1000;
      const adjustedDelay = Math.max(0, delay - elapsedTime);

      const delayTimer = window.setTimeout(async () => {
        await actionCallback();
      }, adjustedDelay * 1000);

      if (
        this.state.type === ActionType.NEXTFLOW ||
        this.state.type === ActionType.GOTO_FLOW_PAGE ||
        this.state.type === ActionType.FLOWPAGE
      ) {
        events.once('flowPageChange', () => {
          window.clearTimeout(delayTimer);
        });
      }
    } else {
      actionCallback();
    }

    // Allow others to hook in and add custom action behaviors.
    events.emit('invalidAction', this);
  }

  goToUrl(url: string, replacementTags?: ReplacementTags) {
    let target: RedirectTarget | undefined;

    switch (this.state.target) {
      case '_blank':
        target = RedirectTarget.BLANK;
        break;

      case '_top':
        target = RedirectTarget.TOP;
        break;

      case '_self':
        target = RedirectTarget.SELF;
        break;
    }

    redirect(
      {
        url,
        target: target ?? RedirectTarget.BLANK
      },
      replacementTags
    );
  }

  goToSection(id: string) {
    const el = document.querySelector(`.section--original-${id}`);

    if (el) {
      if (useCampaignStore().hasResponsiveScript) {
        // Calculate position relative to the document
        const relativeY = el.getBoundingClientRect().top;
        sendEvent('scroll-to-relative-position', { relativeY });
        return;
      } else {
        el.scrollIntoView({ behavior: 'smooth' });
      }

      return;
    }

    throw new Error(`cant find HTML element with selector .section--original-${id}`);
  }

  goToFlowPage(flowPage?: number | null) {
    if (!flowPage) {
      useFlow().goToNextFlowPage();
    }

    if (flowPage) {
      try {
        useFlow().makeSpecificFlowPageActive(flowPage);
      } catch (e) {
        if (e instanceof FlowPageDoesNotExists) {
          useFlow().goToNextFlowPage();
        } else {
          throw e;
        }
      }
    }
  }

  onClick(eventName: string) {
    const fnStr = eventName.match(/(.+)(\(.*\))/);

    if (fnStr) {
      const fnName = fnStr[1];

      // TODO: Investigate if we're able to type this differently.
      // @ts-ignore
      if (typeof window[`${fnName}`] === 'function') {
        const params = fnStr[2].replace(/["'()]/g, '').split(',');
        // @ts-ignore
        window[`${fnName}`].apply(undefined, params);
      }
    } else {
      throw new Error('No function string available');
    }
  }

  onForceSkipRegistration(flowPage?: number | null) {
    const campaignStore = useCampaignStore();

    // Mark in the store that flow registration was skipped.
    campaignStore.skippedFlowRegistration = true;

    // Since user chose to skip registration, we need to reset all the fields
    // so that visibility conditions further down the flow are not affected by these values.
    campaignStore.flowModel?.getAddons<AddonRegistrationModel>('registration').forEach((addon) => {
      addon.state.fields?.forEach((field) => {
        field.state.value = field.parseStringValue('');
      });
    });

    if (flowPage) {
      useFlow().makeSpecificFlowPageActive(flowPage);
    } else {
      useFlow().goToNextFlowPage();
    }
  }
}
