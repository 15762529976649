import type {
  GameGravityDodgerAvatarData,
  GameGravityDodgerBackgroundsData,
  GameGravityDodgerBackgroundsImagesData,
  GameGravityDodgerData,
  GameGravityDodgerGeneralData,
  GameGravityDodgerGeneralLayoutData,
  GameGravityDodgerObstaclesData,
  GameGravityDodgerObstaclesImagesData,
  GameGravityDodgerPointsData,
  GameGravityDodgerPointsImagesData,
  GameGravityDodgerVisualsData
} from '@/src/components/games/gravity-dodger/Data';
import type { HasGameTimeChallenge, GameIndicator, GameTimeChallenge, GameState } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import type { DeviceData } from '@/src/hooks/useDevice';
import { getDeviceData } from '@/src/hooks/useDevice';
import {
  GravityDodgerAnimation,
  GravityDodgerGameSpeed,
  GravityDodgerVerticalPlacement
} from '@/src/components/games/gravity-dodger/interfaces';

interface GameGravityDodgerState extends GameState {
  general: GameGravityDodgerGeneralState;
  visuals: GameGravityDodgerVisualsState;
}

interface GameGravityDodgerGameSettingsState {
  gameSpeed: GravityDodgerGameSpeed;
  backgroundSpawnInterval: number;
  obstacleSpawnInterval: number;
  pointSpawnInterval: number;
}

interface GameGravityDodgerGeneralState {
  pointsToWin: number;
  gameSettings?: GameGravityDodgerGameSettingsState;
  maxRounds: number;
  layout: GameGravityDodgerGeneralLayoutState;
}

interface GameGravityDodgerGeneralLayoutState {
  positiveColor?: string;
  negativeColor?: string;
  fontSize?: string;
  gameHeight?: string;
}

interface GameGravityDodgerVisualsState {
  avatar?: GameGravityDodgerAvatarState;
  backgrounds?: GameGravityDodgerBackgroundsState;
  points?: GameGravityDodgerPointsState;
  obstacles?: GameGravityDodgerObstaclesState;
}

export interface GameGravityDodgerAvatarState {
  image?: string;
  maxWidth?: number;
  animation?: GravityDodgerAnimation;
  jumpPower: number;
  offsetX: number;
}

export interface GameGravityDodgerBackgroundsState {
  images?: GameGravityDodgerBackgroundsImagesState[];
}

export interface GameGravityDodgerBackgroundsImagesState {
  id: string;
  image: string;
  maxWidth?: number;
  placement: GravityDodgerVerticalPlacement;
  offset: number;
  speed: GravityDodgerGameSpeed;
}

export interface GameGravityDodgerPointsState {
  images?: GameGravityDodgerPointsImagesState[];
}

export interface GameGravityDodgerPointsImagesState {
  id: string;
  image: string;
  maxWidth?: number;
  score: number;
  label: string;
}

export interface GameGravityDodgerObstaclesState {
  images?: GameGravityDodgerObstaclesImagesState[];
}

export interface GameGravityDodgerObstaclesImagesState {
  id: string;
  image: string;
  maxWidth?: number;
  placement: GravityDodgerVerticalPlacement;
  flipVertically: boolean;
  offset: number;
}

export class GameGravityDodgerModel
  extends GameModel<GameGravityDodgerData, GameGravityDodgerState>
  implements HasGameTimeChallenge
{
  parseGame(data: GameGravityDodgerData) {
    const state = this.state;

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    } else {
      state.indicators = undefined;
    }

    state.general = GameGravityDodgerModel.constructGeneralState(data.general);
    state.visuals = GameGravityDodgerModel.constructVisualsState(data.visuals);
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();
    return {
      enabled: data.general?.time === 1,
      ...(data.general?.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  private static parseVerticalPlacement(value: string): GravityDodgerVerticalPlacement {
    switch (value) {
      case 'top':
        return GravityDodgerVerticalPlacement.TOP;
      case 'bottom':
        return GravityDodgerVerticalPlacement.BOTTOM;
      case 'middle':
        return GravityDodgerVerticalPlacement.MIDDLE;
      case 'random':
        return GravityDodgerVerticalPlacement.RANDOM;
      default:
        throw new Error(`Unknown VerticalPlacement value: ${value}`);
    }
  }

  private static parseGameSpeed(value: string): GravityDodgerGameSpeed {
    switch (value) {
      case 'slow':
        return GravityDodgerGameSpeed.SLOW;
      case 'normal':
        return GravityDodgerGameSpeed.NORMAL;
      case 'fast':
        return GravityDodgerGameSpeed.FAST;
      default:
        throw new Error(`Unknown GravityDodgerGameSpeed value: ${value}`);
    }
  }

  private static parseAnimation(value: string): GravityDodgerAnimation {
    switch (value) {
      case 'no_animation':
        return GravityDodgerAnimation.NO_ANIMATION;
      case 'pulse':
        return GravityDodgerAnimation.PULSE;
      default:
        throw new Error(`Unknown GravityDodgerAnimation value: ${value}`);
    }
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.ABSOLUTE,
      bottom: GameIndicatorPositionType.ABSOLUTE
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit || 0,
          time_used: 0
        }
      },
      {
        indicatorKey: 'score',
        metricKey: {
          score_used: 'score'
        },
        icon: GameIndicatorIcon.SCORE,
        value: {
          score: 0
        }
      },
      {
        indicatorKey: 'round',
        metricKey: {
          round_used: 'rounds'
        },
        icon: GameIndicatorIcon.ROUNDS,
        value: {
          round_used: 1
        }
      }
    ];
  }

  private static parseGeneralGameSettingsDeviceData(
    data: GameGravityDodgerGeneralData
  ): GameGravityDodgerGameSettingsState | undefined {
    if (data.game_settings) {
      const useData = getDeviceData(data.game_settings);
      if (!useData) {
        return undefined;
      }

      return {
        gameSpeed: this.parseGameSpeed(useData.game_speed) ?? GravityDodgerGameSpeed.NORMAL,
        backgroundSpawnInterval: useData.background_spawn_interval ?? 700,
        obstacleSpawnInterval: useData.obstacle_spawn_interval ?? 1500,
        pointSpawnInterval: useData.point_spawn_interval ?? 3000
      };
    }
  }

  private static constructGeneralState(data: GameGravityDodgerGeneralData): GameGravityDodgerGeneralState {
    return {
      gameSettings: GameGravityDodgerModel.parseGeneralGameSettingsDeviceData(data),
      pointsToWin: data?.points_to_win,
      maxRounds: Number(data?.max_rounds),
      layout: this.constructLayoutState(data.layout)
    };
  }

  private static constructLayoutState(
    data: DeviceData<GameGravityDodgerGeneralLayoutData>
  ): GameGravityDodgerGeneralLayoutState {
    const layoutData = getDeviceData(data);

    return {
      positiveColor: layoutData?.positive_color ?? '#00ff00',
      negativeColor: layoutData?.negative_color ?? '#b22222',
      fontSize: layoutData?.font_size,
      gameHeight: layoutData?.game_height
    };
  }

  private static constructVisualsState(data: GameGravityDodgerVisualsData): GameGravityDodgerVisualsState {
    const avatarData = getDeviceData<GameGravityDodgerAvatarData>(data?.avatar);
    const obstaclesData = getDeviceData<GameGravityDodgerObstaclesData>(data?.obstacles);
    const pointsData = getDeviceData<GameGravityDodgerPointsData>(data?.points);
    const backgroundsData = getDeviceData<GameGravityDodgerBackgroundsData>(data.backgrounds);

    return {
      avatar: avatarData !== undefined ? this.constructAvatarState(avatarData) : undefined,
      backgrounds: backgroundsData !== undefined ? this.constructBackgroundsState(backgroundsData) : undefined,
      obstacles: obstaclesData !== undefined ? this.constructObstaclesState(obstaclesData) : undefined,
      points: pointsData !== undefined ? this.constructPointsState(pointsData) : undefined
    };
  }

  private static constructAvatarState(data: GameGravityDodgerAvatarData): GameGravityDodgerAvatarState {
    return {
      image: data?.image,
      maxWidth: data?.max_width,
      animation: this.parseAnimation(data?.animation ?? 'no_animation'),
      jumpPower: -Math.abs(data?.jump_power ?? 500),
      offsetX: Number(data?.offset_x) || 50
    };
  }

  private static constructBackgroundsState(
    data: GameGravityDodgerBackgroundsData
  ): GameGravityDodgerBackgroundsState | undefined {
    if (data.images === undefined) {
      return undefined;
    }

    return {
      images: data.images.map<GameGravityDodgerBackgroundsImagesState>(
        (element: GameGravityDodgerBackgroundsImagesData) => ({
          id: element.id,
          image: element.image,
          maxWidth: element.max_width || undefined,
          placement: this.parseVerticalPlacement(String(element.placement)),
          offset: element.offset,
          speed: this.parseGameSpeed(element.speed) ?? GravityDodgerGameSpeed.NORMAL
        })
      )
    };
  }

  private static constructObstaclesState(
    data: GameGravityDodgerObstaclesData
  ): GameGravityDodgerObstaclesState | undefined {
    if (data.images === undefined) {
      return undefined;
    }

    return {
      images: data.images.map<GameGravityDodgerObstaclesImagesState>(
        (element: GameGravityDodgerObstaclesImagesData) => ({
          id: element.id,
          image: element.image,
          maxWidth: element.max_width || undefined,
          placement: this.parseVerticalPlacement(String(element.placement)),
          flipVertically: element.flip_vertically === '1',
          offset: element.offset
        })
      )
    };
  }

  private static constructPointsState(data: GameGravityDodgerPointsData): GameGravityDodgerPointsState | undefined {
    if (data.images === undefined) {
      return undefined;
    }

    return {
      images: data.images.map<GameGravityDodgerPointsImagesState>((element: GameGravityDodgerPointsImagesData) => ({
        id: element.id,
        image: element.image,
        maxWidth: element.max_width || undefined,
        score: element.score,
        label: element.label
      }))
    };
  }

  public isGameValid(): boolean {
    return true;
  }
}
