import { useCampaignStore } from '@/src/store/campaign';
import { useUtilityStore } from '@/src/store/utility';
import { inIframe, isSsr } from '@/src/utilities/Utilities';
import { sendEvent } from '@/src/utilities/iFrameUtils';
import { events } from '@/src/services/events';

interface Config {
  timer: number | null;
  resizing: boolean;
  animationFrameId: number | null;
}

const config: Config = {
  timer: null,
  resizing: false,
  animationFrameId: null
};

const setWindowHeightInState = () => {
  const utilityStore = useUtilityStore();
  const campaignStore = useCampaignStore();

  if (
    // Only update the height if it did in fact change. No reason to do it otherwise.
    window.innerHeight !== utilityStore.windowHeight &&
    // The below check ensures that in case it would attempt to get the window height
    // from the top level in a popup context, then we don't fetch it from current window.
    !(
      campaignStore.model?.state.isPopup &&
      inIframe() &&
      utilityStore.url &&
      !utilityStore.url.includes('template=overlay')
    )
  ) {
    utilityStore.overwrittenWindowHeight = window.innerHeight;
  }
};

export const onResize = () => {
  if (isSsr()) {
    return;
  }

  if (inIframe()) {
    if (config.timer !== null) {
      clearTimeout(config.timer);
      config.timer = null;
    }

    config.timer = window.setTimeout(onResizeEnd, 1500);

    if (!config.resizing) {
      config.resizing = true;
      onResizeFrame();
    }
  } else {
    setWindowHeightInState();
  }
};

const onResizeEnd = () => {
  onResizeFrame();

  if (config.timer !== null) {
    clearTimeout(config.timer);
    config.timer = null;
  }

  if (config.animationFrameId !== null) {
    cancelAnimationFrame(config.animationFrameId);
    config.animationFrameId = null;
  }

  config.timer = null;
  config.resizing = false;
};

const onResizeFrame = () => {
  if (config.resizing) {
    config.animationFrameId = requestAnimationFrame(onResizeFrame);
  }

  sendEvent('resize', {
    width: document.body.offsetWidth,
    height: document.body.offsetHeight
  });

  setWindowHeightInState();

  events.emit('resizeFrame');
};
