import type { CSSProperties } from 'vue';
import { useUtilityStore } from '@/src/store/utility';
import type { AddonLfAnimatedHeadlineData } from '@/src/components/addons/lf-animated-headline/Data';
import {
  AnimatedHeadlineAnimationType,
  AnimatedHeadlineStyleType,
  HeadingTagType,
  ShapeType,
  TextDecorationType,
  TextStyleType,
  TextTransformType
} from '@/src/typings/enums/enums';
import type { ShadowState, TextTypographyState } from '@/src/typings/interfaces/state/SharedAddons';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { TextShadowData, TypographyData } from '@/src/typings/interfaces/data/settings/settings';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { isRgbValid, parseColorToRGBA } from '@/src/utilities/ColorHelper';
import { replaceFontIdWithFontString } from '@/src/utilities/Utilities';

interface AnimatedHeadlineLayout {
  typography?: TextTypographyState;
  shadow?: ShadowState;
}

interface AnimatedHeadlineSettingsState {
  layout?: AnimatedHeadlineLayout;
}

export interface AddonLfAnimatedHeadlineState extends AddonModelState {
  animation: AnimatedHeadlineAnimationType;
  rotatingText: string;
  rotatingTextArray: string[];
  shape?: ShapeType;
  style?: AnimatedHeadlineStyleType;
  hasRotation?: boolean;
  textAfter?: string;
  textBefore?: string;
  type?: HeadingTagType;
  highlighted?: string;
  settings?: AnimatedHeadlineSettingsState;
  elementStyling?: {
    text?: CSSProperties;
    gradientText?: CSSProperties | undefined;
    animatedTextIfGradient?: CSSProperties;
  };
}

export class AddonLfAnimatedHeadlineModel extends AddonModel<
  AddonLfAnimatedHeadlineData,
  AddonLfAnimatedHeadlineState,
  'lf-animated-headline'
> {
  parseAddon(data: AddonLfAnimatedHeadlineData) {
    const state = this.state;
    if (data) {
      state.animation = data.settings?.animation ?? AnimatedHeadlineAnimationType.SLIDE_DOWN;
      state.shape = data.settings.shape ?? ShapeType.CIRCLE;
      state.textBefore = data.settings?.text_before ?? 'Lorem ipsum';
      state.textAfter = data.settings?.text_after ?? '';
      state.style = data.settings?.style ?? AnimatedHeadlineStyleType.HIGHLIGHTED;
      state.hasRotation = [AnimatedHeadlineStyleType.ROTATION, AnimatedHeadlineStyleType.ROTATION_ONLY_TEXT].includes(
        state.style
      );
      state.highlighted = data.settings?.highlighted ?? 'dolor';
      state.type = data.settings?.type ?? HeadingTagType.H2;
      state.rotatingText = data.settings?.rotating_text ?? 'Better\n' + 'Bigger\n' + 'Faster';
      state.rotatingTextArray = state.rotatingText.split('\n') ?? [];

      if (state.style === AnimatedHeadlineStyleType.ROTATION_ONLY_TEXT) {
        state.textBefore = '';
        state.textAfter = '';
      }

      state.settings = state.settings ?? {};
      state.settings.layout = state.settings.layout ?? {};
      state.settings.layout.shadow = AddonLfAnimatedHeadlineModel.parseShadowData(data);
      state.settings.layout.typography = AddonLfAnimatedHeadlineModel.parseTypographyData(data);
    }

    state.elementStyling = state.elementStyling ?? {};
    if (state.settings?.layout?.typography) {
      const typographyState = state.settings.layout.typography;
      const shadowState = state.settings.layout.shadow;
      state.elementStyling.text = AddonLfAnimatedHeadlineModel.constructHeadlineInlineStyleState(
        typographyState,
        shadowState
      );
      if (
        typographyState.gradientOne &&
        typographyState.gradientOne !== '' &&
        typographyState.gradientTwo &&
        typographyState.gradientTwo !== ''
      ) {
        if (state?.settings?.layout?.typography) {
          state.elementStyling.gradientText = AddonLfAnimatedHeadlineModel.constructGradientText(
            state.settings.layout.typography
          );
          state.elementStyling.animatedTextIfGradient = AddonLfAnimatedHeadlineModel.constructTextColorIfGradient(
            state.settings.layout.typography
          );
        }
      }
    } else {
      state.elementStyling.text = {};
      state.elementStyling.gradientText = {};
      state.elementStyling.animatedTextIfGradient = {};
    }

    // Load font
    if (state.settings?.layout?.typography?.fontFamily) {
      const utilityStore = useUtilityStore();
      utilityStore.loadFont(state.settings.layout.typography.fontFamily);
    }
  }

  private static parseTypographyData(data: AddonLfAnimatedHeadlineData): TextTypographyState | undefined {
    if (data.settings?.layout?.typography) {
      const useData = getDeviceData<TypographyData>(data.settings.layout.typography);

      if (!useData) {
        return undefined;
      }

      return AddonLfAnimatedHeadlineModel.constructTypographyState(useData);
    }
  }

  private static constructTypographyState(data: TypographyData): TextTypographyState {
    if (data.transform === TextTransformType.DEFAULT) {
      data.transform = TextTransformType.NONE;
    }

    if (data.text_style === TextStyleType.DEFAULT) {
      data.text_style = TextStyleType.NORMAL;
    }

    if (data.text_decoration === TextDecorationType.DEFAULT) {
      data.text_decoration = TextDecorationType.NONE;
    }

    return {
      alignment: data.alignment,
      color: data.color,
      ...(data.font_family && { fontFamily: replaceFontIdWithFontString(data.font_family) }),
      ...(data.size && data.size !== '0px' && { fontSize: data.size }),
      fontWeight: Number(data.font_weight),
      gradientOne: data.gradient_1,
      gradientTwo: data.gradient_2,
      letterSpacing: Number(data.letter_spacing),
      lineHeight: Number(data.line_height),
      textDecoration: data.text_decoration,
      rotateTextColor: data.rotate_text_color,
      shapeColor: data.shape_color ? data.shape_color : '#000000',
      textStyle: data.text_style,
      transform: data.transform
    };
  }

  private static parseShadowData(data: AddonLfAnimatedHeadlineData): ShadowState | undefined {
    if (data.settings?.layout?.shadow) {
      const useData = getDeviceData<TextShadowData>(data.settings.layout.shadow);

      if (!useData) {
        return undefined;
      }

      return AddonLfAnimatedHeadlineModel.constructShadowState(useData);
    }
  }

  private static constructShadowState(data: TextShadowData): ShadowState {
    return {
      blur: data.blur && data.blur !== '' ? Number(data.blur) : 0,
      color: data.color,
      horizontal: data.horizontal && data.horizontal !== '' ? Number(data.horizontal) : 0,
      vertical: data.vertical && data.vertical !== '' ? Number(data.vertical) : 0
    };
  }

  private static constructHeadlineInlineStyleState(
    textState: TextTypographyState,
    shadowState: ShadowState | undefined
  ): CSSProperties {
    let textShadow;

    if (shadowState && shadowState.color && shadowState.color !== '') {
      textShadow = `${shadowState.horizontal}px ${shadowState.vertical}px ${shadowState.blur}px ${shadowState.color}`;
    }

    return {
      ...(textState.fontSize && { fontSize: `${textState.fontSize}` }),
      ...(textState.fontFamily && { fontFamily: `${textState.fontFamily}, 'Helvetica Neue', Arial, sans-serif` }),
      fontWeight: textState.fontWeight as CSSProperties['fontWeight'],
      fontStyle: `${textState.textStyle}`,
      letterSpacing: `${textState.letterSpacing}px`,
      lineHeight: `${textState.lineHeight}`,
      textDecoration: `${textState.textDecoration}`,
      textTransform: textState.transform as CSSProperties['textTransform'],
      color: `${textState.color} !important`,
      textAlign: textState.alignment,
      textShadow
    };
  }

  private static constructGradientText(state: TextTypographyState): CSSProperties | undefined {
    if (!state.gradientOne || !state.gradientTwo) {
      return undefined;
    }

    const gradient1 = parseColorToRGBA(state.gradientOne);
    const gradient2 = parseColorToRGBA(state.gradientTwo);

    if (gradient1 && isRgbValid(gradient1) && gradient2 && isRgbValid(gradient2)) {
      return {
        background: `linear-gradient(to right, ${state.gradientOne}, ${state.gradientTwo})`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
      };
    }
  }

  private static constructTextColorIfGradient(state: TextTypographyState): CSSProperties {
    return {
      color: state.color,
      WebkitTextFillColor: state.color
    };
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
