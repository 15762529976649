import type {
  GameTappingData,
  GameTappingGeneralData,
  GameTappingVisualsData
} from '@/src/components/games/tapping/Data';
import type { HasGameTimeChallenge, GameIndicator, GameTimeChallenge, GameState } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import type { MetricData } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';

interface GameTappingState extends GameState {
  general: GameTappingGeneralState;
  visuals: GameTappingVisualsState;
}

interface GameTappingGeneralState {
  clicksToWin: number;
}

interface GameTappingVisualsState {
  tapImageUrl: string;
}

interface GameTappingSdkSettings {
  clicksToWin: number;
}

export class GameTappingModel extends GameModel<GameTappingData, GameTappingState> implements HasGameTimeChallenge {
  parseGame(data: GameTappingData) {
    const state = this.state;

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    } else {
      state.indicators = undefined;
    }

    state.general = GameTappingModel.constructGeneralState(data.general);
    state.visuals = GameTappingModel.constructVisualsState(data.visuals);
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();
    return {
      enabled: data.general?.time === 1,
      ...(data.general?.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.timeChallenge?.limit || 0,
          time_used: 0
        }
      },
      {
        indicatorKey: 'clicks',
        metricKey: {
          clicks_used: 'clicks'
        },
        icon: GameIndicatorIcon.MOVES,
        value: {
          clicks_used: 0
        }
      }
    ];
  }

  private static constructGeneralState(data?: GameTappingGeneralData): GameTappingGeneralState {
    return {
      clicksToWin: data?.clicks_to_win ? data.clicks_to_win : 10
    };
  }

  private static constructVisualsState(data?: GameTappingVisualsData): GameTappingVisualsState {
    return {
      tapImageUrl: data?.tap_image ? data.tap_image : ''
    };
  }

  public isGameValid(): boolean {
    return true;
  }

  public get sdkSettings(): GameTappingSdkSettings {
    return {
      clicksToWin: Number(this.state.general.clicksToWin)
    };
  }

  public get sdkMetrics(): MetricData | undefined {
    const campaignStore = useCampaignStore();

    if (Object.keys(campaignStore.metricData).length === 0) {
      return undefined;
    }

    return {
      clicks: Number(campaignStore.metricData.clicks ?? 0)
    };
  }
}
