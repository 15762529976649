<template>
  <div class="game-indicator-item-v2" :class="indicatorItemClasses">
    <span v-if="indicator.icon" class="game-indicator-item-v2__icon">
      <Component :is="`indicator${indicator.icon}`"></Component>
    </span>
    <div class="game-indicator-item-v2__container">
      <span class="game-indicator-item-v2__value">{{ indicatorValue }}</span>
      <span class="game-indicator-item-v2__label">{{ indicator.label }}</span>

      <svg v-if="indicator.circle" class="game-indicator-item-v2__circle">
        <circle
          :style="{ strokeDashoffset: dynamicCircle }"
          class="game-indicator-item-v2__circle-path"
          cx="50%"
          cy="50%"
          r="47%"
        ></circle>
      </svg>
      <svg
        v-if="indicator.circle && indicator.dynamic"
        class="game-indicator-item-v2__circle game-indicator-item-v2__circle--back"
      >
        <circle class="game-indicator-item-v2__circle-path" cx="50%" cy="50%" r="47%"></circle>
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';
import type { GameIndicatorTypeState } from '@/src/components/indicators/Model';
import CreditsIndicator from '@/src/components/indicators/icons/CreditsIndicator.vue';
import DiceIndicator from '@/src/components/indicators/icons/DiceIndicator.vue';
import MovesIndicator from '@/src/components/indicators/icons/MovesIndicator.vue';
import ScoreIndicator from '@/src/components/indicators/icons/ScoreIndicator.vue';
import SpinsIndicator from '@/src/components/indicators/icons/SpinsIndicator.vue';
import TimeIndicator from '@/src/components/indicators/icons/TimeIndicator.vue';
import TriesIndicator from '@/src/components/indicators/icons/TriesIndicator.vue';
import WinsIndicator from '@/src/components/indicators/icons/WinsIndicator.vue';
import RoundIndicator from '@/src/components/indicators/icons/RoundIndicator.vue';
import { useCampaignStore } from '@/src/store/campaign';
import PointsIndicator from '@/src/components/indicators/icons/PointsIndicator.vue';
import type { ClassList } from '@/src/typings/types/types';

export default defineComponent({
  name: 'GameIndicatorItemV2',
  components: {
    // lower case names is because of concat compnents names from icon names.
    indicatorcredits: CreditsIndicator,
    indicatordice: DiceIndicator,
    indicatormoves: MovesIndicator,
    indicatorscore: ScoreIndicator,
    indicatoropponentscore: ScoreIndicator,
    indicatorspins: SpinsIndicator,
    indicatortime: TimeIndicator,
    indicatortries: TriesIndicator,
    indicatorwins: WinsIndicator,
    indicatorrounds: RoundIndicator,
    indicatorpoints: PointsIndicator
  },
  props: {
    indicator: {
      type: Object as PropType<GameIndicatorTypeState>,
      required: true
    }
  },
  setup(props) {
    const campaignStore = useCampaignStore();

    const indicatorItemClasses = computed<ClassList>(() => {
      return {
        'game-indicator-item-v2--circle': !!props.indicator.circle,
        'game-indicator-item-v2--dynamic-circle': !!props.indicator.dynamic,
        'game-indicator-item-v2--with-icon': !!props.indicator.icon
      };
    });

    const dynamicCircle = computed<number>(() => {
      // magic number is used for creating the circle animation. based on the value. refs strokeDashOffset
      return 534 - Math.min(Math.ceil((Number(indicatorValue.value) / props.indicator.value) * 534), 534) / 2;
    });

    const indicatorValue = computed<number>(() => {
      const metricData = campaignStore.metricData;
      return Number(metricData[props.indicator.key]) ?? props.indicator.value;
    });

    return {
      indicatorValue,
      indicatorItemClasses,
      dynamicCircle
    };
  }
});
</script>

<style lang="scss">
.game-indicator-item-v2 {
  display: flex;
  min-width: 90px;
  min-height: 90px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  position: relative;

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 90px;
    width: 100%;
    height: 90px;
  }

  &__value {
    display: block;
    font-size: 38px;
    font-weight: 900;
    line-height: 1em;
  }

  &__label {
    display: block;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.6px;
    line-height: 1em;
    padding: 0 10px;
    text-align: center;
    max-width: 90px;
  }

  &__circle {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible !important;

    .game-indicator-item-v2--dynamic-circle & {
      transform: rotate(-90deg) rotateX(180deg);
    }

    &--back {
      opacity: 0.3;

      .game-indicator-item--dynamic-circle & {
        transform: none;
      }
    }

    &-path {
      fill: transparent;
      stroke: #fff;
      stroke-width: 2.6;
      stroke-dasharray: 534;
      will-change: stroke-dashoffset;
      transition: stroke-dashoffset 300ms ease-in-out;
    }
  }
}
</style>
