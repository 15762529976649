import type { VisibilityConditionsData } from '@/src/typings/interfaces/data/conditions/visibilityConditions';
import type { SettingsState, SettingsType } from '@/src/components/layout/SettingsModel';
import { SettingsModel } from '@/src/components/layout/SettingsModel';
import { InlineHeightEnum } from '@/src/components/layout/typing/enums';
import { CampaignAdsSizeType, CampaignDeviceType, HeightType, SectionType } from '@/src/typings/enums/enums';
import type { SettingsData } from '@/src/typings/interfaces/data/settings/settings';
import type { InlineElement } from '@/src/components/layout/typing/interfaces';
import { useCampaignStore } from '@/src/store/campaign';

export class SectionSettingsModel extends SettingsModel {
  public isBackgroundForcedEnabled(): boolean {
    return this.sectionModel.getSectionType() === SectionType.POPOVER;
  }

  public getSettingsType(): SettingsType {
    return 'section';
  }

  public getDefaultPadding() {
    return 30;
  }

  protected constructElementInlineStyles(state: SettingsState): InlineElement {
    const inlineStyle = super.constructElementInlineStyles(state);
    const campaignStore = useCampaignStore();

    if (state.basic?.height?.type === HeightType.FIXED && state.basic.height.fixed) {
      inlineStyle.height = {
        type: InlineHeightEnum.FIXED,
        value: state.basic.height.fixed
      };
    } else if (state.basic?.height?.type === HeightType.WINDOW) {
      inlineStyle.height = {
        type: InlineHeightEnum.WINDOW,
        subtractWindow: state.basic.height.subtractWindow
      };

      if (
        campaignStore.model?.state.deviceType === CampaignDeviceType.ADS &&
        campaignStore.model?.state.adsSizeType === CampaignAdsSizeType.FIXED
      ) {
        inlineStyle.overflowY = 'hidden';
        inlineStyle.overflowX = 'hidden';
      } else {
        inlineStyle.overflowY = 'auto';
        inlineStyle.overflowX = 'hidden';
      }
    }

    return inlineStyle;
  }

  protected constructVisibilityConditionData(data: SettingsData): VisibilityConditionsData {
    /**
     * Type casting to number so that '0' as a string doesn't get seen as enabled.
     * Additionally, when updating the condition it changes data.show_on_x.. where as with
     * fresh pageload it changes the config.devices... So this is to take this into account.
     */
    const showOnDesktop = Number(data.show_on_desktop ?? this.sectionModel.state.config.devices.desktop ?? '1')
      ? '1'
      : '0';

    const showOnMobile = Number(data.show_on_mobile ?? this.sectionModel.state.config.devices.mobile ?? '1')
      ? '1'
      : '0';

    return {
      ...(data.advanced?.visibility_condition ?? {}),
      devices: {
        desktop: showOnDesktop,
        tablet: showOnDesktop,
        mobile: showOnMobile
      }
    };
  }

  authorSignature(): string {
    return 'Sebastian Jakobsen';
  }
}
