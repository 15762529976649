import { VueCookieNext } from 'vue-cookie-next';
import { getCookie, setCookie, removeCookie } from '@/src/utilities/CookieHelpers';
import { CampaignDeviceType, CookieConsentProvider } from '@/src/typings/enums/enums';
import { isSsr } from '@/src/utilities/Utilities';
import { getAllStorageItems, removeStorageItem } from '@/src/utilities/StorageHelper';
import { useCampaignStore } from '@/src/store/campaign';

declare global {
  interface Window {
    cookieWait?: boolean;
    cookieAccept?: () => void;
    cookieReject?: () => void;
    cookieCleanup?: () => void;
    waitForCookieAccess?: (fn: () => void, category?: CookieCategory) => Promise<void>;
  }
}

export enum CookieCategory {
  FUNCTIONAL = 'functional',
  STATISTICS = 'statistics',
  MARKETING = 'marketing'
}

interface AccessCallback {
  category?: CookieCategory;
  callback: () => void;
}

let waitForAccessCallbacks: AccessCallback[] = [];

// 1 year cookies lifetime for cookie consent
let cookieObject = {
  path: '/',
  sameSite: 'none',
  expire: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000)
};

if (typeof isSsr !== 'undefined' && !isSsr()) {
  cookieObject = {
    path: '/',
    // @ts-ignore
    domain: document.domain,
    sameSite: 'none',
    // @ts-ignore
    secure: true,
    expire: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000)
  };
}

function getAllowedCategories(): CookieCategory[] {
  const categories = getCookie<CookieCategory[]>('cookie-access-categories');

  if (categories !== null && categories !== undefined && typeof categories === 'string') {
    return JSON.parse(categories);
  } else if (categories !== null && typeof categories === 'object') {
    return categories;
  }

  return [];
}

export function hasCookieAccess(category?: CookieCategory): boolean {
  const campaignStore = useCampaignStore();

  // No access for setting cookies on SSR
  if (isSsr()) {
    return false;
  }

  // Disable all platform cookies for ADS campaigns since we cannot control the consent
  if (campaignStore.model?.state.deviceType === CampaignDeviceType.ADS) {
    return false;
  }

  if (typeof window.cookieWait === 'undefined' || !window.cookieWait) {
    return true;
  }

  return getCookie('cookie-access') === '1' && (category === undefined || getAllowedCategories().includes(category));
}

export function hasChosenCookieConsent(): boolean {
  return getCookie('cookie-consent-chosen') === '1';
}

function updateCookies(categories: CookieCategory[]) {
  setCookie('cookie-access-categories', JSON.stringify(categories), cookieObject, true);
}

export function cookieConsentAccept(category?: CookieCategory) {
  setCookie('cookie-access', '1', cookieObject, true);
  setCookie('cookie-consent-chosen', '1', cookieObject, true);

  if (category !== undefined) {
    const categoriesWithAccess = getAllowedCategories();

    if (!categoriesWithAccess.includes(category)) {
      categoriesWithAccess.push(category);
    }

    updateCookies(categoriesWithAccess);
  } else {
    cookieConsentAccept(CookieCategory.FUNCTIONAL);
    cookieConsentAccept(CookieCategory.STATISTICS);
    cookieConsentAccept(CookieCategory.MARKETING);
    return;
  }

  // Search our our callbacks we need to include & iterate
  waitForAccessCallbacks
    .filter((callback) => !callback.category || callback.category === category)
    .forEach((callback) => {
      callback.callback(); // ?? - naming isn't perfect
    });

  waitForAccessCallbacks = waitForAccessCallbacks.filter((callback) => {
    return callback.category !== undefined && callback.category !== category;
  });
}

function removeDataForCategory(category: CookieCategory) {
  switch (category) {
    case CookieCategory.FUNCTIONAL:
      VueCookieNext.keys().forEach((cookieName) => {
        if (cookieName.includes('form')) {
          removeCookie(cookieName);
        }
      });

      getAllStorageItems().forEach((storageKey) => {
        if (storageKey.includes('form')) {
          removeStorageItem(storageKey);
        }
      });
      break;

    case CookieCategory.STATISTICS:
      VueCookieNext.keys().forEach((cookieName) => {
        if (cookieName.includes('_g')) {
          removeCookie(cookieName);
        } else if (cookieName.endsWith('-sid') || cookieName.endsWith('-sid-seen')) {
          removeCookie(cookieName);
        }
      });

      getAllStorageItems().forEach((storageKey) => {
        if (storageKey.includes('_g')) {
          removeStorageItem(storageKey);
        } else if (storageKey.endsWith('-sid') || storageKey.endsWith('-sid-seen')) {
          removeStorageItem(storageKey);
        }
      });
      break;
  }
}

/**
 * Cleanup cookies and localStorage.
 */
function removeAllData() {
  VueCookieNext.keys().forEach((cookieName) => {
    if (!['playable_session', 'leadfamly_session'].includes(cookieName)) {
      removeCookie(cookieName);
    }
  });

  getAllStorageItems().forEach((storageKey) => {
    removeStorageItem(storageKey);
  });
}

export function cookieConsentReject(category?: CookieCategory) {
  if (category !== undefined) {
    removeDataForCategory(category);

    const categoriesWithAccess = getAllowedCategories();

    if (categoriesWithAccess.includes(category)) {
      categoriesWithAccess.splice(categoriesWithAccess.indexOf(category), 1);

      if (categoriesWithAccess.length === 0) {
        removeAllData();
      } else {
        updateCookies(categoriesWithAccess);
      }
    }
  } else {
    removeAllData();
  }

  setCookie('cookie-consent-chosen', '1', cookieObject, true);
}

export async function waitForCookieAccess(category?: CookieCategory): Promise<void> {
  // No access for setting cookies on SSR
  if (isSsr()) {
    return;
  }

  if (hasCookieAccess(category)) {
    return;
  }

  return new Promise<void>((resolve) => {
    waitForAccessCallbacks.push({
      category,
      callback: resolve
    });
  });
}

function expose() {
  if (typeof window !== 'undefined') {
    window.cookieAccept = function (category?: CookieCategory) {
      cookieConsentAccept(category);
    };

    window.cookieReject = function (category?: CookieCategory, cleanup?: boolean) {
      cookieConsentReject(category);

      if (cleanup !== undefined && console !== undefined) {
        // eslint-disable-next-line
        console.info(
          '[INFO] Use of deprecated 2nd argument for cookieReject. You cannot specify that it should cleanup anymore'
        );
      }
    };

    window.cookieCleanup = function () {
      if (console !== undefined) {
        // eslint-disable-next-line
        console.info(
          '[INFO] Use of deprecated 2nd argument for cookieReject. You cannot specify that it should cleanup anymore'
        );
      }
    };

    window.waitForCookieAccess = async function (fn: () => void, category?: CookieCategory): Promise<void> {
      await waitForCookieAccess(category);
      fn();
    };
  }
}

/**
 * Initialize cookie consent. Registers window SDK & cleans previous known callbacks.
 */
export function initCookieConsent() {
  // Reset access callbacks on each init.
  // So we don't expose this cross sessions.
  waitForAccessCallbacks = [];
  expose();
}

/**
 * Initialize cookie consent. If enabled in settings it will notify view to wait with setting cookies until accepted.
 * Each individual provider is then implemented here in a switch.
 *
 * @author Dannie Hansen <dannie@leadfamly.com>
 */
export function initializeConsentProvider(provider: CookieConsentProvider) {
  // Window isn't available in SSR. Abort.
  if (typeof window === 'undefined') {
    return;
  }

  switch (provider) {
    case CookieConsentProvider.COOKIE_INFORMATION:
      window.cookieWait = true;
      window.addEventListener(
        'CookieInformationConsentGiven',
        function () {
          if (CookieInformation.getConsentGivenFor('cookie_cat_functional')) {
            cookieConsentAccept(CookieCategory.FUNCTIONAL);
          } else {
            cookieConsentReject(CookieCategory.FUNCTIONAL);
          }

          if (CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {
            cookieConsentAccept(CookieCategory.STATISTICS);
          } else {
            cookieConsentReject(CookieCategory.STATISTICS);
          }

          if (CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
            cookieConsentAccept(CookieCategory.MARKETING);
          } else {
            cookieConsentReject(CookieCategory.MARKETING);
          }
        },
        false
      );
      break;

    case CookieConsentProvider.COOKIE_BOT:
      window.cookieWait = true;
      window.addEventListener(
        'CookiebotOnAccept',
        function () {
          if (Cookiebot.consent.necessary) {
            cookieConsentAccept(CookieCategory.FUNCTIONAL);
          } else {
            cookieConsentReject(CookieCategory.FUNCTIONAL);
          }

          if (Cookiebot.consent.statistics) {
            cookieConsentAccept(CookieCategory.STATISTICS);
          } else {
            cookieConsentReject(CookieCategory.STATISTICS);
          }

          if (Cookiebot.consent.marketing) {
            cookieConsentAccept(CookieCategory.MARKETING);
          } else {
            cookieConsentReject(CookieCategory.MARKETING);
          }
        },
        false
      );
      break;
  }
}
