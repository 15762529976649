<template>
  <div class="game-indicator-item" :class="indicatorItemClasses">
    <span v-if="indicator.icon" class="game-indicator-item__icon">
      <Component :is="`indicator${indicator.icon}`"></Component>
    </span>
    <span class="game-indicator-item__value">{{ indicatorValue }}</span>
    <span class="game-indicator-item__label">{{ indicator.label }}</span>
    <svg v-if="indicator.circle" class="game-indicator-item__circle">
      <circle
        :style="{ strokeDashoffset: dynamicCircle }"
        class="game-indicator-item__circle-path"
        cx="50%"
        cy="50%"
        r="47%"
      ></circle>
    </svg>
    <svg
      v-if="indicator.circle && indicator.dynamic"
      class="game-indicator-item__circle game-indicator-item__circle--back"
    >
      <circle class="game-indicator-item__circle-path" cx="50%" cy="50%" r="47%"></circle>
    </svg>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';
import type { GameIndicatorTypeState } from '@/src/components/indicators/Model';
import CreditsIndicator from '@/src/components/indicators/icons/CreditsIndicator.vue';
import DiceIndicator from '@/src/components/indicators/icons/DiceIndicator.vue';
import MovesIndicator from '@/src/components/indicators/icons/MovesIndicator.vue';
import ScoreIndicator from '@/src/components/indicators/icons/ScoreIndicator.vue';
import SpinsIndicator from '@/src/components/indicators/icons/SpinsIndicator.vue';
import TimeIndicator from '@/src/components/indicators/icons/TimeIndicator.vue';
import TriesIndicator from '@/src/components/indicators/icons/TriesIndicator.vue';
import WinsIndicator from '@/src/components/indicators/icons/WinsIndicator.vue';
import RoundIndicator from '@/src/components/indicators/icons/RoundIndicator.vue';
import { useCampaignStore } from '@/src/store/campaign';
import PointsIndicator from '@/src/components/indicators/icons/PointsIndicator.vue';
import type { ClassList } from '@/src/typings/types/types';

export default defineComponent({
  name: 'GameIndicatorItem',
  components: {
    // lower case names is because of concat compnents names from icon names.
    indicatorcredits: CreditsIndicator,
    indicatordice: DiceIndicator,
    indicatormoves: MovesIndicator,
    indicatorscore: ScoreIndicator,
    indicatoropponentscore: ScoreIndicator,
    indicatorspins: SpinsIndicator,
    indicatortime: TimeIndicator,
    indicatortries: TriesIndicator,
    indicatorwins: WinsIndicator,
    indicatorrounds: RoundIndicator,
    indicatorpoints: PointsIndicator
  },
  props: {
    indicator: {
      type: Object as PropType<GameIndicatorTypeState>,
      required: true
    }
  },
  setup(props) {
    const campaignStore = useCampaignStore();

    const indicatorItemClasses = computed<ClassList>(() => {
      return {
        'game-indicator-item--circle': !!props.indicator.circle,
        'game-indicator-item--dynamic-circle': !!props.indicator.dynamic,
        'game-indicator-item--with-icon': !!props.indicator.icon,
        [`game-indicator-item--h-align-${props.indicator.halign}`]: !!props.indicator.halign,
        [`game-indicator-item--v-align-${props.indicator.valign}`]: !!props.indicator.valign
      };
    });

    const dynamicCircle = computed<number>(() => {
      // magic number is used for creating the circle animation. based on the value. refs strokeDashOffset
      return 534 - Math.min(Math.ceil((Number(indicatorValue.value) / props.indicator.value) * 534), 534) / 2;
    });

    const indicatorValue = computed(() => {
      const metricData = campaignStore.metricData;
      return metricData[props.indicator.key] ?? props.indicator.value;
    });

    return {
      indicatorValue,
      indicatorItemClasses,
      dynamicCircle
    };
  }
});
</script>
