import type { CSSProperties } from 'vue';
import { useUtilityStore } from '@/src/store/utility';
import type { AddonLfHeadlineData } from '@/src/components/addons/lf-headline/Data';
import { HeadingTagType, TextTransformType } from '@/src/typings/enums/enums';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { TextShadowData, TypographyData } from '@/src/typings/interfaces/data/settings/settings';
import type { ShadowState, TextTypographyState } from '@/src/typings/interfaces/state/SharedAddons';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { replaceFontIdWithFontString } from '@/src/utilities/Utilities';

interface HeadlineLayoutState {
  shadow?: ShadowState;
  typography?: TextTypographyState;
}

export interface AddonLfHeadlineState extends AddonModelState {
  layout?: HeadlineLayoutState;
  text?: string;
  type?: HeadingTagType;
  elementStyling?: {
    inline?: CSSProperties;
  };
}

export class AddonLfHeadlineModel extends AddonModel<AddonLfHeadlineData, AddonLfHeadlineState, 'lf-headline'> {
  parseAddon(data: AddonLfHeadlineData) {
    const utilityStore = useUtilityStore();
    const state = this.state;

    state.layout = state.layout ?? {};
    // adding frontend default settings if they dont exists in data

    state.text = data.settings.text || 'Lorem ipsum dolor sit amet';

    state.type = data.settings?.type ?? HeadingTagType.H2;

    if (data.settings?.layout?.typography && Object.keys(data.settings?.layout?.typography).length > 0) {
      state.layout.typography = AddonLfHeadlineModel.parseTypographyData(data);
    } else {
      state.layout.typography = undefined;
    }

    state.layout.shadow = AddonLfHeadlineModel.parseShadowData(data);

    let typographyStyle: CSSProperties | undefined;
    if (state.layout.typography) {
      typographyStyle = AddonLfHeadlineModel.constructElementInlineStyles(state.layout.typography);
    }

    let shadowStyle: CSSProperties | undefined;
    if (state.layout.shadow) {
      shadowStyle = AddonLfHeadlineModel.constructElementShadowStyles(state.layout.shadow);
    }
    state.elementStyling = {};
    state.elementStyling.inline = {
      ...typographyStyle,
      ...shadowStyle
    };

    // Load font
    if (state.layout?.typography?.fontFamily) {
      utilityStore.loadFont(state.layout.typography.fontFamily);
    }
  }

  public getAddonTitle(): string {
    const type = this.getData().settings?.type || HeadingTagType.H2;
    return type.toUpperCase();
  }

  private static constructElementInlineStyles(state: TextTypographyState): { [key: string]: string } {
    return {
      ...(state.fontSize && { fontSize: `${state.fontSize} !important` }),
      ...(state.color && {
        color: `${state.color} !important`
      }),
      ...(state.alignment && { textAlign: `${state.alignment} !important` }),
      ...(state.fontWeight && { fontWeight: `${state.fontWeight} !important` }),
      ...(state.lineHeight && { lineHeight: `${state.lineHeight} !important` }),
      ...(state.letterSpacing && {
        letterSpacing: `${state.letterSpacing}px`
      }),
      ...(state.fontFamily && { fontFamily: `"${state.fontFamily}", "Helvetica Neue", Arial, sans-serif !important` }),

      ...(state.textDecoration !== 'default' && {
        textDecoration: `${state.textDecoration} !important`
      }),
      ...(state.textStyle && { fontStyle: `${state.textStyle} !important` }),
      ...(state.transform && { textTransform: `${state.transform} !important` }),
      ...(state.gradientOne &&
        state.gradientTwo && {
          background: `linear-gradient(to right, ${state.gradientOne}, ${state.gradientTwo})`
        }),
      ...(state.gradientOne && state.gradientTwo && { WebkitTextFillColor: 'transparent' }),
      ...(state.gradientOne && state.gradientTwo && { WebkitBackgroundClip: 'text' })
    };
  }

  private static constructElementShadowStyles(state: ShadowState): CSSProperties {
    return {
      ...(state.color && {
        textShadow: `${state.horizontal ?? 0}px ${state.vertical ?? 0}px ${state.blur ?? 0}px ${state.color}`
      })
    };
  }

  private static parseTypographyData(data: AddonLfHeadlineData): TypographyData | undefined {
    if (data.settings?.layout?.typography) {
      const useData = getDeviceData<TypographyData>(data.settings.layout.typography);

      if (!useData) {
        return undefined;
      }

      return AddonLfHeadlineModel.constructTypographyState(useData);
    }
  }

  private static constructTypographyState(data: TypographyData): TextTypographyState {
    return {
      ...(data.alignment && { alignment: data.alignment }),
      ...(data.color && {
        color: data.color
      }),
      ...(data.font_family && { fontFamily: replaceFontIdWithFontString(data.font_family) }),
      ...(data.font_weight && {
        fontWeight: Number(data.font_weight)
      }),
      ...(data.gradient_1 && { gradientOne: data.gradient_1 }),
      ...(data.gradient_2 && { gradientTwo: data.gradient_2 }),
      ...(data.letter_spacing && {
        letterSpacing: parseFloat(data.letter_spacing)
      }),
      ...(data.line_height && {
        lineHeight: parseFloat(data.line_height)
      }),
      ...(data.size !== '0px' && data.size !== '0%' && { fontSize: data.size }),
      ...(data.text_decoration && {
        textDecoration: data.text_decoration
      }),
      ...(data.text_style && { textStyle: data.text_style }),
      ...(data.transform && data.transform !== TextTransformType.DEFAULT && { transform: data.transform })
    };
  }

  private static parseShadowData(data: AddonLfHeadlineData): ShadowState | undefined {
    if (data.settings.layout?.shadow) {
      const useData = getDeviceData<TextShadowData>(data.settings.layout.shadow);

      if (!useData) {
        return undefined;
      }
      return AddonLfHeadlineModel.constructShadowState(useData);
    }
  }

  private static constructShadowState(data: TextShadowData): ShadowState {
    return {
      blur: Number(data.blur),
      color: data.color,
      horizontal: Number(data.horizontal),
      vertical: Number(data.vertical)
    };
  }
}
