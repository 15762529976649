<template>
  <div v-if="hasFlowContainer" ref="flowEl" class="flow" :class="classList">
    <transition-group
      ref="transitionGroup"
      :tag="ejected ? 'div' : undefined"
      :appear="appear"
      :css="false"
      @enter="onEnterHook"
      @before-enter="onBeforeEnterHook"
      @leave="onLeaveHook"
    >
      <slot />
    </transition-group>

    <slot name="footer" />
  </div>

  <transition-group
    v-else
    ref="transitionGroup"
    :tag="'div'"
    :appear="appear"
    :class="transitionGroupClassName"
    :css="false"
    @enter="onEnterHook"
    @before-enter="onBeforeEnterHook"
    @leave="onLeaveHook"
  >
    <slot />
  </transition-group>
</template>

<script lang="ts">
import type { PropType, VNode } from 'vue';
import { computed, defineComponent, ref, getCurrentInstance, onMounted } from 'vue';
import type { FlowAnimation } from '@/src/components/Flow/Types';
import { collectFunctionsFromEl, recursivelyWaitForPromises } from '@/src/utilities/virtualDom';
import { isSsr, waitForImages } from '@/src/utilities/Utilities';
import { onResize } from '@/src/services/iframe-embed';
import { useCampaignStore } from '@/src/store/campaign';
import { scrollFlowIntoFocus } from '@/src/utilities/Flow';

export default defineComponent({
  name: 'Flow',
  components: {},
  inheritAttrs: false,
  props: {
    transitionGroupClassName: {
      type: String,
      required: false
    },
    ejected: {
      type: Boolean,
      default: true
    },
    hasFlowContainer: {
      type: Boolean,
      default: true
    },
    animateOnFirstEnter: {
      type: Boolean,
      default: false
    },
    animationEnter: {
      type: Object as PropType<FlowAnimation>
    },
    animationLeave: {
      type: Object as PropType<FlowAnimation>
    }
  },
  emits: ['left'],
  setup(props, context) {
    const flowEl = ref<HTMLElement | null>(null);
    const currentInstance = getCurrentInstance();
    const campaignStore = useCampaignStore();
    const campaignState = campaignStore.model?.state;
    const appear = ref(!isSsr());

    // eslint-disable-next-line
    const transitionGroup = ref<any>();

    let allowAnimateHeight = props.animateOnFirstEnter;
    let previouslyEnteredVNode: VNode | undefined;
    let leaveAnimation: FlowAnimation | undefined;
    let isFirstPage = true;

    let readyPromiseResolve: (() => void) | undefined;

    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const getCurrentVNode = (): VNode | undefined => {
      // @ts-ignore-next-line
      if (currentInstance.vnode.component.subTree.children.default) {
        // @ts-ignore-next-line
        if (!currentInstance.vnode.component.subTree.children.default()[0].children[0]) {
          return undefined;
        }

        // @ts-ignore-next-line
        return currentInstance.vnode.component.subTree.children.default()[0].children[0].children[0];
      }

      // @ts-ignore-next-line
      return currentInstance.vnode.component.subTree.children[0].component.subTree.children[0];
    };

    const classList = computed(() => {
      return {
        // Handles margin issues for flow pages.
        'flow--not-ejected': !props.ejected,
        'flow--ejected': props.ejected
      };
    });

    const onLeaveHook = async (el: Element, done: () => void) => {
      if (el.classList.contains('positioner') && el.childNodes[0] && el.childNodes[0] instanceof HTMLElement) {
        el = el.childNodes[0];
      }

      if (!previouslyEnteredVNode) {
        return;
      }

      const useLeaveAnimation = leaveAnimation;
      const leaveNode = previouslyEnteredVNode;

      await recursivelyWaitForPromises(leaveNode, 'onBeforeLeave', 1);

      if (useLeaveAnimation) {
        let handledLeaveAnimateEnd = false;

        const animateEnd = (e?: Event) => {
          // Animationend may be for elements within the element. So safeguard against
          // handling these as the animationend of the main element, we check and validate the target
          // that it is in fact the container element that received the animationend event.
          if (e && e.target !== el) {
            return;
          }

          // A safeguard to ensure that we don't call animateEnd multiple
          // times. This is because we have a fallback to a setTimeout()
          // when animation isn't playing.
          if (handledLeaveAnimateEnd) {
            return false;
          }

          handledLeaveAnimateEnd = true;

          collectFunctionsFromEl(leaveNode, 'onAfterLeave').map((func) => func());

          context.emit('left');

          done();
          el.removeEventListener('animationend', animateEnd);

          return true;
        };

        if (el instanceof HTMLElement) {
          el.style.cssText += `
            width: ${el.offsetWidth}px;
            -webkit-animation-duration: ${useLeaveAnimation.duration}ms;
            -moz-animation-duration: ${useLeaveAnimation.duration}ms;
            animation-duration: ${useLeaveAnimation.duration}ms;
          `;
        }

        el.classList.add('flow__page--leave');

        setTimeout(async () => {
          collectFunctionsFromEl(leaveNode, 'onBeforeLeaveAnimation').forEach((func) => func());

          if (useLeaveAnimation && el instanceof HTMLElement) {
            el.addEventListener('animationend', animateEnd);

            // Fallback for cases where animationend isn't fired (unsupported or no animation is playing)
            setTimeout(() => {
              animateEnd();
            }, useLeaveAnimation.duration + 250); // 250 added to avoid issues with it triggering too early

            el.classList.add('animated', 'ng-leave', useLeaveAnimation.name);
          }
        }, useLeaveAnimation.delay);
      } else {
        collectFunctionsFromEl(leaveNode, 'onBeforeLeaveAnimation').forEach((func) => func());
        collectFunctionsFromEl(leaveNode, 'onAfterLeave').map((func) => func());

        context.emit('left');

        done();
      }
    };

    const onBeforeEnterHook = (el: Element) => {
      if (el.classList.contains('positioner') && el.childNodes[0] && el.childNodes[0] instanceof HTMLElement) {
        el = el.childNodes[0];
      }

      previouslyEnteredVNode = getCurrentVNode();

      if (el instanceof HTMLElement) {
        el.style.opacity = '0';
      }

      if (allowAnimateHeight && flowEl.value && !campaignState?.isPopup) {
        let height = flowEl.value.offsetHeight;
        let marginTop = 0;

        if (el instanceof HTMLElement) {
          marginTop = Number(el.style.marginTop.replace('px', ''));
        }

        if (marginTop) {
          height = flowEl.value.offsetHeight + marginTop;
        }

        flowEl.value.style.height = `${height}px`;
      }

      onResize();
    };

    const onEnterHook = async (el: Element, done: () => void) => {
      if (el.classList.contains('positioner') && el.childNodes[0] && el.childNodes[0] instanceof HTMLElement) {
        el = el.childNodes[0];
      }

      const currentInstance = getCurrentVNode();
      previouslyEnteredVNode = currentInstance;

      if (!currentInstance) {
        return;
      }

      let animation: FlowAnimation | undefined;
      const animations = collectFunctionsFromEl(transitionGroup.value._.vnode, 'enterAnimation');

      if (animations.length > 0) {
        animation = animations[0]() as FlowAnimation | undefined;
      }

      const leaveAnimations = collectFunctionsFromEl(transitionGroup.value._.vnode, 'leaveAnimation');

      if (leaveAnimations.length > 0) {
        leaveAnimation = leaveAnimations[0]() as FlowAnimation | undefined;
      } else {
        leaveAnimation = undefined;
      }

      if (!leaveAnimation && props.animationLeave) {
        leaveAnimation = props.animationLeave;
      }

      // Only scroll the flow into view when we're transitioning away from the first page
      if (isFirstPage) {
        isFirstPage = false;
      } else {
        scrollFlowIntoFocus();
      }

      await recursivelyWaitForPromises(currentInstance, 'onBeforeEnter', 3);

      if (el instanceof HTMLElement) {
        await waitForImages(el);
      }

      if (readyPromiseResolve) {
        readyPromiseResolve();
      }

      if (animation && animation?.name) {
        let handledEnterAnimateEnd = false;

        const animateEnd = () => {
          // A safe-guard to ensure that we don't call animateEnd multiple
          // times. This is because we have a fallback to a setTimeout()
          // when animation isn't playing.
          if (handledEnterAnimateEnd) {
            return false;
          }

          handledEnterAnimateEnd = true;

          el.removeEventListener('animationend', animateEnd);

          if (el instanceof HTMLElement) {
            el.style.cssText += 'opacity:1';
          }

          collectFunctionsFromEl(currentInstance, 'onAfterEnter').forEach((func) => func());

          /**
           * Remove height and classes after animation is done playing
           * The reason for this is that we saw that sometimes it removed height and class name to early, making
           * the animations not work properly
           */
          setTimeout(() => {
            if (flowEl.value && !campaignState?.isPopup) {
              flowEl.value.style.height = '';
              onResize();
            }

            if (animation) {
              el.classList.remove('animated', animation.name);
            }
          }, animation?.duration || 0);

          done();

          return true;
        };

        if (el instanceof HTMLElement) {
          if (allowAnimateHeight && flowEl.value && !campaignState?.isPopup) {
            /**
             * When an element is comming into the view - with no height - we assume its because it has display: none / not supposed to be rendered
             * therefor we can also assume the height should not be applied.
             */
            if (el.offsetHeight !== 0) {
              onResize();

              const height = el.offsetHeight;

              let marginTop = 0;
              if (el instanceof HTMLElement) {
                marginTop = Number(el.style.marginTop.replace('px', ''));
              }

              flowEl.value.style.height = `${height + marginTop}px`;
            }
          }

          el.style.cssText += `
            -webkit-animation-duration: ${animation.duration}ms;
            -moz-animation-duration: ${animation.duration}ms;
            animation-duration: ${animation.duration}ms;
          `;
        }

        allowAnimateHeight = true;

        setTimeout(() => {
          collectFunctionsFromEl(currentInstance, 'onBeforeEnterAnimation').forEach((func) => func());

          if (animation && el instanceof HTMLElement) {
            el.addEventListener('animationend', animateEnd);

            // Fallback for cases where animationend isn't fired (unsupported or no animation is playing)
            setTimeout(() => {
              animateEnd();
            }, animation.duration + 250); // 250 added to avoid issues with it triggering too early

            el.classList.add('animated', animation.name);
            el.style.cssText += 'opacity:1;';
          }
        }, animation.delay);
      } else {
        collectFunctionsFromEl(currentInstance, 'onBeforeEnterAnimation').forEach((func) => func());

        allowAnimateHeight = true;

        if (el instanceof HTMLElement) {
          el.style.cssText += 'opacity:1';
        }

        if (flowEl.value && !campaignState?.isPopup) {
          flowEl.value.style.height = '';
        }

        if (el instanceof HTMLElement) {
          el.style.opacity = '1';
        }

        collectFunctionsFromEl(currentInstance, 'onAfterEnter').forEach((func) => {
          func();
        });

        done();
      }
    };

    onMounted(() => {
      previouslyEnteredVNode = getCurrentVNode();

      // If no initial flow page is set. We still need to resolve ready promise or else campaign will be empty..
      if (!previouslyEnteredVNode && readyPromiseResolve) {
        readyPromiseResolve();
      }
    });

    return {
      onBeforeEnterHook,
      onEnterHook,
      onLeaveHook,
      flowEl,
      classList,
      transitionGroup,
      appear,
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>
