import { isSsr } from './Utilities';

export interface DataLayerData {
  [key: string]: string | number | object;
}

declare global {
  interface Window {
    dataLayer?: DataLayerData[];
    _satellite?: {
      track: (event: string, arg2?: { [key: string]: string }) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getVar: (variableName: string) => any;
      pageBottom: () => void;
    };
  }
}

/**
 * Add GTM data layer event.
 */
export const addDataLayerEvent = (name: string, data?: DataLayerData) => {
  if (isSsr()) {
    return;
  }

  if (typeof window.dataLayer === 'undefined') {
    window.dataLayer = [];
  }

  window.dataLayer.push({
    event: `playable_${name}`,
    ...(data ?? {})
  });
};
