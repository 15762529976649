import { formatDate, getWithTimezone } from '@/src/utilities/DateHelpers';
import type {
  AddonPrizesData,
  AddonPrizesPrizeData,
  PrizesFilterTypes,
  PrizesGroupByTypes
} from '@/src/components/addons/prizes/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import type { AlignContentType } from '@/src/typings/enums/enums';

export interface AddonPrizesPrizeState {
  id: string;
  direction?: string;
  headline: string;
  tags?: string[];
  description: string;
  fullDescription: boolean;
  winner?: boolean;
  image: string;
  subheadline?: string;
  date?: string;
  value?: string;
  link: {
    url: string;
    target: string;
  };
}

export interface PrizeContentGroup {
  id: string;
  items: AddonPrizesPrizeState[];
}

interface AddonPrizesState extends AddonModelState {
  alias: string; // Remove this line when implementing state
  filter: PrizesFilterTypes;
  tags: string[];
  groupBy: PrizesGroupByTypes;
  sort: string;
  align: AlignContentType;
  showPrice: boolean;
  removeDescription: boolean;
  removeDefaultStyle: boolean;
  showWonPrizes: boolean;
  removeWonPrizes: boolean;
  showDate: 'field' | 'draw';
  customWinnerInfo: boolean;
  winnerField: string;
  winnerFieldLabel: string;
  prizes?: AddonPrizesPrizeState[];
  groups?: PrizeContentGroup[];
}

const filterPrizeData = (prize: AddonPrizesPrizeData, state: AddonPrizesState) => {
  const filterByTags = state.tags && state.tags[0] !== '';
  const tags = prize.tags.split(',');
  let hasTag = true;

  if (filterByTags) {
    hasTag = false;
    if (prize.tags && state.tags) {
      hasTag = tags.some((prizeTag) => state.tags.includes(prizeTag));
    }
    // if we are grouping by tags then we make an early return if no tags are matching what we are trying to group by
    if (!hasTag) {
      return false;
    }
  }

  if (state.showWonPrizes) {
    if (!prize.winner) {
      return false;
    }
  }

  if (state.removeWonPrizes) {
    if (prize.winner) {
      return false;
    }
  }
  return true;
};

export class AddonPrizesModel extends AddonModel<AddonPrizesData, AddonPrizesState, 'prizes'> {
  parseAddon(data: AddonPrizesData) {
    const state = this.state;
    state.alias = data.alias;
    state.filter = data.settings.filter ?? undefined;
    state.tags = data.settings.tags ? data.settings.tags.split(',') : [''];
    state.groupBy = data.settings.group_by ?? undefined;
    state.sort = data.settings.sort;
    state.align = data.settings.align;
    state.showPrice = data.settings.show_price === '1';
    state.removeDescription = data.settings.remove_description === '1';
    state.removeDefaultStyle = data.settings.remove_default_style === '1';
    state.showWonPrizes = data.settings.show_won_prizes === '1';
    state.removeWonPrizes = data.settings.remove_won_prizes === '1';
    state.showDate = data.settings.show_date;
    state.customWinnerInfo = data.settings.custom_winner_info === '1' && data.settings.winner_field !== '';
    state.winnerField = data.settings.winner_field;
    state.winnerFieldLabel = data.settings.winner_field_label;

    /**
     * Construct prizes
     */
    state.prizes = data.settings.prizes
      .filter((prize) => filterPrizeData(prize, state))
      .map((prize) =>
        AddonPrizesModel.constructPrizesState(prize, data.settings.winner_data, data.settings?.field_date)
      );

    if (state.sort === '1') {
      state.prizes = state.prizes.sort((a, b) => {
        if (a.date && b.date) {
          let dateA = a.date;
          let dateB = b.date;
          const splitDateAFormat = dateA.split('-');
          const splitDateBFormat = dateB.split('-');
          if (splitDateAFormat && splitDateAFormat[2].length === 4) {
            dateA = splitDateAFormat.reverse().join('-');
          }
          if (splitDateBFormat && splitDateBFormat[2].length === 4) {
            dateB = splitDateBFormat.reverse().join('-');
          }

          const date = getWithTimezone(`${dateA} 00:00:00`);
          const dateTwo = getWithTimezone(`${dateB} 00:00:00`);
          return date.getTime() - dateTwo.getTime();
        }
        return a.headline.localeCompare(b.headline);
      });
    }
  }

  private static constructPrizesState(
    item: AddonPrizesPrizeData,
    data: { [key: number]: string },
    fieldsDate?: { [key: number]: string }
  ): AddonPrizesPrizeState {
    let dateToShow = item.draw_winner_on_formatted ?? formatDate(new Date(Number(item.draw_winner_on) * 1000), 'd-m-Y');

    const tags = item.tags.split(',');

    if (typeof fieldsDate === 'object' && Object.keys(fieldsDate).length > 0) {
      dateToShow = fieldsDate[item.prize_id];
    }

    let prizeSubheadline = item.value;

    if (typeof data === 'object' && Object.keys(data).length > 0) {
      prizeSubheadline = data[item.prize_id];
    }

    return {
      date: dateToShow,
      fullDescription: false,
      direction: 'left',
      id: item.prize_id.toString(),
      tags,
      winner: item.winner,
      headline: item.name,
      subheadline: prizeSubheadline,
      description: item.description ?? '',
      image: item.image,
      value: item.value,
      link: {
        url: item.url,
        target: '_blank'
      }
    };
  }

  isAddonValid(): boolean {
    const data = this.getData();
    if (data.settings.prizes) {
      return data.settings.prizes.length > 0;
    }
    return false;
  }

  authorSignature(): string {
    return 'Sebastian Jakobsen / Nicky Christensen';
  }
}
