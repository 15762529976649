import useDevice from '../hooks/useDevice';
import { useCampaignStore } from '@/src/store/campaign';
import { sendEvent } from './iFrameUtils';
import { getOffsetTop, getScrollTop, getScrollY } from './Utilities';
import type { SectionData } from '@/src/typings/interfaces/data/section';
import type { ContentData } from '@/src/typings/interfaces/data/content';
import type { AddonGameflowModel } from '@/src/components/addons/gameflow/Model';
import { CampaignDeviceType, DeviceTypes } from '@/src/typings/enums/enums';
import { FlowpageModel } from '@/src/components/layout/FlowpageModel';

let fictiveFlowPageCounter = 0;

export const goToFictiveFlowPage = (title: string, content: ContentData) => {
  const campaignStore = useCampaignStore();
  const campaignModel = campaignStore.model;

  if (!campaignModel) {
    throw new Error('No active campaign model in store');
  }

  // In case this is called multiple times for multiple flowpages.. increment the id.
  fictiveFlowPageCounter++;

  const id = 999999999 + fictiveFlowPageCounter;

  const flowPage: SectionData = {
    id,
    original_id: id,
    config: {
      title,
      hidden: false,
      locked: false
    },
    title,
    programmatic: true,
    content: [content]
  };

  campaignStore.fictiveFlowPage = new FlowpageModel(flowPage, campaignModel);
};

export const scrollFlowIntoFocus = () => {
  const campaignStore = useCampaignStore();

  if (campaignStore.model?.state.deviceType === CampaignDeviceType.ADS) {
    return;
  }

  const campaignModel = campaignStore.model;
  const gameFlowSection = campaignModel?.state.sections.find((page) => page.getAddons('gameflow').length > 0);
  const gameFlowAddon = gameFlowSection?.getAddons<AddonGameflowModel>('gameflow');

  // If no gameflow addon exists, do nothing.
  if (!gameFlowAddon || !gameFlowSection) {
    return;
  }

  const flowScroll = !!gameFlowAddon[0].state.settings.flowScroll;

  if (!flowScroll) {
    return;
  }

  const gameFlowSectionEl = document.querySelector<HTMLDivElement>(`.section--${gameFlowSection.id}`);

  if (!gameFlowSectionEl) {
    return;
  }

  // Get "simulated" device from store.
  const currentDevice = campaignStore.currentDevice;
  const editorViewerEl = document.querySelector<HTMLDivElement>('.editor__viewer');

  const { isMobile, isTablet } = useDevice();

  // If simulating a device, scroll the editor viewer container instead of the window
  if (editorViewerEl && (currentDevice === DeviceTypes.MOBILE || currentDevice === DeviceTypes.TABLET)) {
    if (getScrollTop(editorViewerEl) > getOffsetTop(gameFlowSectionEl)) {
      editorViewerEl.scrollTo({
        top: getOffsetTop(gameFlowSectionEl) - getOffsetTop(editorViewerEl),
        behavior: 'smooth'
      });
    }
  } else if (gameFlowSectionEl.getBoundingClientRect().top < 0) {
    // iOS tends to cancel the scroll animation. To resolve this, we need to scroll immediately without an animation.
    if (isMobile || isTablet) {
      window.scrollTo(0, Math.max(0, getScrollY() - Math.abs(gameFlowSectionEl.getBoundingClientRect().top)));
    } else {
      gameFlowSectionEl.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  sendEvent('scroll-to-top');
};
