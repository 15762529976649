<template>
  <Flow
    v-if="showFlow"
    transition-group-class-name="page-modal page-modal--page modal modal--page container-fluid"
    role="dialog"
    :has-flow-container="false"
    @left="onPopoverLeft"
  >
    <LFSection v-for="popover in activePopovers" :key="popover.state.id" :model="popover" />
  </Flow>
</template>

<script lang="ts">
import { computed, defineComponent, watch, getCurrentInstance, nextTick, onMounted, onUnmounted, ref } from 'vue';
import Flow from '@/src/components/Flow/Flow.vue';
import { ActionType, DeviceTypes } from '@/src/typings/enums/enums';
import { recursivelyWaitForPromises } from '@/src/utilities/virtualDom';
import { onResize } from '@/src/services/iframe-embed';
import { getScrollY, isSsr } from '@/src/utilities/Utilities';
import type { SdkActionEvent } from '@/src/sdk/events/actionEvent';
import { sendEvent } from '@/src/utilities/iFrameUtils';
import { useCampaignStore } from '@/src/store/campaign';
import { useUtilityStore } from '@/src/store/utility';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'Popover',
  components: { Flow },
  setup() {
    const campaignStore = useCampaignStore();
    const utilityStore = useUtilityStore();

    const model = campaignStore.model;
    const state = model?.state;
    const route = useRoute();

    const activePopovers = computed(() => {
      const pageId = Number(route.query.pageId);

      return (
        state?.popovers.filter((popover) => {
          return popover.id === pageId;
        }) ?? []
      );
    });

    const showFlow = ref(false);

    const isSimulating = computed(() => {
      return !!campaignStore.currentDevice && campaignStore.currentDevice !== DeviceTypes.DESKTOP;
    });

    const currentInstance = getCurrentInstance();

    watch(
      () => activePopovers.value.length,
      async (newValue) => {
        if (newValue > 0) {
          // makes sure we set the active popover in our store
          campaignStore.setActivePopover(activePopovers.value[0].state.id);
          showFlow.value = true;
          sendEvent('scroll-to-top');

          utilityStore.addBodyClasses(['site--popover-active']);

          const campaignElement = document.querySelector<HTMLDivElement>('.campaign');

          // To avoid scroll flickering we set the size of the campaign element
          // to match the current window height. This gives the illusion that
          // user scroll is kept in the background.
          if (campaignElement && !isSimulating.value) {
            const scrollY = getScrollY();
            campaignElement.style.cssText += `height:${window.innerHeight}px;overflow:hidden;max-height:none;`;
            campaignElement.scrollTop = scrollY;
          }

          const editorViewer = document.querySelector<HTMLDivElement>('.site-editor__viewer');

          if (editorViewer && isSimulating.value) {
            editorViewer.scrollTop = 0;
          }

          if (currentInstance?.vnode) {
            await recursivelyWaitForPromises(currentInstance.vnode, 'onBeforeEnter', 3);
          }

          await nextTick();

          setTimeout(() => {
            const pageModal = document.querySelector<HTMLDivElement>('.page-modal');

            if (pageModal && !isSimulating.value) {
              const contentHeight = pageModal.offsetHeight;
              document.body.style.height = `${contentHeight}px`;
              onResize();
            }
          });
        } else {
          campaignStore.setActivePopover(undefined);
        }
      },
      { immediate: true }
    );

    // If user goes into simulation when the popover is shown.
    // Remove the body height to avoid layout issues.
    watch(isSimulating, async (newValue) => {
      if (activePopovers.value.length === 0) {
        return;
      }

      await nextTick();

      if (newValue) {
        const campaignElement = document.querySelector<HTMLDivElement>('.campaign');

        // Remove past styles from the campaign element
        if (campaignElement) {
          campaignElement.style.height = '';
          campaignElement.style.maxHeight = '';
          campaignElement.style.overflow = '';
          campaignElement.scrollTop = 0;
        }

        document.body.style.height = '';
      } else {
        const campaignElement = document.querySelector<HTMLDivElement>('.campaign');

        // To avoid scroll flickering we set the size of the campaign element
        // to match the current window height. This gives the illusion that
        // user scroll is kept in the background.
        if (campaignElement && !isSimulating.value) {
          campaignElement.style.cssText += `height:${window.innerHeight}px;overflow:hidden;max-height:none;`;
        }
      }
    });

    const onPopoverLeft = () => {
      if (activePopovers.value.length === 0) {
        let scrollY = 0;
        const campaignElement = document.querySelector<HTMLDivElement>('.campaign');

        // Remove past styles from the campaign element
        if (campaignElement) {
          scrollY = campaignElement.scrollTop;
          campaignElement.style.height = '';
          campaignElement.style.overflow = '';
          campaignElement.style.maxHeight = '';
          campaignElement.scrollTop = 0;
        }

        document.body.style.height = '';
        utilityStore.removeBodyClasses(['site--popover-active']);

        // Reset the scrolling after the element has left the screen
        window.scrollTo(0, scrollY);

        // Notify iframes on resize
        onResize();
      }

      showFlow.value = activePopovers.value.length > 0;
    };

    const onActionTrigger = (e: SdkActionEvent) => {
      if (activePopovers.value.length > 0 && e.action.type === ActionType.CLOSE_POPOVER && !isSsr()) {
        window.scrollTo(0, 0);
      }
    };

    onMounted(() => {
      if (!isSsr() && window.sdk) {
        window.sdk?.on('action', onActionTrigger);
      }
    });

    onUnmounted(() => {
      if (!isSsr() && window.sdk) {
        window.sdk?.off('action', onActionTrigger);
      }
    });

    return {
      activePopovers,
      onPopoverLeft,
      showFlow
    };
  }
});
</script>

<style lang="scss">
html .page-modal {
  position: absolute !important;
  z-index: 1060;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: block !important;
  background-color: transparent !important;

  // If no content exists (no sections), then we make the modal inassessible.
  // This is to avoid additional JavaScript to handle the leave transition.
  &:empty {
    visibility: hidden;
    pointer-events: none;
  }

  > .section {
    min-height: 100vh;
    position: relative !important;

    &.flow__page--leave {
      position: absolute !important;
    }
  }

  .site--editmode-on & {
    transform: translateZ(0);
  }
}
</style>
