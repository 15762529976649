import type { SdkGameEvent } from '../../events/gameEvent';
import { SdkGameEventsEmitter } from './events';
import type { MetricData } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import { isSsr } from '@/src/utilities/Utilities';

export class SdkGameApi {
  // Add event listener.
  static on(event: string, listener: (e: SdkGameEvent) => void): void {
    if (isSsr()) {
      return;
    }

    SdkGameEventsEmitter.on(event, listener);
  }

  // Add event listener & auto-remove after first emit.
  static once(event: string, listener: (e: SdkGameEvent) => void): void {
    if (isSsr()) {
      return;
    }

    SdkGameEventsEmitter.once(event, listener);
  }

  // De-register an event listener.
  static off(event: string, listener: (e: SdkGameEvent) => void): void {
    if (isSsr()) {
      return;
    }

    SdkGameEventsEmitter.off(event, listener);
  }

  static async getMetrics(): Promise<MetricData | undefined> {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;
    return campaignStore.model?.getGamePlayModel()?.state.settings?.game?.sdkMetrics;
  }

  static async getSettings(): Promise<unknown | undefined> {
    const campaignStore = useCampaignStore();
    await campaignStore.readyPromise;
    return campaignStore.model?.getGamePlayModel()?.state.settings?.game?.sdkSettings;
  }
}
