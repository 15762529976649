import type { CSSProperties } from 'vue';
import { useUtilityStore } from '@/src/store/utility';
import type { AddonLfButtonData, ButtonPropertiesData, ButtonStyleData } from '@/src/components/addons/lf-button/Data';
import ActionsModel from '@/src/models/actions/ActionsModel';
import type { ButtonPropertiesState, ButtonStyleState, ShadowState } from '@/src/typings/interfaces/state/SharedAddons';
import {
  AlignContentType,
  ButtonStyleType,
  TextDecorationType,
  TextStyleType,
  TextTransformType
} from '@/src/typings/enums/enums';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { BoxShadowData } from '@/src/typings/interfaces/data/settings/settings';
import { isValidValue, replaceFontIdWithFontString, transformMeasurementToNumber } from '@/src/utilities/Utilities';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';

interface AddonLfButtonLayoutState {
  styles?: ButtonStyleState;
  shadow?: ShadowState;
}

interface AddonLfButtonState extends AddonModelState {
  buttonId: string;
  buttonStyle: ButtonStyleType;
  buttonEffect?: string;
  label: string;
  action?: ActionsModel;
  layout?: AddonLfButtonLayoutState;
  elementStyling: {
    align?: CSSProperties;
    button?: CSSProperties;
    buttonShadow?: CSSProperties;
    normal?: CSSProperties;
    hover?: CSSProperties;
    buttonInlineStyle?: CSSProperties;
    hoverStyling?: string;
  };
}

export class AddonLfButtonModel extends AddonModel<AddonLfButtonData, AddonLfButtonState, 'lf-button'> {
  parseAddon(data: AddonLfButtonData) {
    const state = this.state;

    if (data.settings.button_id) {
      state.buttonId = data.settings.button_id;
    }

    state.label = data.settings.label || 'Lorem ipsum';
    state.buttonStyle = data.settings.button_style || ButtonStyleType.PRIMARY;

    if (data.settings?.action?.type) {
      if (state.action) {
        state.action.setData(data.settings?.action);
      } else {
        state.action = new ActionsModel(data.settings?.action);
      }
    } else {
      state.action = undefined;
    }

    state.layout = state.layout ?? {};
    state.layout.shadow = AddonLfButtonModel.parseShadowData(data);
    state.layout.styles = AddonLfButtonModel.parseStyleData(data);
    state.elementStyling = state.elementStyling ?? {};
    if (state.layout?.styles) {
      state.elementStyling.button = AddonLfButtonModel.constructButtonInlineStyles(state.layout.styles);

      if (state.layout.styles.normal) {
        state.elementStyling.normal = AddonLfButtonModel.constructButtonNormalStyles(state.layout.styles.normal);
      } else {
        state.elementStyling.normal = undefined;
      }

      if (state.layout.styles.hover) {
        state.elementStyling.hover = AddonLfButtonModel.constructButtonHoverStyles(state.layout.styles.hover);
      } else {
        state.elementStyling.hover = undefined;
      }

      if (state.layout?.shadow) {
        state.elementStyling.buttonShadow = AddonLfButtonModel.constructButtonShadowState(state.layout.shadow);
      } else {
        state.elementStyling.buttonShadow = undefined;
      }

      state.elementStyling.align = {
        textAlign: state.layout.styles.alignment || AlignContentType.LEFT
      };

      state.elementStyling.hoverStyling = AddonLfButtonModel.constructHoverStyling(state);
      state.elementStyling.buttonInlineStyle = {
        ...state.elementStyling?.button,
        ...state.elementStyling?.buttonShadow
      };
    } else {
      state.elementStyling = {};
    }
  }

  private static parseShadowData(data: AddonLfButtonData): ShadowState | undefined {
    if (data.settings?.layout?.shadow) {
      const useData = getDeviceData<BoxShadowData>(data.settings.layout.shadow);

      if (!useData) {
        return undefined;
      }
      return AddonLfButtonModel.constructShadowState(useData);
    }
  }

  private static constructShadowState(data: BoxShadowData): ShadowState {
    return {
      blur: Number(data?.blur),
      color: data?.color,
      spread: Number(data?.spread),
      horizontal: Number(data?.horizontal),
      vertical: Number(data?.vertical)
    };
  }

  private static parseStyleData(data: AddonLfButtonData): ButtonStyleState | undefined {
    if (data.settings?.layout?.styles) {
      const useData = getDeviceData<ButtonStyleData>(data.settings.layout.styles);

      if (!useData) {
        return undefined;
      }

      return AddonLfButtonModel.constructStyleState(useData);
    }
  }

  private static generateButtonState(state: ButtonPropertiesData): ButtonPropertiesState {
    return {
      backgroundColor: state.background_color,
      borderColor: state.border_color,
      borderWidth: state.border_thickness,
      color: state.color
    };
  }

  private static constructStyleState(data: ButtonStyleData): ButtonStyleState {
    const isWidthPercentage = !!data.width?.includes('%');
    let width: number | undefined;

    if (data.width) {
      width = transformMeasurementToNumber(data.width);
    }
    if (data.transform === TextTransformType.DEFAULT) {
      data.transform = TextTransformType.NONE;
    }
    if (data.text_style === TextStyleType.DEFAULT) {
      data.text_style = TextStyleType.NORMAL;
    }
    if (data.text_decoration === TextDecorationType.DEFAULT) {
      data.text_decoration = TextDecorationType.NONE;
    }

    return {
      ...(data.alignment && { alignment: data.alignment }),
      ...(isValidValue(data.border_radius) && {
        borderRadius: Number(data.border_radius)
      }),
      ...(data.buttons_style && { buttonsStyle: data.buttons_style }),
      ...(data.font_family && { fontFamily: replaceFontIdWithFontString(data.font_family) }),
      ...(data.font_weight && {
        fontWeight: Number(data.font_weight)
      }),
      ...(data.letter_spacing && {
        letterSpacing: parseFloat(data.letter_spacing)
      }),
      ...(data.line_height && {
        lineHeight: parseFloat(data.line_height)
      }),
      ...(data.size && data.size !== '' && data.size !== '0px' && data.size !== '0%' && { fontSize: data.size }),
      ...(data.text_decoration && {
        textDecoration: data.text_decoration
      }),
      ...(data.text_style && { textStyle: data.text_style }),
      ...(data.transform && { transform: data.transform }),
      ...(data.width && { width: Number(width) }),
      ...(data.normal && {
        normal: AddonLfButtonModel.generateButtonState(data.normal)
      }),
      ...(data.hover && { hover: AddonLfButtonModel.generateButtonState(data.hover) }),
      isWidthPercentage
    };
  }

  private static constructButtonInlineStyles(state: ButtonStyleState): CSSProperties | undefined {
    if (!state) return;

    let widthMeasurement = 'px';

    if (state.isWidthPercentage) {
      widthMeasurement = '%';
    }

    if (state.fontFamily) {
      const utilityStore = useUtilityStore();
      utilityStore.loadFont(state.fontFamily);
    }

    return {
      ...(state.normal?.borderWidth && {
        border: `${state.normal.borderWidth}px solid ${state.normal.borderColor}`
      }),
      ...(typeof state.borderRadius !== 'undefined' && {
        borderRadius: `${state.borderRadius}px`
      }),
      ...(state.fontFamily && {
        fontFamily: `"${state.fontFamily}", "Helvetica Neue", Arial, sans-serif`
      }),
      ...(state.fontWeight && {
        fontWeight: state.fontWeight as CSSProperties['fontWeight']
      }),
      ...(state.fontSize && {
        fontSize: `${state.fontSize}`
      }),
      ...(state.textDecoration &&
        // default value 'none' should not be applied - as we rely on css classes instead for default values.
        state.textDecoration !== 'none' && {
          textDecoration: `${state.textDecoration}`
        }),
      // default value 'normal' should not be applied - as we rely on css classes instead for default values.
      ...(state.textStyle && state.textStyle !== 'normal' && { fontStyle: `${state.textStyle}` }),
      ...(state.transform &&
        // default value 'none' should not be applied - as we rely on css classes instead for default values.
        state.transform !== 'none' && {
          textTransform: state.transform as CSSProperties['textTransform']
        }),
      ...(state.letterSpacing && {
        letterSpacing: `${state.letterSpacing}px`
      }),
      ...(state.lineHeight && { lineHeight: state.lineHeight }),
      ...(typeof state.width !== 'undefined' && {
        minWidth: `${state.width}${widthMeasurement}`
      })
    };
  }

  private static constructButtonNormalStyles(state: ButtonPropertiesState): CSSProperties | undefined {
    if (!state) return;
    return {
      backgroundColor: state.backgroundColor,
      color: state.color,
      borderWidth: `${state.borderWidth}px`,
      borderColor: state.borderColor
    };
  }

  private static constructButtonHoverStyles(state: ButtonPropertiesState): CSSProperties | undefined {
    if (!state) return;
    return {
      ...(state.backgroundColor && {
        backgroundColor: state.backgroundColor
      }),
      ...(state.color && { color: state.color }),
      ...(state.borderWidth && {
        borderWidth: `${state.borderWidth}px`
      }),
      ...(state.borderColor && { borderColor: state.borderColor })
    };
  }

  private static constructButtonShadowState(state: ShadowState): CSSProperties | undefined {
    if (!state || !state.color) return;

    return {
      boxShadow: `${state?.horizontal}px ${state?.vertical}px ${state?.blur}px ${state?.spread}px ${state.color}`
    };
  }

  private static constructHoverStyling(state: AddonLfButtonState): string {
    return `.grid__addon--lf-button.${state.classIdentifier} .content__item-action.btn:hover {
          ${
            state.elementStyling?.hover?.backgroundColor
              ? `background-color: ${state.elementStyling.hover.backgroundColor} !important;`
              : ``
          }
          ${
            state.elementStyling?.hover?.borderColor
              ? `border-color: ${state.elementStyling.hover.borderColor} !important;`
              : ``
          }
          ${state.elementStyling?.hover?.color ? `color: ${state.elementStyling.hover.color} !important;` : ``}
          ${
            state.elementStyling?.hover?.borderWidth
              ? `border: ${state.elementStyling.hover.borderWidth} solid ${state.elementStyling.hover.borderColor} !important;`
              : ``
          }
        }`;
  }
}
