export enum HorizontalType {
  RIGHT = 'right',
  LEFT = 'left'
}

export enum BehaviorTypes {
  TILE,
  ACTUAL_SIZE,
  STRETCH,
  CONTAIN,
  COVER,
  CENTER
}

export enum PositionTypes {
  TOP_LEFT,
  TOP_CENTER,
  TOP_RIGHT,
  CENTER_LEFT,
  CENTER_CENTER,
  CENTER_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_CENTER,
  BOTTOM_RIGHT,
  CUSTOM
}

export enum BackgroundSizeType {
  ACTUAL_SIZE = 'actual_size',
  TILE = 'tile',
  STRETCH = 'stretch',
  CONTAIN = 'contain',
  COVER = 'cover'
}

export enum GameActionType {
  LOSER = 'loser',
  NO_PRIZE = 'no_prize',
  NEW_TURN = 'new_turn',
  WINNER = 'winner'
}

export enum SectionType {
  SECTION = 'section',
  POPOVER = 'popover',
  FLOWPAGE = 'flowpage'
}

export enum DeviceTypes {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile'
}

export enum ActiveElementTypes {
  SECTION = 'section',
  ROW = 'row',
  COLUMN = 'column',
  ADDON = 'addon',
  FLOWPAGE = 'flowpage'
}

export enum BackgroundType {
  GRADIENT = 'gradient',
  TRANSPARENT = 'transparent',
  SOLID = 'solid',
  IMAGE = 'image',
  VIDEO = 'video',
  SLIDER = 'slider'
}

export enum ImageSizeType {
  FULL = 'full',
  THUMB = 'thumb',
  MEDIUM = 'medium',
  CUSTOM = 'custom'
}

export enum IframeWidthType {
  WINDOW = 'window',
  CONTENT = 'content',
  FIXED = 'fixed'
}

export enum SliderTypes {
  SLIDE = 'slide',
  FADE = 'fade'
}

export enum VideoFormatType {
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  EXTERNAL = 'external'
}

export enum AdvancedWidthType {
  AUTO = 'auto',
  CUSTOM = 'custom',
  FULL = 'full',
  EMPTY = ''
}

export enum AdvancedPositionType {
  ABSOLUTE = 'absolute',
  FIXED = 'fixed',
  EMPTY = ''
}

export enum AdvancedHorizontalAlignmentType {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center'
}

export enum AdvancedVerticalAlignmentType {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum DividerPositionType {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum PositionType {
  TOP_LEFT = 'top_left',
  TOP_CENTER = 'top_center',
  TOP_RIGHT = 'top_right',
  CENTER_LEFT = 'center_left',
  CENTER_CENTER = 'center_center',
  CENTER_RIGHT = 'center_right',
  BOTTOM_LEFT = 'bottom_left',
  BOTTOM_CENTER = 'bottom_center',
  BOTTOM_RIGHT = 'bottom_right',
  CUSTOM = 'custom'
}

export enum OverlayType {
  TRANSPARENT = 'transparent',
  SOLID = 'solid',
  GRADIENTS = 'gradient'
}

export enum GradientPositionType {
  CENTER_CENTER = 'center_center',
  CENTER_LEFT = 'center_left',
  CENTER_RIGHT = 'center_right',
  TOP_CENTER = 'top_center',
  TOP_LEFT = 'top_left',
  TOP_RIGHT = 'top_right'
}

export enum GradientType {
  LINEAR = 'linear',
  RADIAL = 'radial'
}

export enum DividerTypes {
  WAVES = 'waves',
  FAN_OPACITY = 'fan_opacity',
  TRIANGLE = 'triangle',
  TILT_OPACITY = 'tilt_opacity',
  DROPS = 'drops',
  CURVE = 'curve',
  WAVES_BRUSH = 'waves_brush',
  PYRAMIDS = 'pyramids',
  TILT = 'tilt',
  SPLIT = 'split',
  TRIANGLE_ASYMMETRICAL = 'triangle_asymmetrical',
  WAVES_PATTERN = 'waves_pattern',
  BOOK = 'book',
  CLOUDS = 'clouds',
  ZIGZAG = 'zigzag',
  MOUNTAINS = 'mountains',
  CURVE_ASYMMETRICAL = 'curve_asymmetrical'
}

export enum BorderType {
  SOLID = 'solid',
  DASHED = 'dashed',
  DOTTED = 'dotted'
}

export enum FieldInputType {
  TEXT = 'text',
  FILE = 'file',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  HIDDEN = 'hidden',
  MASK = 'mask',
  TEXTAREA = 'textarea',
  DIVIDER = 'divider',
  RAIDO = 'radio',
  SLIDER = 'slider',
  DATE = 'date',
  SIGNATURE = 'signature',
  DAWA = 'dawa',
  CVR = 'cvr',
  PARAGRAPH = 'paragraph'
}

export enum FieldHtmlInputType {
  TEXT = 'text',
  EMAIL = 'email',
  CHECKBOX = 'checkbox',
  FILE = 'file',
  SELECT = 'select',
  HIDDEN = 'hidden',
  TEXTAREA = 'textarea',
  DIVIDER = 'divider',
  RADIO = 'radio',
  DAWA = 'dawa',
  CVR = 'cvr',
  PARAGRAPH = 'paragraph',
  TEL = 'tel'
}

export enum TargetType {
  SELF = '_self',
  BLANK = '_blank',
  TOP = '_top'
}

export enum FieldType {
  NAME = 'name',
  EMAIL = 'email',
  CHECKBOX = 'checkboxes',
  FILE = 'file',
  TEXT = 'text',
  EMAIL_CONFIRM = 'emailconfirm',
  PASSWORD = 'password',
  PASSWORD_CONFIRM = 'password_confirm',
  PHONE_NUMBER = 'phonenumber',
  PHONE_NUMBER_CONFIRM = 'phonenumber_confirm',
  SELECT = 'select',
  HIDDEN = 'hidden',
  MASK = 'mask',
  TEXTAREA = 'textarea',
  DIVIDER = 'divider',
  RADIO = 'radio',
  SLIDER = 'slider',
  DATE = 'date',
  DAWA = 'dawa',
  CVR = 'cvr',
  SIGNATURE = 'signature',
  PARAGRAPH = 'paragraph',
  MULTIPLE = 'multiple'
}

export enum CountdownType {
  TODATE = 'todate',
  SIMPLE = 'simple'
}

export enum AlignContentType {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export enum TransitionEnterType {
  NONE = '',
  FADE_IN = 'fadeIn',
  FADE_IN_DOWN = 'fadeInDown',
  FADE_IN_DOWN_BIG = 'fadeInDownBig',
  FADE_IN_LEFT = 'fadeInLeft',
  FADE_IN_LEFT_BIG = 'fadeInLeftBig',
  FADE_IN_RIGHT = 'fadeInRight',
  FADE_IN_RIGHT_BIG = 'fadeInRightBig',
  FADE_IN_UP = 'fadeInUp',
  FADE_OUT = 'fadeOut',
  FADE_OUT_DOWN = 'fadeOutDown',
  FADE_OUT_DOWN_BIG = 'fadeOutDownBig',
  FADE_OUT_LEFT = 'fadeOutLeft',
  FADE_OUT_LEFT_BIG = 'fadeOutLeftBig',
  FADE_OUT_RIGHT = 'fadeOutRight',
  FADE_OUT_RIGHT_BIG = 'fadeOutRightBig',
  FADE_OUT_UP = 'fadeOutUp',
  FADE_OUT_UP_BIG = 'fadeOutUpBig',
  SLIDE_OUT_UP = 'slideOutUp',
  SLIDE_OUT_DOWN = 'slideOutDown',
  SLIDE_OUT_LEFT = 'slideOutLeft',
  SLIDE_OUT_RIGHT = 'slideOutRight',
  ZOOM_OUT = 'zoomOut',
  ZOOM_OUT_DOWN = 'zoomOutDown',
  ZOOM_OUT_LEFT = 'zoomOutLeft',
  ZOOM_OUT_RIGHT = 'zoomOutRight',
  ZOOM_OUT_UP = 'zoomOutUp',
  ROTATE_IN = 'rotateIn',
  ROTATE_OUT_DOWN_LEFT = 'rotateOutDownLeft',
  ROTATE_OUT_DOWN_RIGHT = 'rotateOutDownRight',
  ROTATE_OUT_UP_LEFT = 'rotateOutUpLeft',
  ROTATE_OUT_UP_RIGHT = 'rotateOutUpRight',
  FLIP = 'flip',
  FLIP_OUT_X = 'flipOutX',
  FLIP_OUT_Y = 'flipOutY',
  BOUNCE_OUT = 'bounceOut',
  BOUNCE_OUT_DOWN = 'bounceOutDown',
  BOUNCE_OUT_LEFT = 'bounceOutLeft',
  BOUNCE_OUT_RIGHT = 'bounceOutRight',
  BOUNCE_OUT_UP = 'bounceOutUp'
}

export enum TextTransformType {
  CAPITALIZE = 'capitalize',
  NONE = 'none',
  UPPERCASE = 'uppercase',
  LOWERCASE = 'lowercase',
  DEFAULT = 'default'
}

export enum TextStyleType {
  ITALIC = 'italic',
  DEFAULT = 'default',
  NORMAL = 'normal',
  OBLIQUE = 'oblique'
}

export enum TextDecorationType {
  NONE = 'none',
  UNDERLINE = 'underline',
  DEFAULT = 'default',
  OVERLINE = 'overline',
  LINE_THROUGH = 'line-through'
}

export enum InputOptionType {
  INTERVAL = 'interval',
  OPTIONS = 'options',
  LINE = 'line',
  PASTE = 'paste',
  COUNTRIES = 'countries'
}

export enum ButtonStyleType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export enum CampaignDeviceType {
  ALL_DEVICES,
  MOBILE_ONLY,
  ADS
}

export enum CampaignAdsSizeType {
  RESPONSIVE,
  FIXED
}

export enum ActionType {
  URL = 'url',
  SECTION = 'section',
  POPOVER = 'popover',
  GOTO_FLOW_PAGE = 'goto_flow_page',
  RESTART = 'restart',
  ONCLICK = 'onclick',
  FORCE_SKIP_REGISTRATION = 'force_skip_registration',
  CLOSE_POPOVER = 'close_popover',
  HIDE = 'hide',
  FADE = 'fade',
  NEXTFLOW = 'nextflow',
  NONE = 'none',
  CLOSE_POPUP = 'close_popup',
  FLOWPAGE = 'flowpage',
  COPY_TO_CLIPBOARD = 'copy_to_clipboard',
  FINISH_GAME = 'finish_game',
  CLICK_TAG = 'click_tag'
}

export enum HeadingTagType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6'
}

export enum ContentPositionType {
  TOP = 'top',
  MIDDLE = 'middle',
  CENTER = 'center',
  BOTTOM = 'bottom'
}

export enum HorizontalPositionType {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export enum ShapeType {
  CIRCLE = 'circle',
  CURLY = 'curly',
  UNDERLINE = 'underline',
  DOUBLE = 'double',
  DOUBLE_UNDERLINE = 'double_underline',
  UNDERLINE_ZIGZAG = 'underline_zigzag',
  DIAGONAL = 'diagonal',
  STRIKETHROUGH = 'strikethrough',
  CROSS = 'cross'
}

export enum HeightType {
  FLUID = 'fluid',
  FIXED = 'fixed',
  WINDOW = 'window'
}

export enum RepeatType {
  NO_REPEAT = 'no-repeat',
  REPEAT = 'repeat',
  REPEAT_X = 'repeat-x',
  REPEAT_Y = 'repeat-y'
}

export enum AnimatedHeadlineStyleType {
  ROTATION = 'rotation',
  ROTATION_ONLY_TEXT = 'rotation-only-text',
  HIGHLIGHTED = 'highlighted'
}

export enum AnimatedHeadlineAnimationType {
  SLIDE = 'slide',
  FLIP = 'flip',
  SLIDE_DOWN = 'slide-down',
  DROP_IN = 'drop-in'
}

export enum IconDisplayType {
  DEFAULT = 'default',
  STACKED = 'stacked',
  FRAMED = 'framed'
}

export enum IconShapeType {
  SQUARE = 'square',
  CIRCLE = 'circle'
}

export enum GapType {
  DEFAULT = 'default',
  NO_GUTTER = 'no_gutter'
}

export enum GoogleMapType {
  NORMAL = 'roadmap',
  HYBRID = 'hybrid',
  SATELLITE = 'satellite'
}

export enum GoogleMapDisplayColor {
  COLOR = 'color',
  GREYSCALE = 'greyscale'
}

export enum VideoEmbedType {
  PLAYER = 'player',
  EXTERNAL = 'external'
}

export enum BooleanDataType {
  TRUE = '1',
  FALSE = '0'
}

export enum VideoSizeType {
  PERCENT = 'percent',
  PIXEL = 'pixel'
}

export enum TrustPilotSkinType {
  DARK = 'dark',
  LIGHT = 'light'
}

export enum DrawType {
  AUTO = 'auto',
  MANUAL = 'manual'
}

export enum CampaignIntervalType {
  CAMPAIGN_PERIOD = 'CAMPAIGN_PERIOD',
  DAY = 'DAY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum CookieConsentProvider {
  COOKIE_INFORMATION = 'cookieinformation',
  COOKIE_BOT = 'cookiebot'
}

export enum KeyBinding {
  ESCAPE = 'esc',
  ENTER = 'enter',
  BACKSPACE = 'backspace'
}

export enum EventActions {
  RESIZE = 'resize',
  SCROLL_TOP = 'scroll-to-top',
  CAMPAIGN_READY = 'campaign-ready'
}

export enum HotKeys {
  COPY = 'ctrl+c,command+c',
  PASTE = 'ctrl+v,command+v',
  MOVEUP = 'ctrl+up,command+up',
  MOVEDOWN = 'ctrl+down,command+down',
  SECTION_SETTINGS = 'ctrl+s,command+s',
  GAME_SETTINGS = 'ctrl+g,command+g',
  LAYOUT_SETTINGS = 'ctrl+l,command+l',
  DUPLICATE = 'ctrl+d,command+d',
  DELETE = 'delete',
  EXTRA_COLUMN = 'ctrl+i,command+i'
}
