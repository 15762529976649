import type { CSSProperties } from 'vue';
import type { VisibilityConditionsData } from '@/src/typings/interfaces/data/conditions/visibilityConditions';
import { BaseModel } from '@/src/models/BaseModel';
import type {
  AdvancedStyleData,
  BackgroundData,
  BorderData,
  BoxShadowData,
  ContentColorData,
  DividerData,
  HeightData,
  LayoutData,
  OverlayData,
  PopupPositionData,
  SettingsData,
  TransitionsData
} from '@/src/typings/interfaces/data/settings/settings';
import { PopupPositionType } from '@/src/typings/interfaces/data/settings/settings';
import { VisibilityConditionsModel } from '@/src/models/conditions/VisibilityConditionsModel';
import type { CustomCSSModel } from '@/src/models/CustomCSSModel';
import { getDeviceData } from '@/src/hooks/useDevice';
import type {
  BorderType,
  ContentPositionType,
  DividerPositionType,
  DividerTypes,
  GapType,
  GradientPositionType,
  GradientType,
  HorizontalPositionType,
  OverlayType,
  PositionType,
  SliderTypes,
  VideoFormatType
} from '@/src/typings/enums/enums';
import { ActionType, BackgroundType, BehaviorTypes, HeightType, RepeatType } from '@/src/typings/enums/enums';
import {
  generateVimeoUrl,
  generateYouTubeUrl,
  getVimeoIdFromUrl,
  getYouTubeVideoIdFromUrl,
  shuffle
} from '@/src/utilities/Utilities';
import type { GridTypes, SectionModelType, TransitionTypes } from '@/src/typings/types/types';
import ActionsModel from '@/src/models/actions/ActionsModel';
import type { InlineElement, ShadowState } from '@/src/components/layout/typing/interfaces';
import { InlineHeightEnum } from '@/src/components/layout/typing/interfaces';
import type { ActionData } from '@/src/typings/interfaces/data/settings/actions';

interface HeightState {
  layout?: GridTypes;
  width?: string; // either with px or % at the end
  gap?: GapType;
  type?: HeightType;
  subtractWindow?: number;
  fixed?: number;
  minimum?: number;
  contentPosition?: ContentPositionType;
  overwriteTablet?: boolean;
  overwriteMobile?: boolean;
}

export interface VideoState {
  type?: VideoFormatType;
  url?: string;
  disableLooping?: boolean;
  opacity?: number;
  fallbackImage?: {
    enabled: boolean;
    url?: string;
    behavior?: BehaviorTypes;
    position?: string;
  };
}

interface BackgroundState {
  backgroundType: BackgroundType;
  color: string;
  video?: VideoState;
  image?: BackgroundImage;
  slider?: {
    images: Array<string>;
    interval?: {
      time?: number;
    };

    transition?: SliderTypes;
    transitionTime?: number;
    shuffle?: boolean;
  };
  backgroundToSection: boolean;
  overwriteTablet?: boolean;
  overwriteMobile?: boolean;
}

export interface BackgroundImage {
  src?: string;
  behavior?: BehaviorTypes;
  tileRepeat?: RepeatType;
  position?: PositionType;
  xPosition?: string;
  yPosition?: string;
  opacity?: number;
}

export interface SectionBackground {
  id: string;
  url: string;
}

export interface OverlayState {
  overlayToSection: boolean;
  overlayType?: OverlayType;
  overlayColor?: string;
  location1?: number;
  color2?: string;
  location2?: number;
  gradientType?: GradientType;
  gradientAngle?: number;
  gradientPosition?: GradientPositionType;
  overwriteTablet?: boolean;
  overwriteMobile?: boolean;
}

interface ContentColorState {
  useDefaultTextColor?: boolean;
  color?: string;
  overwriteTablet?: boolean;
  overwriteMobile?: boolean;
}

export interface DividerState {
  enabled?: boolean;
  position: DividerPositionType;
  type?: DividerTypes;
  color?: string;
  width?: number;
  height?: number;
  flip?: boolean;
  invert?: boolean;
  front?: boolean;
  overwriteTablet?: boolean;
  overwriteMobile?: boolean;
}

export interface BorderState {
  type?: BorderType;
  color?: string;
  width?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  overwriteTablet?: boolean;
  overwriteMobile?: boolean;
}

export interface AdvancedStyleState {
  margin?: {
    top?: number;
    right?: number | string; // number when in pixels - otherwise string containing 'auto'
    bottom?: number;
    left?: number | string; // number when in pixels - otherwise string containing 'auto'
  };
  padding?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  minHeight?: number;
  overwriteTablet?: boolean;
  overwriteMobile?: boolean;
}

interface PopupPositionState {
  type: PopupPositionType;
  x: number;
  y: number;
}

interface PopupOverlayState {
  enabled: boolean;
  backgroundColor?: string;
}

interface TransitionsState {
  enter?: TransitionTypes;
  enterDuration?: number;
  enterDelay?: number;
  leave?: TransitionTypes;
  leaveDuration?: number;
  leaveDelay?: number;
  overwriteTablet?: boolean;
  overwriteMobile?: boolean;
}

interface SettingsBasicState {
  // Section
  height?: HeightState;
  // Row & column
  name?: string;
  layout?: LayoutState;
  position?: PopupPositionState;
  overlay?: PopupOverlayState;
  action?: ActionsModel;
}

interface LayoutState {
  width?: string;
  alignment?: string;
  contentPosition?: ContentPositionType;
  horizontalPosition?: HorizontalPositionType;
  overwriteTablet?: boolean;
  overwriteMobile?: boolean;
}

interface SettingsStyleState {
  background?: BackgroundState;
  overlay?: OverlayState;
  border?: BorderState;
  shadow?: ShadowState;
  content?: ContentColorState;
  divider?: DividerState;
}

interface SettingsAdvancedState {
  style?: AdvancedStyleState;
  advanced?: {
    zIndex?: number;
    elementId?: string;
    classname?: string;
  };
  transitions?: TransitionsState;
  visibilityCondition?: VisibilityConditionsModel;
  customCss?: CustomCSSModel;

  // + Row & column advanced settings
  showOnDesktop?: boolean;
  showOnTablet?: boolean;
  showOnMobile?: boolean;

  // + Row advanced only
  repeater?: {
    enable: boolean;
    source?: string;
    data?: 'json_feed' | 'rss_feed' | '';
    feedUrl?: string;
    columnGrid?: number;
    sortBy?: {
      by?: string;
    };
    limit?: number;
  };
}

interface SettingsConfigState {
  isDividerEnabled?: boolean;
  isBackgroundEnabled?: boolean;
  isBackgroundImageEnabled?: boolean;
  isBackgroundColorEnabled?: boolean;
  isOverlayEnabled?: boolean;
  isOverlayGradientEnabled?: boolean;
  isVideoEnabled?: boolean;
  isSliderEnabled?: boolean;
}

export interface BackgroundSlideState {
  imageUrl: string;
  style: CSSProperties;
}

export interface SettingsState {
  basic?: SettingsBasicState;
  style: SettingsStyleState;
  advanced: SettingsAdvancedState;
  elementStyling: SettingElementStyling;
  redirect?: ActionsModel;
  config: SettingsConfigState;
  backgroundSlides?: BackgroundSlideState[];
  sectionBackground?: SectionBackground[];
}

export interface SettingElementStyling {
  styling?: CSSProperties;
  background?: {
    general?: InlineElement;
    color?: CSSProperties;
    overlay?: CSSProperties;
    video?: CSSProperties;
    image?: CSSProperties;
  };
}

export type SettingsType = 'section' | 'row' | 'column' | '';

export class SettingsModel<Data extends SettingsData = SettingsData> extends BaseModel<Data, SettingsState> {
  protected sectionModel: SectionModelType;

  constructor(data: Data, sectionModel: SectionModelType) {
    super(data);

    this.sectionModel = sectionModel;
    this.parse(data);
  }

  shallSkipInitialParse(): boolean {
    return true;
  }

  isBackgroundForcedEnabled(): boolean {
    return false;
  }

  getSettingsType(): SettingsType {
    return '';
  }

  parse(data: Data) {
    const state = this.state;
    // Create empty objects and set values
    state.basic = state.basic || {};
    state.style = state.style || {};
    state.advanced = state.advanced || {};

    state.basic.name = data?.basic?.name;

    state.basic.layout = SettingsModel.parseLayoutData(data);

    // It's important that we parse position data before we parse parseAdvancedStyleData()
    // This is because popup x/y will overwrite margin values on the section.
    state.basic.position = SettingsModel.parsePopupPositionData(data);

    state.basic.overlay = this.parsePopupOverlayData(data);
    state.basic.height = SettingsModel.parseHeightData(data);
    state.style.background = SettingsModel.parseStyleBackgroundData(data);
    state.style.border = SettingsModel.parseStyleBorderData(data);
    state.style.content = SettingsModel.parseStyleContentData(data);
    state.style.divider = SettingsModel.parseStyleDividerData(data);
    state.style.shadow = SettingsModel.parseStyleShadowData(data);
    state.style.overlay = SettingsModel.parseStyleOverlayDate(data);
    state.advanced.style = this.parseAdvancedStyleData(data);

    if (data.advanced?.redirect && data.advanced?.redirect?.enabled === '1') {
      const redirectActionData: ActionData = {
        url: data.advanced.redirect.url,
        target: data.advanced.redirect.target,
        delay: data.advanced.redirect.delay ? Number(data.advanced.redirect.delay) : undefined,
        type: data.advanced.redirect.type ? data.advanced.redirect.type : ActionType.URL
      };

      if (data.advanced?.redirect.enabled) {
        if (state.redirect) {
          state.redirect.setData(redirectActionData);
        } else {
          state.redirect = new ActionsModel(redirectActionData);
        }
      } else {
        state.redirect = undefined;
      }
    }

    const advancedData = data.advanced?.advanced;
    if (advancedData) {
      state.advanced.advanced = {
        zIndex: Number(advancedData.zindex),
        classname: advancedData.classname,
        elementId: advancedData.element_id
      };
    }

    state.advanced.transitions = SettingsModel.parseAdvancedTransitionsData(data);

    state.elementStyling = state.elementStyling || {};
    state.elementStyling.background = {};
    state.elementStyling.background.general = this.constructElementInlineStyles(state);
    state.elementStyling.background.color = SettingsModel.constructBackgroundColorStyles(state);
    state.elementStyling.background.video = SettingsModel.constructVideoBackgroundStyles(state);
    state.elementStyling.background.overlay = SettingsModel.constructBackgroundOverlayStyles(
      state,
      this.getSettingsType()
    );
    state.elementStyling.background.image = SettingsModel.constructBackgroundImageStyles(state);

    if (data.basic?.action) {
      if (state.basic.action) {
        state.basic.action.setData(data.basic.action);
      } else {
        state.basic.action = new ActionsModel(data.basic.action);
      }
    } else {
      state.basic.action = undefined;
    }

    const visibilityConditionsData = this.constructVisibilityConditionData(data);

    if (visibilityConditionsData) {
      if (this.state.advanced.visibilityCondition) {
        this.state.advanced.visibilityCondition.setData(visibilityConditionsData);
      } else {
        this.state.advanced.visibilityCondition = new VisibilityConditionsModel(visibilityConditionsData);
      }
    } else {
      this.state.advanced.visibilityCondition = undefined;
    }

    state.config = this.constructConfigState(state);
    state.backgroundSlides = this.constructBackgroundSlidesState(state);
    state.sectionBackground = SettingsModel.constructSectionBackground(state);
    /**
     * If we are forcing a background (popovers) and we dont have a section background,
     * we set it to transparent.
     */
    if (this.isBackgroundForcedEnabled() && !state.sectionBackground) {
      state.sectionBackground = [
        {
          id: 'transparent',
          url: ''
        }
      ];
    }
  }

  public getDefaultPadding() {
    return 30;
  }

  private static parsePopupPositionData(data: SettingsData): PopupPositionState | undefined {
    if (data.basic?.position) {
      const useData = getDeviceData<PopupPositionData>(data.basic.position);

      if (!useData) {
        return undefined;
      }

      return SettingsModel.constructPopupPositionState(useData);
    }
  }

  protected constructVisibilityConditionData(data: Data): VisibilityConditionsData | undefined {
    return data.advanced?.visibility_condition;
  }

  /**
   * Currently we don't transform the data. But leaving it here to follow the convention we use.
   * Then it's easier to extend if we need to in the future.
   */
  private static constructPopupPositionState(data: PopupPositionData): PopupPositionState {
    return {
      type: data.type,
      x: Number(data.x),
      y: Number(data.y)
    };
  }

  private parsePopupOverlayData(data: SettingsData): PopupOverlayState | undefined {
    if (data.basic?.height) {
      const useData = getDeviceData<HeightData>(data.basic.height);

      if (!useData) {
        return undefined;
      }

      return this.constructPopupOverlayState(useData);
    }
  }

  private constructPopupOverlayState(data: HeightData): PopupOverlayState {
    return {
      enabled: data.overlay === '1' || this.state.basic?.position?.type === PopupPositionType.CENTER_CENTER,
      backgroundColor: data.overlay_bgcolor ? data.overlay_bgcolor : 'rgba(0, 0, 0, 0.65)'
    };
  }

  private static parseLayoutData(data: SettingsData): LayoutState | undefined {
    if (data.basic?.layout) {
      const useData = getDeviceData(data.basic.layout) as LayoutData | undefined;

      if (!useData) {
        return undefined;
      }
      return SettingsModel.constructLayoutState(useData);
    }
  }

  // Parse Settings Data functions
  private static parseHeightData(data: SettingsData): HeightState | undefined {
    if (data.basic?.height) {
      const useData = getDeviceData(data.basic.height) as HeightData | undefined;

      if (!useData) {
        return undefined;
      }
      return SettingsModel.constructHeightState(useData);
    }
  }

  private static constructHeightState(data: HeightData): HeightState {
    return {
      layout: data.width_type || 'grid',
      ...(data.width && { width: data.width }),
      gap: data.gap || 'default',
      type: data.type || 'fluid',
      ...(data.subtract_window && { subtractWindow: parseInt(data.subtract_window) }),
      ...(data.fixed && { fixed: parseInt(data.fixed) }),
      ...(data.minimum && { minimum: parseInt(data.minimum) }),
      contentPosition: data.content_position || 'top'
    };
  }

  private static constructLayoutState(data: LayoutData): LayoutState {
    return {
      ...(data.width && { width: data.width }),
      ...(data.alignment && { alignment: data.alignment }),
      ...(data.content_position && {
        contentPosition: data.content_position
      }),
      ...(data.horizontal_position && {
        horizontalPosition: data.horizontal_position
      })
    };
  }

  private static parseStyleBackgroundData(data: SettingsData) {
    if (data.style?.background) {
      const useData = getDeviceData(data.style.background) as BackgroundData | undefined;

      if (!useData) {
        return undefined;
      }

      return SettingsModel.constructBackgroundStyleState(useData);
    }
  }

  private static constructBackgroundStyleState(data: BackgroundData): BackgroundState {
    let behaviourType: BehaviorTypes | undefined;

    switch (data?.image?.behavior) {
      case 'cover':
        behaviourType = BehaviorTypes.COVER;
        break;
      case 'contain':
        behaviourType = BehaviorTypes.CONTAIN;
        break;
      case 'stretch':
        behaviourType = BehaviorTypes.STRETCH;
        break;
      case 'tile':
        behaviourType = BehaviorTypes.TILE;
        break;
      case 'actual_size':
        behaviourType = BehaviorTypes.ACTUAL_SIZE;
        break;
    }
    return {
      color: '',
      ...(data.background_type && {
        backgroundType: data.background_type
      }),
      ...(data.color && { color: data.color }),
      image: {
        ...(typeof behaviourType === 'number' && {
          behavior: behaviourType
        }),
        ...(data.image?.opacity && {
          opacity: Number(data.image.opacity)
        }),
        ...(data.image?.position && {
          position: data.image.position
        }),
        ...(data.image?.src && { src: data.image.src }),
        ...(data.image?.tile_repeat && {
          tileRepeat: data.image.tile_repeat
        }),
        ...(data.image?.x_position && {
          xPosition: data.image.x_position
        }),
        ...(data.image?.y_position && {
          yPosition: data.image.y_position
        })
      },
      ...(data?.slider?.images && {
        slider: {
          ...(data.slider?.images && {
            images: data.slider.images || []
          }),
          interval: {
            ...(data.slider?.interval?.time && {
              time: Number(data.slider.interval.time) * 1000
            })
          },
          ...(data.slider && { shuffle: data.slider.shuffle === '1' }),
          ...(data.slider?.transition && {
            transition: data.slider.transition
          }),
          ...(data.slider?.transition_time && {
            transitionTime: Number(data.slider?.transition_time) * 1000
          })
        }
      }),

      video: {
        ...(data.video?.url && { url: data.video.url }),
        ...(data.video?.disable_looping && {
          disableLooping: data.video.disable_looping === '1'
        }),
        fallbackImage: {
          enabled: !!data.video?.fallback_image,
          url: data.video?.fallback_image,
          behavior: behaviourType,
          position: data.image?.position
        },
        ...(data.video?.opacity && {
          opacity: Number(data.video.opacity)
        }),
        ...(data.video?.type && { type: data.video.type })
      },
      backgroundToSection: data.background_to_section === '1' || false
    };
  }

  private static parseStyleBorderData(data: SettingsData) {
    if (data.style?.border) {
      const useData = getDeviceData(data.style.border) as BorderData | undefined;

      if (!useData) {
        return undefined;
      }
      return SettingsModel.constructStyleBorderState(useData);
    }
  }

  private static constructStyleBorderState(data: BorderData): BorderState | undefined {
    if (!data.type) return;
    if (data.type) {
      return {
        ...(data.color && { color: data.color }),
        type: data.type,
        width: {
          ...(data.width?.top && { top: Number(data.width.top) }),
          ...(data.width?.right && {
            right: Number(data.width.right)
          }),
          ...(data.width?.bottom && {
            bottom: Number(data.width.bottom)
          }),
          ...(data.width?.left && { left: Number(data.width.left) })
        }
      };
    }
  }

  private static parseStyleContentData(data: SettingsData) {
    if (data.style?.content) {
      const useData = getDeviceData(data.style.content) as ContentColorData | undefined;

      if (!useData) {
        return undefined;
      }
      return SettingsModel.constructStyleContentState(useData);
    }
  }

  private static constructStyleContentState(data: ContentColorData): ContentColorState {
    return {
      ...(data.color && { color: data.color }),
      ...(data.use_default_text_color && {
        useDefaultTextColor: data.use_default_text_color === '1'
      })
    };
  }

  private static parseStyleDividerData(data: SettingsData) {
    if (data.style?.divider) {
      const useData = getDeviceData(data.style.divider) as DividerData | undefined;

      if (!useData) {
        return undefined;
      }
      return SettingsModel.constructStyleDividerState(useData);
    }
  }

  private static constructStyleDividerState(data: DividerData): DividerState {
    return {
      enabled: data.enabled === '1',
      ...(data.position && { position: data.position }),
      ...(data.type && { type: data.type }),
      ...(data.color && { color: data.color }),
      ...(data.width && { width: Number(data.width) }),
      ...(data.height && { height: Number(data.height) }),
      ...(data.flip && { flip: data.flip === '1' }),
      ...(data.invert && { invert: data.invert === '1' }),
      ...(data.front && { front: data.front === '1' })
    };
  }

  private static parseStyleShadowData(data: SettingsData) {
    if (data.style?.shadow) {
      const useData = getDeviceData(data.style.shadow) as BoxShadowData | undefined;

      if (!useData) {
        return undefined;
      }
      return SettingsModel.constructStyleShadowState(useData);
    }
  }

  private static constructStyleShadowState(data: BoxShadowData): ShadowState {
    /**
     * This fixes issue with some campaign having 0 as returning an empty string
     * To accomodate and make this work, we need to set the values to 0
     */
    if (data.blur === '') {
      data.blur = '0';
    }
    if (data.spread === '') {
      data.spread = '0';
    }
    return {
      ...(data.color && { color: data.color }),
      ...(data.blur && { blur: Number(data.blur) }),
      ...(data.spread && { spread: Number(data.spread) }),
      ...(data.horizontal && { horizontal: Number(data.horizontal) }),
      ...(data.vertical && { vertical: Number(data.vertical) })
    };
  }

  private static parseStyleOverlayDate(data: SettingsData) {
    if (data.style?.overlay) {
      const useData = getDeviceData(data.style.overlay) as OverlayData | undefined;

      if (!useData) {
        return undefined;
      }
      return SettingsModel.constructStyleOverlayState(useData);
    }
  }

  private static constructStyleOverlayState(data: OverlayData): OverlayState {
    return {
      overlayToSection: data.overlay_to_section === '1' || false,
      ...(data.overlay_type && { overlayType: data.overlay_type }),
      ...(data.overlay_color && { overlayColor: data.overlay_color }),
      ...(data.location_1 !== undefined ? { location1: Number(data.location_1) } : {}),
      ...(data.color_2 && { color2: data.color_2 }),
      ...(data.location_2 !== undefined ? { location2: Number(data.location_2) } : {}),
      ...(data.gradient_type && { gradientType: data.gradient_type }),
      ...(data.gradient_angle && {
        gradientAngle: Number(data.gradient_angle)
      }),
      ...(data.gradient_position && {
        gradientPosition: data.gradient_position
      })
    };
  }

  private parseAdvancedStyleData(data: SettingsData) {
    if (data.advanced?.style) {
      const useData = getDeviceData(data.advanced.style) as AdvancedStyleData | undefined;
      if (!useData) {
        return undefined;
      }
      return this.constructAdvancedStyleState(useData);
    }
  }

  private isNumeric(n: string | number): boolean {
    // marginRight and Left can be both string auto or a number
    return !isNaN(Number(n));
  }

  protected constructAdvancedStyleState(data: AdvancedStyleData): AdvancedStyleState {
    let marginRight: number | string | undefined;
    if (data.margin?.right && this.isNumeric(data.margin.right)) {
      marginRight = Number(data.margin.right);
    } else {
      marginRight = data.margin?.right;
    }

    let marginLeft: number | string | undefined;
    if (data.margin?.left && this.isNumeric(data.margin.left)) {
      marginLeft = Number(data.margin.left);
    } else {
      marginLeft = data.margin?.left;
    }

    return {
      ...(data?.min_height && { minHeight: parseInt(data.min_height) }),
      margin: {
        ...((data?.margin?.top || data?.margin?.top === '0') && { top: Number(data.margin?.top) }),
        ...((data?.margin?.bottom || data?.margin?.bottom === '0') && { bottom: Number(data.margin?.bottom) }),
        ...((data?.margin?.left || data?.margin?.left === '0') && { left: marginLeft }),
        ...((data?.margin?.right || data.margin?.right === '0') && { right: marginRight })
      },
      padding: {
        ...((data?.padding?.top || data?.padding?.top === 0) && { top: Number(data.padding.top) }),
        ...((data?.padding?.bottom || data?.padding?.bottom === 0) && { bottom: Number(data.padding.bottom) }),
        ...((data?.padding?.left || data?.padding?.left === 0) && { left: Number(data.padding.left) }),
        ...((data?.padding?.right || data?.padding?.right === 0) && { right: Number(data.padding.right) })
      }
    };
  }

  private static parseAdvancedTransitionsData(data: SettingsData) {
    if (data.advanced?.transitions) {
      const useData = getDeviceData(data.advanced.transitions) as TransitionsData | undefined;
      if (!useData) {
        return undefined;
      }
      return SettingsModel.constructAdvancedTransitionState(useData);
    }
  }

  private static constructAdvancedTransitionState(data: TransitionsData): TransitionsState {
    return {
      ...(data.enter && { enter: data.enter as TransitionTypes }),
      ...(data.enter_duration && {
        enterDuration: Number(data.enter_duration)
      }),
      ...(data.enter_delay && {
        enterDelay: Number(data.enter_delay)
      }),
      ...(data.leave && { leave: data.leave as TransitionTypes }),
      ...(data.leave_duration && {
        leaveDuration: Number(data.leave_duration)
      }),
      ...(data.leave_delay && {
        leaveDelay: Number(data.leave_delay)
      })
    };
  }

  protected constructElementInlineStyles(state: SettingsState): InlineElement {
    const output: InlineElement = {
      padding: {
        top: this.getDefaultPadding(),
        bottom: this.getDefaultPadding()
      }
    };
    if (state.basic?.height?.type === HeightType.FLUID && state.basic?.height?.minimum) {
      output.height = {
        type: InlineHeightEnum.FLUID,
        value: Number(state.basic.height.minimum)
      };
    }

    if (state.basic?.height?.type === HeightType.FIXED) {
      output.height = {
        type: InlineHeightEnum.FIXED,
        value: Number(state.basic.height.fixed)
      };
    }

    if (state.advanced.advanced?.zIndex) {
      output.zIndex = state.advanced.advanced.zIndex;
    }

    if (state.advanced.style?.minHeight) {
      output.minHeight = state.advanced.style?.minHeight;
    }

    if (state.advanced?.style?.padding?.top || state.advanced?.style?.padding?.top === 0) {
      output.padding.top = state.advanced?.style.padding.top;
    }
    if (state.advanced?.style?.padding?.bottom || state.advanced?.style?.padding?.bottom === 0) {
      output.padding.bottom = state.advanced?.style.padding.bottom;
    }
    if (state.advanced?.style?.padding?.left || state.advanced?.style?.padding?.left === 0) {
      output.padding.left = state.advanced?.style.padding.left;
    }
    if (state.advanced?.style?.padding?.right || state.advanced?.style?.padding?.right === 0) {
      output.padding.right = state.advanced?.style.padding.right;
    }

    if (state.advanced?.style?.margin) {
      output.margin = {};
    }

    if (state.advanced?.style?.margin?.top && output.margin) {
      output.margin.top = state.advanced?.style.margin.top;
    }
    if (state.advanced?.style?.margin?.bottom && output.margin) {
      output.margin.bottom = state.advanced?.style.margin.bottom;
    }
    if (state.advanced?.style?.margin?.left && output.margin) {
      output.margin.left = state.advanced?.style.margin.left;
    }
    if (state.advanced?.style?.margin?.right && output.margin) {
      output.margin.right = state.advanced?.style.margin.right;
    }

    if (state.style?.border) {
      output.border = state.style?.border;
    }

    if (state.basic?.layout?.contentPosition) {
      output.verticalPosition = state.basic.layout.contentPosition;
    }
    if (state.basic?.layout?.horizontalPosition) {
      output.horizontalPosition = state.basic.layout.horizontalPosition;
    }
    if (state.style?.shadow) {
      output.shadow = state.style?.shadow;
    }

    if (!state.style?.content?.useDefaultTextColor && state.style?.content?.color) {
      output.color = state.style?.content?.color;
    }

    return output;
  }

  private static constructBackgroundColorStyles(state: SettingsState): CSSProperties {
    return {
      ...(state.style?.background?.color && {
        backgroundColor: state.style.background.color
      })
    };
  }

  private static constructVideoBackgroundStyles(state: SettingsState): CSSProperties {
    let backgroundStyle = {};

    const image = state.style.background?.video?.fallbackImage?.url ?? '';

    const backgroundPosition = state.style.background?.video?.fallbackImage?.position?.replace('_', ' ');
    let opacity = 1;

    if (state.style.background?.video?.opacity) {
      opacity = state.style.background.video.opacity / 100;
    }

    switch (state.style.background?.video?.fallbackImage?.behavior) {
      case BehaviorTypes.STRETCH:
        backgroundStyle = {
          backgroundImage: 'url(' + image + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          opacity
        };
        break;
      case BehaviorTypes.TILE:
        backgroundStyle = {
          backgroundImage: 'url(' + image + ')',
          backgroundRepeat: state.style.background?.image?.tileRepeat,
          backgroundPosition,
          opacity
        };
        break;
      case BehaviorTypes.ACTUAL_SIZE:
        backgroundStyle = {
          backgroundImage: 'url(' + image + ')',
          backgroundRepeat: 'no-repeat',
          backgroundPosition,
          opacity
        };
        break;

      case BehaviorTypes.CONTAIN:
        backgroundStyle = {
          backgroundImage: 'url(' + image + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition,
          opacity
        };
        break;
      case BehaviorTypes.COVER:
        backgroundStyle = {
          backgroundImage: 'url(' + image + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition,
          opacity
        };
        break;
      default:
        backgroundStyle = {
          backgroundImage: 'url(' + image + ')',
          backgroundRepeat: 'no-repeat',
          backgroundPosition,
          opacity
        };
    }

    return {
      ...backgroundStyle
    };
  }

  private static constructBackgroundOverlayStyles(state: SettingsState, settingsType: string): CSSProperties {
    const backgroundOverlayState = {
      color2: state.style?.overlay?.color2,
      gradientAngle: state.style?.overlay?.gradientAngle,
      gradientPosition: state.style?.overlay?.gradientPosition,
      gradientType: state.style?.overlay?.gradientType,
      location1: state.style?.overlay?.location1,
      location2: state.style?.overlay?.location2,
      overlayColor: state.style?.overlay?.overlayColor,
      overlayType: state.style?.overlay?.overlayType
    };

    let backgroundOverlay: string | undefined;

    if (backgroundOverlayState.overlayType === 'transparent') {
      return {};
    }

    let requireBothGradientColors = true;
    if (settingsType === 'column') {
      requireBothGradientColors = false;
    }

    if (!backgroundOverlayState.gradientAngle) {
      backgroundOverlayState.gradientAngle = 0;
    }

    if (requireBothGradientColors) {
      if (
        backgroundOverlayState.gradientType === 'linear' &&
        backgroundOverlayState.overlayColor &&
        backgroundOverlayState.overlayType === 'gradient' &&
        backgroundOverlayState.overlayColor &&
        backgroundOverlayState.color2 &&
        backgroundOverlayState.overlayColor !== '' &&
        backgroundOverlayState.color2 !== ''
      ) {
        backgroundOverlay = `linear-gradient(${backgroundOverlayState.gradientAngle}deg, ${
          backgroundOverlayState.overlayColor
        } ${backgroundOverlayState.location1 ?? 'transparent'}%, ${backgroundOverlayState.color2 ?? 'transparent'} ${
          backgroundOverlayState.location2
        }%)`;
      }
    } else if (
      backgroundOverlayState.gradientType === 'linear' &&
      backgroundOverlayState.overlayColor &&
      backgroundOverlayState.overlayType === 'gradient'
    ) {
      backgroundOverlay = `linear-gradient(${backgroundOverlayState.gradientAngle}deg, ${
        backgroundOverlayState.overlayColor
      } ${backgroundOverlayState.location1 ?? 'transparent'}%, ${backgroundOverlayState.color2 ?? 'transparent'} ${
        backgroundOverlayState.location2
      }%)`;
    }

    if (requireBothGradientColors) {
      if (
        backgroundOverlayState.gradientType === 'radial' &&
        backgroundOverlayState.overlayColor &&
        backgroundOverlayState.overlayType === 'gradient' &&
        backgroundOverlayState.overlayColor &&
        backgroundOverlayState.color2 &&
        backgroundOverlayState.overlayColor !== '' &&
        backgroundOverlayState.color2 !== ''
      ) {
        const gradientPosition = backgroundOverlayState?.gradientPosition
          ? backgroundOverlayState?.gradientPosition.replace('-', ' ')
          : 'center center';
        backgroundOverlay = `radial-gradient(ellipse at ${gradientPosition}, ${backgroundOverlayState.overlayColor} ${backgroundOverlayState.location1}%, ${backgroundOverlayState.color2} ${backgroundOverlayState.location2}%)`;
      } else if (
        backgroundOverlayState.gradientType === 'radial' &&
        backgroundOverlayState.overlayColor &&
        backgroundOverlayState.overlayType === 'gradient'
      ) {
        const gradientPosition = backgroundOverlayState?.gradientPosition
          ? backgroundOverlayState?.gradientPosition.replace('-', ' ')
          : 'center center';
        backgroundOverlay = `radial-gradient(ellipse at ${gradientPosition}, ${
          backgroundOverlayState.overlayColor ?? 'transparent'
        } ${backgroundOverlayState.location1}%, ${backgroundOverlayState.color2 ?? 'transparent'} ${
          backgroundOverlayState.location2
        }%)`;
      }
    }

    if (backgroundOverlayState.overlayType === 'solid') {
      backgroundOverlay = backgroundOverlayState.overlayColor;
    }

    return {
      ...(backgroundOverlay && { background: backgroundOverlay })
    };
  }

  private static constructBackgroundImageStyles(state: SettingsState): CSSProperties {
    const image = state?.style?.background?.image;

    const backgroundColor = state.style?.background?.color;
    const position = image?.position?.replace('_', ' ');

    let opacity: number | undefined;

    if (image && image.opacity) {
      opacity = image?.opacity / 100;
    }

    const yPosition: string | undefined = image?.yPosition ?? '0';
    const xPosition: string | undefined = image?.xPosition ?? '0';

    let repeat = RepeatType.NO_REPEAT;
    let behaviour: string | undefined;

    switch (image?.behavior) {
      case BehaviorTypes.ACTUAL_SIZE:
        behaviour = 'initial';
        break;
      case BehaviorTypes.COVER:
        behaviour = 'cover';
        break;
      case BehaviorTypes.CONTAIN:
        behaviour = 'contain';
        break;
      case BehaviorTypes.STRETCH:
        behaviour = '100% 100%';
        break;
      case BehaviorTypes.TILE:
        behaviour = 'tile';
        break;
    }

    if (image?.behavior === BehaviorTypes.TILE) {
      repeat = image.tileRepeat ?? RepeatType.NO_REPEAT;
    }

    return {
      ...(image?.src && {
        backgroundImage: `url("${image.src}")`
      }),
      ...(repeat && { backgroundRepeat: repeat }),
      ...(behaviour !== 'tile' && { backgroundSize: behaviour }),
      ...(position &&
        position !== 'custom' && {
          backgroundPosition: position
        }),
      ...(backgroundColor && !image?.src && { backgroundColor: backgroundColor || 'transparent' }),
      ...(position === 'custom' && {
        ...(xPosition && { backgroundPositionX: xPosition }),
        ...(yPosition && { backgroundPositionY: yPosition })
      }),
      ...(opacity && { opacity })
    };
  }

  private constructConfigState(state: SettingsState): SettingsConfigState {
    let hasBackground = !!state?.style?.background;
    let isBackgroundEnabled = false;
    let isBackgroundImageEnabled = false;
    let isVideoEnabled = false;
    let isOverlayGradientEnabled = false;
    let isOverlayEnabled = false;
    let isSliderEnabled = false;
    let isDividerEnabled = false;

    if (this.isBackgroundForcedEnabled()) {
      isBackgroundEnabled = true;
      hasBackground = true;
    }

    /**
     * set isVideoEnabled if video exist
     */
    if (state.style?.background?.video) {
      const videoState = state.style.background.video;

      if (state.style.background.backgroundType === BackgroundType.VIDEO) {
        isVideoEnabled = !!videoState?.url || !!videoState?.fallbackImage;
      }
    }

    /**
     * Check if any overlays are available
     * */
    if (state?.style?.overlay) {
      if (state.style.overlay.overlayType === 'solid') {
        isOverlayEnabled = true;
      } else if (state.style.overlay.overlayType === 'gradient') {
        isOverlayGradientEnabled = true;
      }
    }

    /**
     * Set background config state
     */

    if (hasBackground && state?.style?.background) {
      const backgroundType = state?.style?.background.backgroundType;
      const background = state.style?.background;

      if (backgroundType === BackgroundType.SOLID) {
        isBackgroundEnabled = true;
      }

      if (backgroundType === BackgroundType.IMAGE) {
        if (background.color) {
          isBackgroundEnabled = true;
        }

        if (state.style.background?.image?.src && state.style.background.image.src !== '') {
          isBackgroundImageEnabled = true;
        }
      }
      if (backgroundType === BackgroundType.SLIDER && background?.slider?.interval) {
        isSliderEnabled = true;
      }
    }

    /**
     * Set divider visible state
     */
    if (state.style?.divider?.enabled) {
      isDividerEnabled = true;
    }

    return {
      isDividerEnabled,
      isBackgroundEnabled,
      isBackgroundImageEnabled,
      isOverlayGradientEnabled,
      isOverlayEnabled,
      isVideoEnabled,
      isSliderEnabled
    };
  }

  private static constructSectionBackground(state: SettingsState): SectionBackground[] | undefined {
    /**
     * If there is a background defined, add it to array as we need to loop it in the view
     */
    const hasBackground = !!state?.style?.background;

    if (hasBackground && state?.style?.background) {
      const backgroundType = state.style.background.backgroundType;
      const background = state.style?.background;
      const defaultBackground = {
        id: 'transparent',
        url: ''
      };

      if (backgroundType === BackgroundType.SOLID) {
        defaultBackground.id = 'background';
      }
      if (backgroundType === BackgroundType.IMAGE) {
        defaultBackground.id = 'image';
      }
      if (backgroundType === BackgroundType.VIDEO && background.video && background.video.url) {
        const videoId =
          background.video.type === 'youtube'
            ? getYouTubeVideoIdFromUrl(background?.video?.url)
            : getVimeoIdFromUrl(background?.video?.url);

        defaultBackground.id = 'video';
        if (background.video.type === 'youtube' && videoId) {
          defaultBackground.url = generateYouTubeUrl(videoId);
        } else if (background.video.type === 'vimeo' && videoId) {
          defaultBackground.url = generateVimeoUrl(videoId);
        }
      }
      return [
        {
          ...defaultBackground
        }
      ];
    }
  }

  private constructBackgroundSlidesState(state: SettingsState): BackgroundSlideState[] | undefined {
    const hasBackground = !!state?.style?.background;

    if (hasBackground && state?.style?.background) {
      const background = state.style.background;

      if (background?.slider) {
        const hasImages = background.slider.images.some((image) => image.length > 0);

        if (!hasImages || !state.elementStyling.background?.image) {
          return;
        }

        const backgroundPosition = state.style.background?.image?.position?.replace('_', ' ');
        const backgroundPositionX = state.style.background?.image?.xPosition;
        const backgroundPositionY = state.style.background?.image?.yPosition;

        const images = background.slider.images
          .filter((image) => image.length > 0)
          .map((image) => {
            let backgroundStyle: CSSProperties = {};

            switch (state.style.background?.image?.behavior) {
              case BehaviorTypes.STRETCH:
                backgroundStyle = {
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%'
                };
                break;

              case BehaviorTypes.TILE:
                backgroundStyle = {
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: state.style.background?.image?.tileRepeat
                };
                break;

              case BehaviorTypes.ACTUAL_SIZE:
                backgroundStyle = {
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: 'no-repeat'
                };
                break;

              case BehaviorTypes.CONTAIN:
                backgroundStyle = {
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain'
                };
                break;

              case BehaviorTypes.COVER:
                backgroundStyle = {
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                };
                break;

              default:
                backgroundStyle = {
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: 'no-repeat'
                };
            }

            return {
              imageUrl: image,
              style: {
                ...state.elementStyling.background?.image,
                ...backgroundStyle,
                ...(backgroundPosition &&
                  backgroundPosition !== 'custom' && {
                    backgroundPosition
                  }),
                ...(backgroundPosition === 'custom' && {
                  ...(backgroundPositionX && { backgroundPositionX }),
                  ...(backgroundPositionY && { backgroundPositionY })
                }),
                ...(!state.style.background?.backgroundToSection && {
                  ...(state.basic?.height?.width && { width: state.basic?.height?.width })
                })
              }
            };
          });

        if (this.state.style.background?.slider?.shuffle) {
          return shuffle(images);
        }

        return images;
      }
    }
  }
}
