import { watch } from 'vue';
import { validate } from 'vee-validate';
import { useCampaignStore } from '@/src/store/campaign';
import type { FieldOptionItem } from './types';
import { BaseModel } from '@/src/models/BaseModel';
import type { FieldHtmlInputType, FieldInputType } from '@/src/typings/enums/enums';
import { FieldType } from '@/src/typings/enums/enums';
import type { VisibilityConditionsState } from '@/src/models/conditions/VisibilityConditionsModel';
import { VisibilityConditionsModel } from '@/src/models/conditions/VisibilityConditionsModel';
import { applyReplacementTags, convertHTMLEntities, isSsr } from '@/src/utilities/Utilities';
import { getQueryParams } from '@/src/utilities/Url';
import type { VisibilityConditionsData } from '@/src/typings/interfaces/data/conditions/visibilityConditions';
import { getFormCookieData, getGlobalFormCookieData } from '@/src/utilities/Registration';
import type { AddonRegistrationModel } from '@/src/components/addons/registration/Model';

interface DeviceSettings {
  showOnDesktop: boolean;
  showOnTablet: boolean;
  showOnMobile: boolean;
}

export type FormElementValidationTypes = Array<
  | 'min_age'
  | 'min_date'
  | 'max_date'
  | 'numbers'
  | 'chars'
  | 'max_chars'
  | 'min_chars'
  | 'regex'
  | 'phone'
  | 'strip_special_chars'
  | 'email'
  | 'javascript_function'
  | 'file'
>;

export interface BaseFormElementSettingsData {
  field_size: '1column' | '2column';
  field_class: string;
  hide_if_autofilled: string;
  element_message?: string;
  readonly?: string;
  readonly_edit?: string;
  sdk_identifier?: string;
  tooltip?: {
    enabled: string;
    text?: string;
  };
  decryption?: {
    enabled?: string;
  };
  disable_cookie: string;
  disable_autocomplete: string;
  advanced?: {
    visibility_condition?: VisibilityConditionsData;
    show_on_desktop: string;
    show_on_tablet?: string;
    show_on_mobile?: string;
  };

  validation?: FormElementValidationTypes;
  javascript_function?: string;
  validation_message?: string;
  regex?: string;
  min_chars?: string;
  max_chars?: string;
}

export interface BaseFormElementData {
  id: string;
  name: string;
  default_value: string;
  required: boolean;
  visible: boolean;
  editable: boolean;
  deletable: boolean;
  exportable: boolean;
  label: string;
  placeholder: string;
  input_type: FieldInputType;
  settings?: BaseFormElementSettingsData;
  input_html_type: FieldHtmlInputType;
  type: FieldType;
  value: string;
}

export interface FormElementValidation {
  types: FormElementValidationTypes;
  message?: string;
  regex?: string;
  javascriptFunction?: string;
  maxChars?: number;
}

export interface BaseFormElementState<Value> {
  // TODO: Should not be a string. But a number. As it's a integer.
  id: string;
  name: string;
  defaultValue: string | number;
  required: boolean;
  isVisible: boolean;
  readonly: boolean;
  readonlyEdit: boolean;
  elementMessage?: string;
  label: string;
  placeholder: string;
  inputType: FieldInputType;
  inputHtmlType: FieldHtmlInputType;
  sdkIdentifier?: string;
  type: FieldType;
  visibilityConditions?: VisibilityConditionsModel;
  deviceSettings?: DeviceSettings;

  fieldSize?: '1column' | '2column';
  fieldClass?: string;
  hideIfAutofilled: boolean;
  minChars?: number;
  maxChars?: number;
  decryption: {
    enabled: boolean;
  };

  tooltip?: {
    enabled: boolean;
    text?: string;
  };
  disableCookie: boolean;
  disableAutocomplete: boolean;
  advanced?: {
    visibilityCondition?: VisibilityConditionsState;
    showOnDesktop: boolean;
    showOnTablet?: boolean;
    showOnMobile?: boolean;
  };

  value?: Value;
  isValid?: boolean;
  validated?: boolean;

  // Hidden & ignore validation is typically used by decryption. When a field is filled out by decryption
  // it should no longer display & no longer validate.
  hidden: boolean;
  ignoreValidation: boolean;
  containsEncryptedValue: boolean;
  includeInApi: boolean;

  validation?: FormElementValidation;
}

export abstract class BaseFormElementModel<
  Value,
  Data extends BaseFormElementData,
  State extends BaseFormElementState<Value>
> extends BaseModel<Data, State> {
  public id: string;

  constructor(data: Data) {
    super(data);
    this.id = data.id;

    if (!isSsr()) {
      watch(
        () => this.state.value,
        () => this.validate(),
        {
          immediate: true,
          deep: true
        }
      );

      watch(
        () => this.state.containsEncryptedValue,
        () => this.validate()
      );
    }
  }

  parse(data: Data) {
    const state = this.state;
    this.id = state.id = data.id;

    state.name = data.name;
    state.defaultValue = data.default_value;
    state.label = data.label;
    state.placeholder = data.placeholder;
    state.inputType = data.input_type;
    state.type = data.type;

    state.sdkIdentifier = data.settings?.sdk_identifier ? data.settings?.sdk_identifier : undefined;

    state.readonly = data.settings?.readonly === '1';
    state.readonlyEdit = data.settings?.readonly_edit === '1';
    state.fieldClass = data.settings?.field_class;
    state.fieldSize = data.settings?.field_size;
    state.disableCookie = data.settings?.disable_cookie === '1';
    state.disableAutocomplete = data.settings?.disable_autocomplete === '1';
    state.hideIfAutofilled = data.settings?.hide_if_autofilled === '1';
    state.hidden = state.hidden || false;
    state.ignoreValidation = state.ignoreValidation || false;
    state.containsEncryptedValue = state.containsEncryptedValue || false;

    // Sometimes, depending on integrations and/or implementations we would like
    // form fields to not be included in the API.
    // This is expecially the case for user recognition where we would not
    // like for the FE to send empty values and overwrite the BE values on subsequent requests.
    state.includeInApi = typeof state.includeInApi !== 'undefined' ? state.includeInApi : true;

    if (data.settings?.min_chars) {
      state.minChars = Number(data.settings.min_chars);
    }

    if (data.settings?.max_chars) {
      state.maxChars = Number(data.settings.max_chars);
    }

    state.decryption = {
      enabled: data.settings?.decryption?.enabled === '1'
    };

    state.elementMessage = data.settings?.element_message;

    state.required = data.required;
    state.isVisible = data.visible;
    state.inputHtmlType = data.input_html_type;

    if (data.settings?.advanced?.visibility_condition) {
      if (state.visibilityConditions) {
        state.visibilityConditions.setData(data.settings.advanced.visibility_condition);
      } else {
        state.visibilityConditions = new VisibilityConditionsModel(data.settings.advanced.visibility_condition);
      }
    } else {
      state.visibilityConditions = undefined;
    }

    if (data.settings?.advanced) {
      state.deviceSettings = {
        showOnDesktop: data.settings.advanced.show_on_desktop === '1',
        showOnTablet: data.settings.advanced.show_on_tablet === '1',
        showOnMobile: data.settings.advanced.show_on_mobile === '1'
      };
    } else {
      state.deviceSettings = undefined;
    }

    if (data.settings?.tooltip) {
      state.tooltip = {
        enabled: data.settings.tooltip.enabled === '1',
        text: data.settings.tooltip.text
      };
    } else {
      state.tooltip = undefined;
    }

    if (data.settings?.validation) {
      const validationTypes = data.settings?.validation;
      let regex: string | undefined;
      if (data.settings.regex && validationTypes.includes('regex')) {
        regex = data.settings.regex;
      } else if (validationTypes.includes('regex')) {
        validationTypes.splice(validationTypes.indexOf('regex'), 1);
      }

      state.validation = {
        types: validationTypes,
        ...(regex && {
          regex
        }),
        message: data.settings?.validation_message,
        javascriptFunction: data.settings?.javascript_function
      };
    } else {
      state.validation = undefined;
    }

    this.parseFormElement(data);

    if (typeof state.value === 'undefined') {
      this.setInitialValue();
    }

    const value = this.getSerializedCookieValue();

    // Hide if filled check
    if (state.hideIfAutofilled && value !== '' && value !== '0' && !(value + '').includes('#')) {
      state.isVisible = false;
    }
    // End hide if filled check
  }

  public setInitialValue(): void {
    if (this.state.value === undefined) {
      this.state.value = !isSsr() ? this.getInitialValue() : undefined;
    }
  }

  abstract getSerializedPostValue(): unknown;
  abstract getSerializedCookieValue(): string;

  /**
   * @author Dannie Hansen <dannie@leadfamly.com>
   */
  abstract parseFormElement(data: Data): void;

  /**
   * Get a list over available query parameters for this field.
   *
   * @author Dannie Hansen <dannie@leadfamly.com>
   */
  get queryParameters(): string[] {
    const campaignStore = useCampaignStore();

    return [
      BaseFormElementModel.convertToSystemString(this.state.label),
      `${this.id}`,
      this.state.label.toLowerCase(),
      this.state.name,
      ...([FieldType.EMAIL, FieldType.NAME].includes(this.state.type) ? [this.state.type.toString()] : [])
    ].filter((param) => {
      return !(
        campaignStore.model?.state.config?.userRecognition?.parameters &&
        campaignStore.model?.state.config?.userRecognition?.parameters.includes(param)
      );
    });
  }

  public get shouldSetReplacementTags(): boolean {
    const campaignStore = useCampaignStore();

    // If user recognition is enabled, and the field mapping includes this field - then we should not set replacement tags.
    // As the replacement tag may be set by the exposed fields from the user recognition.
    if (
      campaignStore.staticFormData.ur_tokens &&
      campaignStore.model?.state.config?.userRecognition?.mapping &&
      Object.values(campaignStore.model?.state.config?.userRecognition?.mapping)
        .map((value) => String(value))
        .includes(String(this.id))
    ) {
      return false;
    }

    return true;
  }

  public async validateOnSubmit(): Promise<boolean> {
    return true;
  }

  /**
   * Validate the current states value.
   */
  public async validate(): Promise<boolean> {
    if (this.state.ignoreValidation) {
      this.state.isValid = true;
      this.state.validated = true;
      return true;
    }

    if (this.state.containsEncryptedValue) {
      this.state.isValid = true;
      this.state.validated = true;
      return this.state.isValid;
    }

    this.state.isValid = (await validate(this.getSerializedPostValue(), this.getValidationRules().join('|'))).valid;
    this.state.validated = true;

    return this.state.isValid;
  }

  /**
   * Method for getting initial value to use for this field.
   * This field should ideally call getInitialStringValue() &
   * format it to however it needs it.
   */
  abstract getInitialValue(): Value | undefined;

  /**
   * Parse an string value into our needed value format.
   */
  abstract parseStringValue(value: string): Value | undefined;

  /**
   * Generic rules implementation. This method is called initially
   * and set all validation rules on the state it-self.
   */
  public getValidationRules(): string[] {
    const rulesArr: string[] = [];

    if (this.state.required) {
      rulesArr.push('required');
    }

    if (this.state.validation) {
      this.state.validation.types
        .filter((rule) => !['min_age', 'file', 'min_date', 'max_date'].includes(rule))
        .forEach((validationRule) => {
          let funcArguments = '';

          if (validationRule === 'javascript_function' && this.state.validation?.javascriptFunction) {
            // nosem
            funcArguments += this.state.validation?.javascriptFunction;
          }

          if (validationRule === 'min_chars') {
            funcArguments += this.state.minChars;
          }

          if (validationRule === 'max_chars') {
            funcArguments += this.state.maxChars;
          }

          if (validationRule === 'regex') {
            if (this.state.validation?.regex) {
              const regex = convertHTMLEntities(this.state.validation.regex);
              // converts regex to a Base64-encoded ASCII
              // We need to do this because vee validate does different things base on symbol in the string
              // vee validate use pipes (|) to separate validations and if a regex contains pipes (|) vee will try to separate the regex also!
              // etc. regex ^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)&#92;d{4}$ would be seperated to ^([0-2][0-9] because if (|)
              funcArguments += window.btoa(regex);
            }
          }

          rulesArr.push(`${validationRule}${funcArguments ? `:${funcArguments}` : ''}`);
        });
    }

    return rulesArr;
  }

  public setValid(isValid: boolean) {
    this.state.isValid = isValid;
  }

  public getSpecificFieldFromInputType<T>(inputType: FieldType) {
    let fieldModel: T | undefined;
    const campaignStore = useCampaignStore();

    // Loop over sections, flow pages & popovers to find the field model that matches the input type and field id.
    // we have to do this since we can have registrations on all these types
    campaignStore.model?.state.popovers?.forEach((popoverModel) => {
      popoverModel.getAddons<AddonRegistrationModel>('registration').forEach((registrationModel) => {
        if (registrationModel?.state?.fields?.find((field) => field.state.id === this.state.id)) {
          registrationModel.state.fields.forEach((field) => {
            if (field.state.type === inputType) {
              // TODO: Investigate if we can strongly type this
              // @ts-ignore
              fieldModel = field;
            }
          });
        }
      });
    });

    campaignStore.model?.state.sections?.forEach((sectionModel) => {
      sectionModel.getAddons<AddonRegistrationModel>('registration').forEach((registrationModel) => {
        if (registrationModel?.state?.fields?.find((field) => field.state.id === this.state.id)) {
          registrationModel.state.fields.forEach((field) => {
            if (field.state.type === inputType) {
              // TODO: Investigate if we can strongly type this
              // @ts-ignore
              fieldModel = field;
            }
          });
        }
      });
    });

    campaignStore.model?.state.flowPages?.forEach((pageModel) => {
      pageModel.getAddons<AddonRegistrationModel>('registration').forEach((registrationModel) => {
        if (registrationModel?.state?.fields?.find((field) => field.state.id === this.state.id)) {
          registrationModel.state.fields.forEach((field) => {
            if (field.state.type === inputType) {
              // TODO: Investigate if we can strongly type this
              // @ts-ignore
              fieldModel = field;
            }
          });
        }
      });
    });

    return fieldModel;
  }

  /**
   * Get the initial string value to use for this field.
   *
   * @author Dannie Hansen <dannie@leadfamly.com>
   */
  protected getInitialStringValue(): string | undefined {
    if (isSsr()) {
      return undefined;
    }

    const params = getQueryParams();
    const campaignStore = useCampaignStore();
    const replacementTags = campaignStore.replacementTags;

    let value: string | undefined = this.state.defaultValue.toString();
    // Look for a field_x replacement tag matching the field id. This is so we can set values for fields
    // from a replacement tag directly. This will also by-pass any other value that it attempt at getting.
    if (typeof replacementTags[`field_${this.id}`] !== 'undefined' && replacementTags[`field_${this.id}`]) {
      return applyReplacementTags(replacementTags[`field_${this.id}`] + '');
    }

    if (
      typeof replacementTags[`registration_field_${this.id}`] !== 'undefined' &&
      replacementTags[`registration_field_${this.id}`]
    ) {
      return applyReplacementTags(replacementTags[`field_${this.id}`] + '');
    }

    // Loop over query parameters and attempt at finding a match in the query parameters allowed by the field model.
    this.queryParameters.forEach((param) => {
      if (typeof params[`${param}`] === 'string') {
        value = params[`${param}`];
      }
    });

    // Get campaign form cookies and attempt at finding a match if they're not disabled for the field and if
    // decryption isn't enabled.
    if (!this.state.disableCookie && !this.state.decryption.enabled && this.state.type !== FieldType.HIDDEN) {
      const formCookie = getFormCookieData();
      const globalFormCookie = getGlobalFormCookieData();

      // Extract the text from the HTML string.
      const tmp = document.createElement('div');
      tmp.innerHTML = this.state.name; // nosem
      const name = (tmp.textContent || tmp.innerText || '').trim();

      if (typeof globalFormCookie[`${name}`] !== 'undefined' && globalFormCookie[`${name}`] !== '') {
        value = globalFormCookie[`${name}`];
      }

      if (typeof formCookie[this.id] !== 'undefined' && formCookie[this.id] !== '') {
        value = formCookie[this.id];
      }
    }

    if (typeof value !== 'undefined' && value) {
      // For fields with decryption enabled we need to hide the element so user doesn't see the value.
      if (this.state.decryption.enabled) {
        this.state.hidden = true;
        this.state.ignoreValidation = true;
        this.state.containsEncryptedValue = true;
      }

      // For e.g. checkbox we can encounter number values. So we need to ensure we only attempt at applying replacement
      // tags when the data type is string.
      if (typeof value === 'string') {
        return applyReplacementTags(value);
      }

      return applyReplacementTags(value);
    }

    return '';
  }

  authorSignature(): string {
    return 'Nicky Christensen / Dannie Hansen';
  }

  /**
   * Backend data comes in a obscrured data format.
   *
   * key_1: ...
   * key_2: ...
   * val_1: ...
   * val_2: ...
   *
   * This washes that and creates a nice options array.
   */
  protected parseFieldOptions(options: { [key: string]: string }) {
    const keyOptions: { [key: number]: { option: FieldOptionItem; index: number } } = {};

    for (const optionIndex in options) {
      if (Object.prototype.hasOwnProperty.call(options, optionIndex)) {
        const indexItems = optionIndex.split('_', 2);

        const definition = indexItems[0];
        const numericIndex = Number(indexItems[1]);

        // eslint-disable-next-line security/detect-object-injection
        keyOptions[numericIndex] = keyOptions[numericIndex] ?? {
          index: numericIndex,
          option: {}
        };

        switch (definition) {
          case 'key':
            // eslint-disable-next-line security/detect-object-injection
            keyOptions[numericIndex].option.label = options[optionIndex];
            break;

          case 'val':
            // eslint-disable-next-line security/detect-object-injection
            keyOptions[numericIndex].option.value = options[optionIndex];
            break;
        }
      }
    }

    return Object.values(keyOptions)
      .sort((a, b) => {
        if (a.index < b.index) {
          return -1;
        } else if (a.index > b.index) {
          return 1;
        }

        return 0;
      })
      .map((item) => item.option);
  }

  /**
   * Old relic from the old platform. We need to use the exact same function for this -
   * otherwise we risk query parameters not working for some campaigns.
   *
   * @author Dannie Hansen <dannie@leadfamly.com>
   */
  private static convertToSystemString(text: string): string {
    return text
      .replace(/æ/gi, 'ae')
      .replace(/ø/gi, 'oe')
      .replace(/å/gi, 'aa')
      .replace(/ /gi, '_')
      .replace(/[^a-zA-Z0-9_-]/gi, '')
      .toLowerCase();
  }

  abstract getStringifiedValue(): string | undefined;
}
