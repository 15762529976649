import { sendEvent } from './../utilities/iFrameUtils';
import { inIframe, isSsr } from './../utilities/Utilities';
import { events } from '@/src/services/events';
import { formatUrl } from '@/src/utilities/Url';
import type { ReplacementTags } from '@/src/store/campaign';

export enum RedirectTarget {
  BLANK,
  TOP,
  SELF
}

export interface RedirectItem {
  url: string;
  target: RedirectTarget;
}

export type RedirectEvent = CustomEvent<RedirectItem>;

export const redirect = (item: RedirectItem, extraReplacementTags?: ReplacementTags | undefined) => {
  if (isSsr()) {
    return;
  }

  const formattedUrl = formatUrl(item.url, extraReplacementTags);

  item.url = formattedUrl ?? item.url;

  const event: RedirectEvent = new CustomEvent<RedirectItem>('redirect');
  event.initCustomEvent('redirect', true, true, item);

  events.emit('redirect', event);

  if (event.defaultPrevented) {
    return;
  }

  // Safeguard against javascript.browser.security.open-redirect.js-open-redirect semgrep rule.
  if (item.url.startsWith('javascript:')) {
    throw new Error('Unsafe JavaScript redirect. Rejected.');
  }

  switch (item.target) {
    case RedirectTarget.BLANK:
      window.open(item.url);
      break;

    case RedirectTarget.SELF:
      if (inIframe()) {
        sendEvent('scroll-to-top');

        // Allow event-loop to flush the events to the receiver
        // nosem
        setTimeout(() => {
          window.location.href = item.url; // nosem
        }, 1);
      } else {
        window.location.href = item.url; // nosem
      }
      break;

    case RedirectTarget.TOP:
      if (window.top) {
        try {
          window.top.location.href = item.url;
        } catch (e) {
          // If window.top is undefined, or not accessible.
          // Then fallback to just navigating away in the current window.
          if (inIframe()) {
            sendEvent('scroll-to-top');

            // Allow event-loop to flush the events to the receiver
            // nosem
            setTimeout(() => {
              window.location.href = item.url; // nosem
            }, 1);
          } else {
            window.location.href = item.url; // nosem
          }
        }
      } else {
        window.location.href = item.url; // nosem
      }
      break;
  }
};

export const getHostName = () => {
  return location.hostname;
};
