import { SdkQuizQuestionModel } from './sdk/question-model';
import type { GameQuizSdkSettings } from './sdk/exports';
import type { GameIndicator, GameState } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import { GameAdvancedSettingsModel } from '@/src/models/settings/GameAdvancedSettingsModel';
import type {
  BackgroundBehaviorType,
  BackgroundPositionType,
  GameQuizData,
  GameQuizGeneralData,
  GameQuizQuestionData,
  GameQuizSwipeButtonData,
  QuizSwipeItButtonsType
} from '@/src/components/games/quiz/Data';
import { AlignContentType, BehaviorTypes, PositionTypes } from '@/src/typings/enums/enums';
import { AnswerLayoutSettingsModel } from '@/src/models/settings/AnswerLayoutSettingsModel';
import { VisibilityConditionsModel } from '@/src/models/conditions/VisibilityConditionsModel';
import { GameEndingConditionType } from '@/src/typings/interfaces/data/conditions/visibilityConditions';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import useDevice, { getDeviceData } from '@/src/hooks/useDevice';
import { formatUrl } from '@/src/utilities/Url';
import type { GameSectionImageOverlay, MetricData } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';

interface VisibilityConditionData {
  condition: GameEndingConditionType;
  date_range: {
    enabled: string;
    date_to: string;
    date_from: string;
  };
}

export enum QuizGameTypes {
  STANDARD,
  RANDOMIZE
}

export enum QuizVideoPositionTypes {
  ABOVE,
  LEFT,
  RIGHT
}

export enum QuizAnswerTypes {
  TEXT,
  IMAGE,
  SLIDER,
  SWIPE
}

export interface GameQuizQuestionFeedbackState {
  enabled: boolean;
  redirectToNextQuestion?: boolean;
  delayUntilNext?: number;
  buttonNextLabel?: string;
  previousQuestionLabel?: string;
  feedbackButtonLabel?: string;
  buttonLastLabel?: string;
  answerMessage?: {
    correct?: string;
    wrong?: string;
  };
}

export interface CustomLabelState {
  buttons: {
    answer?: string;
    lastAnswer?: string;
    selectAnswer?: string;
  };
  linkLabel?: string;
}

export interface GameQuizSwipeItButtonsState {
  color?: string;
  enabled: boolean;
  leftText?: string;
  leftImage?: string;
  rightImage?: string;
  rightText?: string;
  onlyFacebook?: boolean;
  type?: QuizSwipeItButtonsType;
}

export interface GameQuizGeneralState {
  gameType: QuizGameTypes;
  contentAlign: AlignContentType;
  answerAlignment: AlignContentType;
  questionsShown?: number;
  previousQuestionLabel?: string;
  correctAnswersRequired?: number;
  loserOnIncorrectAnswer: boolean;
  feedback: GameQuizQuestionFeedbackState;
  buttons: GameQuizSwipeItButtonsState;
  showAnswerButton: boolean;
  enableSkipLogic: boolean;
  showCorrectAnswer: boolean;
  customLabels: CustomLabelState;

  time: {
    enabled: boolean;
    limit?: number;
    perQuestion: boolean;
  };

  features: {
    bestTime: boolean;
    timeRanking: boolean;
  };

  goBackwards: {
    enabled: boolean;
  };

  activeStateColor?: string;
  activeStateBackgroundColor?: string;

  focusStateBackgroundColor?: string;
  focusStateTextColor?: string;

  backgroundImageEnabled?: boolean;
}

export interface GameQuizAnswerActionState {
  action?: string | number;
  actionPage?: number;
}

export interface GameQuizAnswerState extends GameQuizAnswerActionState {
  id: number;
  text: string;
  description?: string;
  percentage?: number;
  alternativeText?: string;
}

export interface GameQuizQuestionIntroductionState {
  enabled: boolean;
  content?: string;
  buttonLabel?: string;
  backgroundImage?: GameQuizSectionImageOverlay;
}

export interface GameQuizQuestionAnswerMessageState {
  correct?: string;
  wrong?: string;
}

export interface GameQuizSectionImageOverlay extends GameSectionImageOverlay {
  onFeedback: boolean;
  onIntroduction: boolean;
}

export interface GameQuizQuestionState {
  id: number;
  question: string;
  swipeImage?: {
    src: string;
    alt?: string;
  };

  video: {
    enabled: boolean;
    url?: string;
    position?: QuizVideoPositionTypes;
    width?: number;
  };

  sliderRotation?: {
    enabled: boolean;
    onlyMobile?: boolean;
  };

  questionNumber: number;
  introduction: GameQuizQuestionIntroductionState;
  backgroundImage: GameQuizSectionImageOverlay;
  questionImage?: string;
  questionButtonLabel: string;
  previousQuestionLabel?: string;
  feedbackButtonLabel: string;
  enableMultipleAnswers: boolean;
  minAnswers?: number;
  maxAnswers?: number;
  minAnswersValidationMessage?: string;
  maxAnswersValidationMessage?: string;
  enableDescription: boolean;
  answerType: QuizAnswerTypes;
  answers: GameQuizAnswerState[];
  multipleCorrectAnswers: boolean;
  correctAnswers: number[];
  link: {
    enabled: boolean;
    url?: string;
  };
  date: {
    enabled: boolean;
    from?: string;
    to?: string;
  };
  visibilityCondition?: VisibilityConditionsModel;
  answerMessage: GameQuizQuestionAnswerMessageState;
  feedback: GameQuizQuestionFeedbackState;
  questionString?: string;
  correctString?: string;
  wrongString?: string;
  enablePeopleAnswers: boolean;
  answerLayout: AnswerLayoutSettingsModel;
  answerColor?: {
    correct?: string;
    wrong?: string;
  };
  defaultAnswer?: number;
}

export interface GameQuizTranslationState {
  swipe?: {
    left?: string;
    right?: string;
  };
}

export interface GameQuizState extends GameState {
  general: GameQuizGeneralState;
  questions: GameQuizQuestionState[];
  advanced?: GameAdvancedSettingsModel;
  translation?: GameQuizTranslationState;
}

export class GameQuizModel extends GameModel<GameQuizData, GameQuizState> {
  parseGame(data: GameQuizData) {
    const state = this.state;

    state.general = GameQuizModel.constructGeneralState(data.general);

    const questions: GameQuizQuestionState[] = [];

    let i = 1;
    if (Array.isArray(data.questions.question)) {
      data.questions.question.forEach((element) => {
        questions.push(this.constructQuestionState(element, data.general, i));
        i++;
      });
    } else {
      for (const property in data.questions.question) {
        if (Object.hasOwnProperty.call(data.questions.question, property)) {
          questions.push(this.constructQuestionState(data.questions.question[Number(property)], data.general, i));
          i++;
        }
      }
    }

    state.questions = questions;

    /**
     * To support legacy formats/copied campaigns
     * Issue can be customer has copied a campaign where required correct answers cna be higher than
     * available question. This happens if a customer has copied a campaign with a lot of questions
     * Then removed questions, but haven't set the option for requred correct answers to the the length
     * of available questions
     */
    if (state.general.correctAnswersRequired) {
      if (state.general.correctAnswersRequired > state.questions.length) {
        state.general.correctAnswersRequired = state.questions.length;
      }
    }

    if (data.advanced) {
      if (state.advanced) {
        state.advanced.setData(data.advanced);
      } else {
        state.advanced = new GameAdvancedSettingsModel(data.advanced);
      }
    } else {
      state.advanced = undefined;
    }

    if (data.translation) {
      state.translation = {
        swipe: {
          right: data.translation.right_swipe,
          left: data.translation.left_swipe
        }
      };
    } else {
      state.translation = undefined;
    }
  }

  private static constructGeneralState(data: GameQuizGeneralData): GameQuizGeneralState {
    let showCorrectAnswer = false;
    if (typeof data.show_correct_answer === 'number') {
      showCorrectAnswer = data.show_correct_answer === 1;
    } else {
      showCorrectAnswer = data?.show_correct_answer ? data?.show_correct_answer : false;
    }
    return {
      gameType: data.quiz_type === 'randomize' ? QuizGameTypes.RANDOMIZE : QuizGameTypes.STANDARD,
      contentAlign: data.quiz_align ?? AlignContentType.CENTER,
      ...(data.previous_question && { previousQuestionLabel: data.previous_question }),
      answerAlignment: data.answer_alignment ?? AlignContentType.CENTER,
      enableSkipLogic: data.conditional ? data.conditional === '1' : false,
      ...(data?.questions_shown && { questionsShown: Number(data.questions_shown) }),
      ...(data?.correct_answers && { correctAnswersRequired: Number(data.correct_answers) }),
      loserOnIncorrectAnswer: data?.loser_on_incorrect_answer === '1',
      buttons: GameQuizModel.parseGeneralButtonsData(data) || { enabled: false },
      feedback: {
        enabled: data.show_answer === '1',
        ...(data.show_answer &&
          data.show_answer === '1' && {
            redirectToNextQuestion: data.show_answer_redirect === '1',
            delayUntilNext: data.show_answer_delay ? Number(data.show_answer_delay) : undefined,
            buttonNextLabel: data.next_question,
            answerMessage: {
              correct: GameQuizModel.feedbackMessagePrioritize(data.correct_answer_message ?? ''),
              wrong: GameQuizModel.feedbackMessagePrioritize(data.wrong_answer_message ?? '')
            }
          })
      },
      showAnswerButton: data.show_answer_button ? data.show_answer_button === '1' : true,
      showCorrectAnswer,
      customLabels: {
        buttons: {
          answer: data.answer_btn,
          lastAnswer: data.answer_btn_last,
          selectAnswer: data.select_answer_btn ? data.select_answer_btn : 'Pick an answer'
        },
        linkLabel: data?.link
      },
      features: {
        bestTime: data.enable_best_time === '1',
        timeRanking: data.enable_time_ranking === '1'
      },
      time: {
        enabled: data.time === 1,
        ...(data?.time_limit && { limit: Number(data.time_limit) }),
        perQuestion: data.time_per_question === '1'
      },
      goBackwards: {
        enabled: data.go_backwards?.enabled
          ? data.go_backwards?.enabled === '1' ||
            data.go_backwards?.enabled === 1 ||
            data.go_backwards?.enabled === true
          : false
      },
      activeStateColor: data.active_state_color,
      activeStateBackgroundColor: data.active_state_bg_color,
      ...(data.focus_state_background && {
        focusStateBackgroundColor: data.focus_state_background
      }),
      ...(data.focus_state_text && {
        focusStateTextColor: data.focus_state_text
      })
    };
  }

  private static parseGeneralButtonsData(data: GameQuizGeneralData): GameQuizSwipeItButtonsState | undefined {
    if (data.buttons) {
      const useData = getDeviceData<GameQuizSwipeButtonData>(data.buttons);

      if (!useData) {
        return undefined;
      }

      return GameQuizModel.constructGameQuizSwipeButtonsState(useData);
    }

    return undefined;
  }

  private static constructGameQuizSwipeButtonsState(
    data: GameQuizSwipeButtonData
  ): GameQuizSwipeItButtonsState | undefined {
    if (data) {
      return {
        enabled: data.enabled === '1',
        color: data.color,
        leftText: data.left_text,
        rightText: data.right_text,
        ...(data.left_image && {
          leftImage: data.left_image
        }),
        ...(data.right_image && {
          rightImage: data.right_image
        }),
        onlyFacebook: data.only_facebook === '1',
        type: data.type
      };
    }
    return data;
  }

  private constructQuestionState(
    data: GameQuizQuestionData,
    generalData: GameQuizGeneralData,
    index: number
  ): GameQuizQuestionState {
    let questionVideoPosition: QuizVideoPositionTypes = QuizVideoPositionTypes.ABOVE;

    if (data.question_video && data.question_video.position) {
      switch (data.question_video.position) {
        case 'left':
          questionVideoPosition = QuizVideoPositionTypes.LEFT;
          break;

        case 'right':
          questionVideoPosition = QuizVideoPositionTypes.RIGHT;
          break;

        default:
          questionVideoPosition = QuizVideoPositionTypes.ABOVE;
          break;
      }
    }

    let behaviourType: BehaviorTypes = BehaviorTypes.COVER;
    let positionType: PositionTypes = PositionTypes.CENTER_CENTER;

    switch (data.background_behavior) {
      case 'center':
        behaviourType = BehaviorTypes.CENTER;
        break;
      case 'cover':
        behaviourType = BehaviorTypes.COVER;
        break;
      case 'contain':
        behaviourType = BehaviorTypes.CONTAIN;
        break;
      case 'stretch':
        behaviourType = BehaviorTypes.STRETCH;
        break;
      case 'tile':
        behaviourType = BehaviorTypes.TILE;
        break;
      case 'actual_size':
        behaviourType = BehaviorTypes.ACTUAL_SIZE;
        break;
    }

    switch (data.background_position) {
      case 'custom':
        positionType = PositionTypes.CUSTOM;
        break;
      case 'bottom_center':
        positionType = PositionTypes.BOTTOM_CENTER;
        break;
      case 'bottom_left':
        positionType = PositionTypes.BOTTOM_LEFT;
        break;
      case 'bottom_right':
        positionType = PositionTypes.BOTTOM_RIGHT;
        break;
      case 'center_center':
        positionType = PositionTypes.CENTER_CENTER;
        break;
      case 'center_left':
        positionType = PositionTypes.CENTER_LEFT;
        break;
      case 'center_right':
        positionType = PositionTypes.CENTER_RIGHT;
        break;
      case 'top_center':
        positionType = PositionTypes.TOP_CENTER;
        break;
      case 'top_left':
        positionType = PositionTypes.TOP_LEFT;
        break;
      case 'top_right':
        positionType = PositionTypes.TOP_RIGHT;
        break;
    }

    const existingQuestionState = this.state?.questions?.find(
      (existingQuestion) => existingQuestion.id === Number(data.id)
    );

    let visibilityCondition: VisibilityConditionsModel | undefined;

    if (data.date_range.enabled === '1') {
      visibilityCondition = existingQuestionState ? existingQuestionState.visibilityCondition : undefined;

      const visibilityConditionData: VisibilityConditionData = {
        condition: GameEndingConditionType.NONE,
        date_range: {
          enabled: data.date_range.enabled,
          date_to: data.date_range.to ? data.date_range.to : '',
          date_from: data.date_range.from ? data.date_range.from : ''
        }
      };

      if (visibilityCondition) {
        visibilityCondition.setData(visibilityConditionData);
      } else {
        visibilityCondition = new VisibilityConditionsModel(visibilityConditionData);
      }
    }

    let answerLayout: AnswerLayoutSettingsModel | undefined;
    answerLayout = existingQuestionState ? existingQuestionState.answerLayout : undefined;
    if (answerLayout) {
      answerLayout.setData(generalData);
    } else {
      answerLayout = new AnswerLayoutSettingsModel(generalData);
    }

    let correctAnswers: number[] = [];

    if (data.correct_answer instanceof Array) {
      correctAnswers = data.correct_answer.map(function (x) {
        return parseInt(x.replace(/^\D+/g, ''), 10);
      });
    } else if (typeof data.correct_answer === 'string') {
      correctAnswers.push(parseInt(data.correct_answer.replace(/^\D+/g, ''), 10));
    }

    /**
     * only assign mobile values if overwrite mobile is false else ignore mobile values and assign default object.
     * This functionality is much like the check we have in getDeviceData, however
     * we have pulled this functionality in locally in this model as the data in this
     * is formatted differently, so we couldn't make a 100% use of getDeviceData
     */

    let backgroundImage: GameQuizSectionImageOverlay = {
      enabled: !!data.background_image && generalData.background_image?.enabled === true,
      src: data.background_image,
      behavior: behaviourType,
      position: positionType,
      overlay: data.background_overlay,
      onFeedback: data.background_feedback_enabled === '1',
      onIntroduction: data.background_intro_enabled === '1'
    };

    const { isMobile } = useDevice();
    const overwriteMobile = typeof data.overwrite_mobile === 'undefined' || data.overwrite_mobile === '1';
    if (isMobile && !overwriteMobile) {
      backgroundImage = {
        enabled: !!data.mobile.background_image,
        src: data.mobile.background_image,
        behavior: this.getBackgroundBehavior(data.mobile.background_behavior),
        position: this.getBackgroundPosition(data.mobile.background_position),
        overlay: data.mobile.background_overlay,
        onFeedback: data.mobile.background_feedback_enabled === '1',
        onIntroduction: data.mobile.background_intro_enabled === '1'
      };
    }

    const questionState: GameQuizQuestionState = {
      id: Number(data.id),
      questionNumber: index,
      question: data.question,
      video: {
        enabled: data.question_video ? data.question_video.enable === '1' : false,
        ...(data.question_video &&
          data.question_video.enable &&
          data.question_video.enable === '1' && {
            url: data.question_video.url,
            width: data.question_video.width ? Number(data.question_video.width) : undefined,
            position: questionVideoPosition
          })
      },
      introduction: {
        enabled: data.introduction?.enabled === '1',
        content: data.introduction?.content,
        buttonLabel: data.introduction?.button_label ? data.introduction?.button_label : generalData.next_question,
        ...(data.background_intro_enabled && { backgroundImage })
      },
      backgroundImage,
      enableMultipleAnswers: data.multiple_answers === '1',
      ...(data.multiple_answers && {
        ...(data.max_answers && { maxAnswers: Number(data.max_answers) }),
        ...(data.min_answers && { minAnswers: Number(data.min_answers) }),
        ...(data.min_answers_validation_message && {
          minAnswersValidationMessage: data.min_answers_validation_message
        }),
        ...(data.max_answers_validation_message && { maxAnswersValidationMessage: data.max_answers_validation_message })
      }),
      sliderRotation: {
        enabled: data.slider_rotation === '1',
        ...(data.slider_rotation_mobile && { onlyMobile: data.slider_rotation_mobile === '1' })
      },
      questionImage: data.question_image,
      ...(data.swipe_image && {
        swipeImage: {
          src: data.swipe_image,
          alt: data.swipe_image_alt_text
        }
      }),
      enableDescription: data.additional_description === '1',
      answers: GameQuizModel.constructAnswerState(data),
      multipleCorrectAnswers: data.multiple_correct_answers === '1',
      correctAnswers,
      questionButtonLabel: data.question_button_label,
      feedbackButtonLabel: data.feedback_button_label,
      ...(generalData.previous_question && { previousQuestionLabel: generalData.previous_question }),
      date: {
        enabled: data.date_range.enabled === '1',
        ...(data.date_range.enabled &&
          data.date_range.enabled === '1' && {
            from: data.date_range.from,
            to: data.date_range.to
          })
      },
      visibilityCondition,
      link: {
        enabled: !!data.link,
        ...(!!data.link && {
          url: formatUrl(data.link)
        })
      },
      answerMessage: {
        correct: data.correct_answer_message ? data.correct_answer_message : data.correct_string,
        wrong: data.wrong_answer_message ? data.wrong_answer_message : data.wrong_string
      },

      feedback: {
        enabled: generalData.show_answer === '1',
        ...(generalData.show_answer &&
          generalData.show_answer === '1' && {
            redirectToNextQuestion: generalData.show_answer_redirect === '1',
            delayUntilNext: generalData.show_answer_delay ? Number(generalData.show_answer_delay) : undefined,
            buttonNextLabel: generalData.next_question,
            feedbackButtonLabel: data.feedback_button_label,
            buttonLastLabel: generalData.answer_btn_last,
            answerMessage: {
              correct: GameQuizModel.feedbackMessagePrioritize(
                data.correct_answer_message ?? '',
                generalData.correct_answer_message ?? ''
              ),
              wrong: GameQuizModel.feedbackMessagePrioritize(
                data.wrong_answer_message ?? '',
                generalData.wrong_answer_message ?? ''
              )
            }
          })
      },
      questionString: data.question_string,
      correctString: data.correct_string,
      wrongString: data.wrong_string,
      answerLayout,
      enablePeopleAnswers: !!generalData.other_peoples_answer,
      answerColor: {
        correct: generalData.correct_answer_color ? generalData.correct_answer_color : '#68bb5a',
        wrong: generalData.wrong_answer_color ? generalData.wrong_answer_color : '#bb5a80'
      },
      defaultAnswer: data.default_answer ? Number(data.default_answer) : undefined,
      answerType: QuizAnswerTypes.TEXT
    };

    switch (data.answer_type) {
      case 'image':
        questionState.answerType = QuizAnswerTypes.IMAGE;
        break;

      case 'slider':
        questionState.answerType = QuizAnswerTypes.SLIDER;
        break;

      case 'swipe':
        questionState.answerType = QuizAnswerTypes.SWIPE;
        break;

      case 'text':
        questionState.answerType = QuizAnswerTypes.TEXT;
        break;
    }

    return questionState;
  }

  private static feedbackMessagePrioritize(...messages: string[]): string | undefined {
    return messages.find((message) => {
      return (
        message &&
        message.trim() &&
        ![
          '<div><br></div>',
          '<br>',
          '<p><br></p>',
          '<h1><br></h1>',
          '<h2><br></h2>',
          '<h3><br></h3>',
          '<h4><br></h4>',
          '<h5><br></h5>',
          '<h6><br></h6>',
          '<div style="text-align: center;"><br></div>',
          '<p style="text-align: center;"><br></p>',
          '<h1 style="text-align: center;"><br></h1>',
          '<h2 style="text-align: center;"><br></h2>',
          '<h3 style="text-align: center;"><br></h3>',
          '<h4 style="text-align: center;"><br></h4>',
          '<h5 style="text-align: center;"><br></h5>',
          '<h6 style="text-align: center;"><br></h6>'
        ].includes(message.replace(/&#60;/gi, '<').replace(/&#62;/gi, '>'))
      );
    });
  }

  private static constructAnswerState(data: GameQuizQuestionData): GameQuizAnswerState[] {
    const answers: GameQuizAnswerState[] = [];
    if (data.answer_1) {
      answers.push({
        id: 1,
        text: data.answer_1,
        ...(data?.answer_1_additional && { description: data.answer_1_additional }),
        ...(Number.isInteger(data?.answer_1_percentage) && { percentage: data.answer_1_percentage }),
        ...(data?.answer_1_action && { action: data.answer_1_action }),
        ...(data?.answer_1_action_page && { actionPage: Number(data.answer_1_action_page) }),
        ...(data?.answer_1_alt_text && { alternativeText: data.answer_1_alt_text })
      });
    }

    if (data.answer_2) {
      answers.push({
        id: 2,
        text: data.answer_2,
        ...(data?.answer_2_additional && { description: data.answer_2_additional }),
        ...(Number.isInteger(data?.answer_2_percentage) && { percentage: data.answer_2_percentage }),
        ...(data?.answer_2_action && { action: data.answer_2_action }),
        ...(data?.answer_2_action_page && { actionPage: Number(data.answer_2_action_page) }),
        ...(data?.answer_2_alt_text && { alternativeText: data.answer_2_alt_text })
      });
    }

    if (data.answer_3) {
      answers.push({
        id: 3,
        text: data.answer_3,
        ...(data?.answer_3_additional && { description: data.answer_3_additional }),
        ...(Number.isInteger(data?.answer_3_percentage) && { percentage: data.answer_3_percentage }),
        ...(data?.answer_3_action && { action: data.answer_3_action }),
        ...(data?.answer_3_action_page && { actionPage: Number(data.answer_3_action_page) }),
        ...(data?.answer_3_alt_text && { alternativeText: data.answer_3_alt_text })
      });
    }

    if (data.answer_4) {
      answers.push({
        id: 4,
        text: data.answer_4,
        ...(data?.answer_4_additional && { description: data.answer_4_additional }),
        ...(Number.isInteger(data?.answer_4_percentage) && { percentage: data.answer_4_percentage }),
        ...(data?.answer_4_action && { action: data.answer_4_action }),
        ...(data?.answer_4_action_page && { actionPage: Number(data.answer_4_action_page) }),
        ...(data?.answer_4_alt_text && { alternativeText: data.answer_4_alt_text })
      });
    }

    if (data.answer_5) {
      answers.push({
        id: 5,
        text: data.answer_5,
        ...(data?.answer_5_additional && { description: data.answer_5_additional }),
        ...(Number.isInteger(data?.answer_5_percentage) && { percentage: data.answer_5_percentage }),
        ...(data?.answer_5_action && { action: data.answer_5_action }),
        ...(data?.answer_5_action_page && { actionPage: Number(data.answer_5_action_page) }),
        ...(data?.answer_5_alt_text && { alternativeText: data.answer_5_alt_text })
      });
    }

    if (data.answer_6) {
      answers.push({
        id: 6,
        text: data.answer_6,
        ...(data?.answer_6_additional && { description: data.answer_6_additional }),
        ...(Number.isInteger(data?.answer_6_percentage) && { percentage: data.answer_6_percentage }),
        ...(data?.answer_6_action && { action: data.answer_6_action }),
        ...(data?.answer_6_action_page && { actionPage: Number(data.answer_6_action_page) }),
        ...(data?.answer_6_alt_text && { alternativeText: data.answer_6_alt_text })
      });
    }

    if (data.answer_7) {
      answers.push({
        id: 7,
        text: data.answer_7,
        ...(data?.answer_7_additional && { description: data.answer_7_additional }),
        ...(Number.isInteger(data?.answer_7_percentage) && { percentage: data.answer_7_percentage }),
        ...(data?.answer_7_action && { action: data.answer_7_action }),
        ...(data?.answer_7_action_page && { actionPage: Number(data.answer_7_action_page) }),
        ...(data?.answer_7_alt_text && { alternativeText: data.answer_7_alt_text })
      });
    }

    if (data.answer_8) {
      answers.push({
        id: 8,
        text: data.answer_8,
        ...(data?.answer_8_additional && { description: data.answer_8_additional }),
        ...(Number.isInteger(data?.answer_8_percentage) && { percentage: data.answer_8_percentage }),
        ...(data?.answer_8_action && { action: data.answer_8_action }),
        ...(data?.answer_8_action_page && { actionPage: Number(data.answer_8_action_page) }),
        ...(data?.answer_8_alt_text && { alternativeText: data.answer_8_alt_text })
      });
    }

    if (data.answer_9) {
      answers.push({
        id: 9,
        text: data.answer_9,
        ...(data?.answer_9_additional && { description: data.answer_9_additional }),
        ...(Number.isInteger(data?.answer_9_percentage) && { percentage: data.answer_9_percentage }),
        ...(data?.answer_9_action && { action: data.answer_9_action }),
        ...(data?.answer_9_action_page && { actionPage: Number(data.answer_9_action_page) }),
        ...(data?.answer_9_alt_text && { alternativeText: data.answer_9_alt_text })
      });
    }

    if (data.answer_10) {
      answers.push({
        id: 10,
        text: data.answer_10,
        ...(data?.answer_10_additional && { description: data.answer_10_additional }),
        ...(Number.isInteger(data?.answer_10_percentage) && { percentage: data.answer_10_percentage }),
        ...(data?.answer_10_action && { action: data.answer_10_action }),
        ...(data?.answer_10_action_page && { actionPage: Number(data.answer_10_action_page) }),
        ...(data?.answer_10_alt_text && { alternativeText: data.answer_10_alt_text })
      });
    }

    if (data.answer_11) {
      answers.push({
        id: 11,
        text: data.answer_11,
        ...(data?.answer_11_additional && { description: data.answer_11_additional }),
        ...(Number.isInteger(data?.answer_11_percentage) && { percentage: data.answer_11_percentage }),
        ...(data?.answer_11_action && { action: data.answer_11_action }),
        ...(data?.answer_11_action_page && { actionPage: Number(data.answer_11_action_page) }),
        ...(data?.answer_11_alt_text && { alternativeText: data.answer_11_alt_text })
      });
    }

    if (data.answer_12) {
      answers.push({
        id: 12,
        text: data.answer_12,
        ...(data?.answer_12_additional && { description: data.answer_12_additional }),
        ...(Number.isInteger(data?.answer_12_percentage) && { percentage: data.answer_12_percentage }),
        ...(data?.answer_12_action && { action: data.answer_12_action }),
        ...(data?.answer_12_action_page && { actionPage: Number(data.answer_12_action_page) }),
        ...(data?.answer_12_alt_text && { alternativeText: data.answer_12_alt_text })
      });
    }

    if (data.answer_13) {
      answers.push({
        id: 13,
        text: data.answer_13,
        ...(data?.answer_13_additional && { description: data.answer_13_additional }),
        ...(Number.isInteger(data?.answer_13_percentage) && { percentage: data.answer_13_percentage }),
        ...(data?.answer_13_action && { action: data.answer_13_action }),
        ...(data?.answer_13_action_page && { actionPage: Number(data.answer_13_action_page) }),
        ...(data?.answer_13_alt_text && { alternativeText: data.answer_13_alt_text })
      });
    }

    if (data.answer_14) {
      answers.push({
        id: 14,
        text: data.answer_14,
        ...(data?.answer_14_additional && { description: data.answer_14_additional }),
        ...(Number.isInteger(data?.answer_14_percentage) && { percentage: data.answer_14_percentage }),
        ...(data?.answer_14_action && { action: data.answer_14_action }),
        ...(data?.answer_14_action_page && { actionPage: Number(data.answer_14_action_page) }),
        ...(data?.answer_14_alt_text && { alternativeText: data.answer_14_alt_text })
      });
    }

    if (data.answer_15) {
      answers.push({
        id: 15,
        text: data.answer_15,
        ...(data?.answer_15_additional && { description: data.answer_15_additional }),
        ...(Number.isInteger(data?.answer_15_percentage) && { percentage: data.answer_15_percentage }),
        ...(data?.answer_15_action && { action: data.answer_15_action }),
        ...(data?.answer_15_action_page && { actionPage: Number(data.answer_15_action_page) }),
        ...(data?.answer_15_alt_text && { alternativeText: data.answer_15_alt_text })
      });
    }

    if (data.answer_16) {
      answers.push({
        id: 16,
        text: data.answer_16,
        ...(data?.answer_16_additional && { description: data.answer_16_additional }),
        ...(Number.isInteger(data?.answer_16_percentage) && { percentage: data.answer_16_percentage }),
        ...(data?.answer_16_action && { action: data.answer_16_action }),
        ...(data?.answer_16_action_page && { actionPage: Number(data.answer_16_action_page) }),
        ...(data?.answer_16_alt_text && { alternativeText: data.answer_16_alt_text })
      });
    }

    if (data.answer_17) {
      answers.push({
        id: 17,
        text: data.answer_17,
        ...(data?.answer_17_additional && { description: data.answer_17_additional }),
        ...(Number.isInteger(data?.answer_17_percentage) && { percentage: data.answer_17_percentage }),
        ...(data?.answer_17_action && { action: data.answer_17_action }),
        ...(data?.answer_17_action_page && { actionPage: Number(data.answer_17_action_page) }),
        ...(data?.answer_17_alt_text && { alternativeText: data.answer_17_alt_text })
      });
    }

    if (data.answer_18) {
      answers.push({
        id: 18,
        text: data.answer_18,
        ...(data?.answer_18_additional && { description: data.answer_18_additional }),
        ...(Number.isInteger(data?.answer_18_percentage) && { percentage: data.answer_18_percentage }),
        ...(data?.answer_18_action && { action: data.answer_18_action }),
        ...(data?.answer_18_action_page && { actionPage: Number(data.answer_18_action_page) }),
        ...(data?.answer_18_alt_text && { alternativeText: data.answer_18_alt_text })
      });
    }

    if (data.answer_19) {
      answers.push({
        id: 19,
        text: data.answer_19,
        ...(data?.answer_19_additional && { description: data.answer_19_additional }),
        ...(Number.isInteger(data?.answer_19_percentage) && { percentage: data.answer_19_percentage }),
        ...(data?.answer_19_action && { action: data.answer_19_action }),
        ...(data?.answer_19_action_page && { actionPage: Number(data.answer_19_action_page) }),
        ...(data?.answer_19_alt_text && { alternativeText: data.answer_19_alt_text })
      });
    }

    if (data.answer_20) {
      answers.push({
        id: 20,
        text: data.answer_20,
        ...(data?.answer_20_additional && { description: data.answer_20_additional }),
        ...(Number.isInteger(data?.answer_20_percentage) && { percentage: data.answer_20_percentage }),
        ...(data?.answer_20_action && { action: data.answer_20_action }),
        ...(data?.answer_20_action_page && { actionPage: Number(data.answer_20_action_page) }),
        ...(data?.answer_20_alt_text && { alternativeText: data.answer_20_alt_text })
      });
    }

    if (data.answer_21) {
      answers.push({
        id: 21,
        text: data.answer_21,
        ...(data?.answer_21_additional && { description: data.answer_21_additional }),
        ...(Number.isInteger(data?.answer_21_percentage) && { percentage: data.answer_21_percentage }),
        ...(data?.answer_21_action && { action: data.answer_21_action }),
        ...(data?.answer_21_action_page && { actionPage: Number(data.answer_21_action_page) }),
        ...(data?.answer_21_alt_text && { alternativeText: data.answer_21_alt_text })
      });
    }

    if (data.answer_22) {
      answers.push({
        id: 22,
        text: data.answer_22,
        ...(data?.answer_22_additional && { description: data.answer_22_additional }),
        ...(Number.isInteger(data?.answer_22_percentage) && { percentage: data.answer_22_percentage }),
        ...(data?.answer_22_action && { action: data.answer_22_action }),
        ...(data?.answer_22_action_page && { actionPage: Number(data.answer_22_action_page) }),
        ...(data?.answer_22_alt_text && { alternativeText: data.answer_22_alt_text })
      });
    }

    if (data.answer_23) {
      answers.push({
        id: 23,
        text: data.answer_23,
        ...(data?.answer_23_additional && { description: data.answer_23_additional }),
        ...(Number.isInteger(data?.answer_23_percentage) && { percentage: data.answer_23_percentage }),
        ...(data?.answer_23_action && { action: data.answer_23_action }),
        ...(data?.answer_23_action_page && { actionPage: Number(data.answer_23_action_page) }),
        ...(data?.answer_23_alt_text && { alternativeText: data.answer_23_alt_text })
      });
    }

    if (data.answer_24) {
      answers.push({
        id: 24,
        text: data.answer_24,
        ...(data?.answer_24_additional && { description: data.answer_24_additional }),
        ...(Number.isInteger(data?.answer_24_percentage) && { percentage: data.answer_24_percentage }),
        ...(data?.answer_24_action && { action: data.answer_24_action }),
        ...(data?.answer_24_action_page && { actionPage: Number(data.answer_24_action_page) }),
        ...(data?.answer_24_alt_text && { alternativeText: data.answer_24_alt_text })
      });
    }

    if (data.answer_25) {
      answers.push({
        id: 25,
        text: data.answer_25,
        ...(data?.answer_25_additional && { description: data.answer_25_additional }),
        ...(Number.isInteger(data?.answer_25_percentage) && { percentage: data.answer_25_percentage }),
        ...(data?.answer_25_action && { action: data.answer_25_action }),
        ...(data?.answer_25_action_page && { actionPage: Number(data.answer_25_action_page) }),
        ...(data?.answer_25_alt_text && { alternativeText: data.answer_25_alt_text })
      });
    }

    return answers;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_left: this.state.general.time.limit ?? 0,
          time_used: 1
        }
      }
    ];
  }

  private getBackgroundPosition(data?: BackgroundPositionType) {
    let positionType: PositionTypes | undefined;

    switch (data) {
      case 'custom':
        positionType = PositionTypes.CUSTOM;
        break;
      case 'bottom_center':
        positionType = PositionTypes.BOTTOM_CENTER;
        break;
      case 'bottom_left':
        positionType = PositionTypes.BOTTOM_LEFT;
        break;
      case 'bottom_right':
        positionType = PositionTypes.BOTTOM_RIGHT;
        break;
      case 'center_left':
        positionType = PositionTypes.CENTER_LEFT;
        break;
      case 'center_right':
        positionType = PositionTypes.CENTER_RIGHT;
        break;
      case 'top_center':
        positionType = PositionTypes.TOP_CENTER;
        break;
      case 'top_left':
        positionType = PositionTypes.TOP_LEFT;
        break;
      case 'top_right':
        positionType = PositionTypes.TOP_RIGHT;
        break;
      default:
        positionType = PositionTypes.CENTER_CENTER;
        break;
    }
    return positionType;
  }

  private getBackgroundBehavior(data?: BackgroundBehaviorType) {
    let behaviourType: BehaviorTypes | undefined;

    switch (data) {
      case 'center':
        behaviourType = BehaviorTypes.CENTER;
        break;

      case 'contain':
        behaviourType = BehaviorTypes.CONTAIN;
        break;
      case 'stretch':
        behaviourType = BehaviorTypes.STRETCH;
        break;
      case 'tile':
        behaviourType = BehaviorTypes.TILE;
        break;
      case 'actual_size':
        behaviourType = BehaviorTypes.ACTUAL_SIZE;
        break;
      default:
        behaviourType = BehaviorTypes.COVER;
        break;
    }
    return behaviourType;
  }

  public isGameValid(): boolean {
    return true;
  }

  public get sdkMetrics(): MetricData | undefined {
    const campaignStore = useCampaignStore();

    if (Object.keys(campaignStore.metricData).length === 0) {
      return undefined;
    }

    return {
      timeused: Number(campaignStore.metricData.timeused ?? 0),
      timeused_ms: Number(campaignStore.metricData.timeused_ms ?? 0),
      timeleft: Number(campaignStore.metricData.timeleft ?? 0),
      total_answers: Number(campaignStore.metricData.total_answers ?? 0),
      wrong_answers: Number(campaignStore.metricData.wrong_answers ?? 0),
      correct_answers: Number(campaignStore.metricData.correct_answers ?? 0)
    };
  }

  public get sdkSettings(): GameQuizSdkSettings {
    const lastQuestion = this.state.questions[this.state.questions.length - 1];

    return {
      questions: this.state.questions.map((question) => {
        return new SdkQuizQuestionModel(question, lastQuestion === question);
      })
    };
  }
}
