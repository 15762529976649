<template>
  <div class="game-indicator" :class="indicatorClass">
    <GameIndicatorItem
      v-for="(indicator, index) in indicators?.state.indicators"
      :key="index"
      :class="
        indicators?.state.layout?.direction ? `game-indicator-item--${indicators?.state.layout?.direction}` : undefined
      "
      :indicator="indicator"
    ></GameIndicatorItem>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, onMounted } from 'vue';
import type { GameIndicatorSettingsModel } from '@/src/components/indicators/Model';

import useFontObserver from '@/src/hooks/useFontObserver';
import { useUtilityStore } from '@/src/store/utility';
import GameIndicatorItem from '@/src/components/indicators/v1/GameIndicatorItem.vue';

export default defineComponent({
  name: 'GameIndicators',
  components: {
    GameIndicatorItem
  },
  props: {
    indicators: {
      type: Object as PropType<GameIndicatorSettingsModel>
    },
    indicatorClass: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    let readyPromiseResolve: (() => void) | undefined;
    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    if (props.indicators?.state.layout?.fontFamily) {
      const utilityStore = useUtilityStore();
      utilityStore.loadFont(props.indicators?.state.layout?.fontFamily);
    }

    onMounted(async () => {
      if (props.indicators?.state.layout?.fontFamily) {
        const { isFontLoaded } = useFontObserver();

        await isFontLoaded(props.indicators?.state.layout.fontFamily).catch((e) => {
          // eslint-disable-next-line no-console
          console.error('Something went wrong with loading the font', e);
        });
      }

      if (readyPromiseResolve) {
        readyPromiseResolve();
      }
    });

    return {
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>

<style lang="scss">
.game-indicator {
  position: static;
  width: 100%;

  &--fill-above,
  &--fill-below {
    min-height: 90px;

    &.game-indicator--with-icon {
      min-height: 150px;
    }
  }

  &--fill-below {
    margin: 20px 0 0 0;

    &.game-indicator--with-icon {
      + div {
        margin-bottom: 60px;
      }
    }
  }

  &--fill-above {
    margin: 0 0 20px 0;
  }

  &--align-top-left,
  &--align-top-center,
  &--align-top-right,
  &--align-center-left,
  &--align-center-center,
  &--align-center-right,
  &--align-bottom-left,
  &--align-bottom-center,
  &--align-bottom-right {
    display: flex;
    pointer-events: none;
  }

  &--align-top-right,
  &--align-top-left,
  &--align-center-left,
  &--align-center-center,
  &--align-center-right,
  &--align-bottom-left,
  &--align-bottom-right {
    .category-desktop & {
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      z-index: 999999;
    }
  }

  &--horizontal {
    flex-direction: row;

    &.game-indicator--align-top-left {
      align-items: flex-start;
      justify-content: flex-start;
    }

    &.game-indicator--align-top-center {
      align-items: flex-start;
      justify-content: center;
    }

    &.game-indicator--align-top-right {
      align-items: flex-start;
      justify-content: flex-end;
    }

    &.game-indicator--align-center-left {
      align-items: center;
      justify-content: flex-start;
    }

    &.game-indicator--align-center-center {
      align-items: center;
      justify-content: center;
    }

    &.game-indicator--align-center-right {
      align-items: center;
      justify-content: flex-end;
    }

    &.game-indicator--align-bottom-left {
      align-items: flex-end;
      justify-content: flex-start;
    }

    &.game-indicator--align-bottom-center {
      align-items: flex-end;
      justify-content: center;
    }

    &.game-indicator--align-bottom-right {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  &--vertical {
    flex-direction: column;

    &.game-indicator--align-top-left {
      align-items: flex-start;
      justify-content: flex-start;
    }

    &.game-indicator--align-top-center {
      align-items: center;
      justify-content: flex-start;
    }

    &.game-indicator--align-top-right {
      align-items: flex-end;
      justify-content: flex-start;
    }

    &.game-indicator--align-center-left {
      align-items: flex-start;
      justify-content: center;
    }

    &.game-indicator--align-center-center {
      align-items: center;
      justify-content: center;
    }

    &.game-indicator--align-center-right {
      align-items: flex-end;
      justify-content: center;
    }

    &.game-indicator--align-bottom-left {
      align-items: flex-start;
      justify-content: flex-end;
    }

    &.game-indicator--align-bottom-center {
      align-items: center;
      justify-content: flex-end;
    }

    &.game-indicator--align-bottom-right {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}

.game .flow-container .flow-page .game-container.game-container--bottom-fill,
.gameplay.gameplay--bottom-fill {
  padding-bottom: 110px;
}

.gameplay.gameplay--bottom-fill {
  position: relative;
}

.game-indicator-item {
  position: absolute;
  display: flex;
  margin: 5px;
  width: 90px;
  height: 90px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &::before {
    background: #212529;
  }

  &--v-align {
    &-top {
      top: 0;
    }

    &-center {
      top: 50%;
      margin-top: -45px;
    }

    &-bottom {
      bottom: -5px;
    }
  }

  &--h-align {
    &-left {
      left: -5px;
    }

    &-center {
      left: 50%;
      margin-left: -45px;
    }

    &-right {
      right: -5px;
    }
  }

  .game-indicator--align-top-left &,
  .game-indicator--align-top-center &,
  .game-indicator--align-top-right &,
  .game-indicator--align-center-left &,
  .game-indicator--align-center-center &,
  .game-indicator--align-center-right &,
  .game-indicator--align-bottom-left &,
  .game-indicator--align-bottom-center &,
  .game-indicator--align-bottom-right & {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    margin: 5px;
  }

  &__value {
    display: block;
    margin-top: -7px;
    margin-bottom: 2px;
    font-weight: 900;
    font-size: 38px;
    line-height: 1em;
  }

  &__label {
    display: block;
    padding: 0 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1em;
    letter-spacing: -0.6px;
    text-align: center;
  }

  &__circle {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible !important;

    .game-indicator-item--dynamic-circle & {
      transform: rotate(-90deg) rotateX(180deg);
    }

    &--back {
      opacity: 0.3;

      .game-indicator-item--dynamic-circle & {
        transform: none;
      }
    }

    &-path {
      fill: transparent;
      stroke: #fff;
      stroke-width: 2.6;
      stroke-dasharray: 534;
      will-change: stroke-dashoffset;
      transition: stroke-dashoffset 300ms ease-in-out;
    }
  }

  &__icon-path {
    fill: #fff;
  }

  .game-indicator--horizontal &--with-icon {
    margin-top: 40px !important;

    .game-indicator-item__icon {
      position: absolute;
      bottom: 80px;
    }

    &.game-indicator-item--circle {
      margin-top: 60px !important;

      .game-indicator-item__icon {
        bottom: 105px;
      }
    }
  }

  .game-indicator--vertical &--with-icon {
    margin-left: 40px !important;

    .game-indicator-item__icon {
      position: absolute;
      right: 80px;
    }

    &.game-indicator-item--circle {
      margin-left: 60px !important;

      .game-indicator-item__icon {
        right: 105px;
      }
    }
  }
}

.game-indicator-item--v-align-top.game-indicator-item--h-align-center
  + .game-indicator-item--v-align-top.game-indicator-item--h-align-center,
.game-indicator-item--v-align-center.game-indicator-item--h-align-center
  + .game-indicator-item--v-align-center.game-indicator-item--h-align-center,
.game-indicator-item--v-align-bottom.game-indicator-item--h-align-center
  + .game-indicator-item--v-align-bottom.game-indicator-item--h-align-center {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: -6px;
    width: 2px;
    height: 40px;
    margin-top: -20px;
  }

  &.game-indicator-item--circle {
    margin-left: 5px;

    &::before {
      left: -15px;
    }
  }

  &.game-indicator-item--vertical {
    margin-left: 5px;

    &::before {
      top: 5px;
      left: 50%;
      height: 2px;
      width: 40px;
      margin-top: 0;
      margin-left: -20px;
    }

    &.game-indicator-item--circle {
      &::before {
        top: -17px;
      }
    }
  }
}

.game-indicator-item--v-align-top.game-indicator-item--h-align-left
  + .game-indicator-item--v-align-top.game-indicator-item--h-align-left,
.game-indicator-item--v-align-center.game-indicator-item--h-align-left
  + .game-indicator-item--v-align-center.game-indicator-item--h-align-left,
.game-indicator-item--v-align-bottom.game-indicator-item--h-align-left
  + .game-indicator-item--v-align-bottom.game-indicator-item--h-align-left,
.game-indicator-item--v-align-top.game-indicator-item--h-align-right
  + .game-indicator-item--v-align-top.game-indicator-item--h-align-right,
.game-indicator-item--v-align-center.game-indicator-item--h-align-right
  + .game-indicator-item--v-align-center.game-indicator-item--h-align-right,
.game-indicator-item--v-align-bottom.game-indicator-item--h-align-right
  + .game-indicator-item--v-align-bottom.game-indicator-item--h-align-right {
  margin-left: 10px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 3px;
    width: 2px;
    height: 40px;
    margin-top: -20px;
  }

  &.game-indicator-item--circle {
    margin-left: 5px;

    &::before {
      left: -15px;
    }
  }

  &.game-indicator-item--vertical {
    margin-left: 5px;

    &::before {
      top: 5px;
      left: 50%;
      height: 2px;
      width: 40px;
      margin-top: 0;
      margin-left: -20px;
    }

    &.game-indicator-item--circle {
      &::before {
        top: -17px;
      }
    }
  }
}

.game-indicator-item.game-indicator-item--circle + .game-indicator-item.game-indicator-item--circle {
  &::before {
    display: none;
  }
}

.game-indicator-item.game-indicator-item--vertical:not(.game-indicator-item--circle)
  + .game-indicator-item.game-indicator-item--vertical:not(.game-indicator-item--circle) {
  margin-top: -15px;
}

.game-indicator-item:not(.game-indicator-item--h-align-center).game-indicator-item--horizontal:not(
    .game-indicator-item--circle
  )
  + .game-indicator-item:not(.game-indicator-item--h-align-center).game-indicator-item--horizontal:not(
    .game-indicator-item--circle
  ) {
  margin-left: -15px;
}
</style>
