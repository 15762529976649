import type {
  BaseFormElementData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';

export type FormElementTextareaData = BaseFormElementData;
export type FormElementTextareaState = BaseFormElementState<string>;

export class FormElementTextareaModel extends BaseFormElementModel<
  string,
  FormElementTextareaData,
  FormElementTextareaState
> {
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
  parseFormElement(data: FormElementTextareaData) {}

  getInitialValue(): string | undefined {
    return this.getInitialStringValue();
  }

  parseStringValue(value: string): string | undefined {
    return value;
  }

  getStringifiedValue(): string | undefined {
    return this.state.value ?? '';
  }

  getSerializedPostValue(): string {
    return this.state.value ?? '';
  }

  getSerializedCookieValue(): string {
    return this.state.value ?? '';
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
